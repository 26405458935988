import constant from "../_config/constant";
import { history } from "../_helpers/history";
import {
  authHeader,
  // logout,
  basicAuthHeader,
} from "../_helpers";
import { handleResponse } from "../_helpers/utils";

export const amenityService = {
  list,
  add,
  update,
  updateStatus,
  getDetail,
  List,
};

function list(data) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(
    `${constant.apiUrl}/amenity/list?search=${data.search}`,
    requestOptions
  )
    .then(handleResponse)
    .then((user) => {
      return user;
    });
}

function List(data) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(
    `${constant.apiUrl}/amenity/_list?search=${data.search}`,
    requestOptions
  )
    .then(handleResponse)
    .then((user) => {
      return user;
    });
}

function getDetail(id) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(`${constant.apiUrl}/amenity/detail/${id}`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      return user;
    });
}

function updateStatus(data) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  return fetch(`${constant.apiUrl}/amenity/updateStatus`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      return user;
    });
}
function add(data) {
  var fd = new FormData();
  fd.append("name", data.name);
  fd.append("file", data.image);
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader() },
    body: fd,
  };

  return fetch(`${constant.apiUrl}/amenity/add`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      return user;
    });
}

function update(data) {
  var fd = new FormData();
  fd.append("name", data.name);
  fd.append("amenityId", data.amenityId);
  fd.append("file", data.file);

  const requestOptions = {
    method: "POST",
    headers: { ...authHeader() },
    body: fd,
  };
  return fetch(`${constant.apiUrl}/amenity/update`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      return user;
    });
}

// function handleResponse(response) {
//   return response.text().then((text) => {
//     const data = text && JSON.parse(text);
//     if (data.statusCode == 401 || data.statusCode == 403) {
//       logout();
//       window.location.reload();
//     }
//     if (data.statusCode != 200) {
//       const error = (data && data.userMessage) || response.statusText;
//       return Promise.reject(error);
//     }
//     return data;
//   });
// }
