import React from 'react'
import { HiOutlineChevronLeft } from 'react-icons/hi'
import { FaBed } from 'react-icons/fa'

export default function RoomAndBedSelection({ properyDetails,
    selectedRoom, handleRoomSelection,
    scrollLeftHandlerForBed, bedsContainer,
    scrollRightHandlerForBed,
    selectedBed,
    setSelectedBed
}) {


    const BedCard = ({ data, selected, onClick, disabled, unSelectable }) => {
        return (
            <span
                onClick={disabled || unSelectable ? null : onClick}
                className={`tw-relative ${disabled
                    ? `tw-cursor-not-allowed tw-p-4 tw-bg-gray-200 tw-text-sm tw-shadow-md tw-rounded tw-flex tw-flex-col tw-items-center tw-justify-center tw-w-[100px] tw-flex-shrink-0`
                    : `tw-p-4 ${unSelectable ? '' : 'hover:tw-bg-parrot-light hover:tw-text-white'
                    } tw-text-sm tw-shadow-md tw-rounded tw-cursor-pointer tw-flex tw-flex-col tw-items-center tw-justify-center tw-w-[100px] tw-flex-shrink-0 ${selected ? 'tw-bg-parrot-light tw-text-white' : ''
                    } `
                    }`}
            >
                <p>{data.bedNumber}</p>
                <FaBed size={24} />
                <p className="tw-text-xs tw-whitespace-normal">{data.bedType.split(' ')[0]}</p>
            </span>
        )
    }

    return (
        <div> {
            properyDetails && properyDetails.sleepingArrangements && <>
                <p className="tw-text-primary">{properyDetails && properyDetails.rentalType === 'Crashpad' ? "Bed Selection" : 'Room Selection'}   </p>
                <div className="tw-flex tw-justify-between tw-items-center tw-py-8">
                    <span
                        onClick={() => handleRoomSelection('prev')}
                        className="tw-flex-shrink-0 tw-mr-2 tw-hover:bg-gray-100 tw-h-8 tw-w-8 tw-rounded-full tw-grid tw-place-content-center "
                    >
                        <HiOutlineChevronLeft size={24} className="tw-cursor-pointer" />
                    </span>
                    <hr className="tw-border-dashed tw-border-gray-400 tw-mx-auto tw-w-full" />
                    <p className="tw-text-center tw-w-full tw-mx-2 tw-whitespace-nowrap">
                        Bedroom{' '}
                        {properyDetails && selectedRoom &&
                            properyDetails.sleepingArrangements.length &&
                            properyDetails.sleepingArrangements[selectedRoom.index].roomNumber}
                    </p>
                    <hr className="tw-border-dashed tw-border-gray-400 tw-mx-auto tw-w-full" />
                    <span
                        onClick={() => handleRoomSelection('next')}
                        className="tw-flex-shrink-0 tw-ml-2 tw-hover:bg-gray-100 tw-h-8 tw-w-8 tw-rounded-full tw-grid tw-place-content-center"
                    >
                        <HiOutlineChevronLeft
                            size={24}
                            className="tw-transform tw-rotate-180 tw-cursor-pointer"
                        />
                    </span>
                </div>
                <div className="tw-relative">
                    {/* {!selectedRoom.isAvailable && (
            <div className="tw-absolute tw-top-0 tw-bottom-0 tw-left-0 tw-right-0 tw-z-10 tw-rounded tw-bg-red-400 tw-bg-opacity-80 tw-text-white tw-grid tw-place-content-center">
                <p className="tw-text-xl tw-uppercase tw-transform tw-rotate-45">Reserved</p>
            </div>
        )} */}
                    <div className="tw-grid tw-grid-cols-2 tw-gap-4 tw-text-xs tw-text-gray-500 tw-text-center">
                        {properyDetails.rentalType === 'Crashpad' && selectedRoom && (
                            <>
                                <p className="tw-col-span-1">
                                    <span>Room Type </span> <br />
                                    <span className="tw-font-medium tw-text-primary">
                                        {(properyDetails &&
                                            properyDetails.sleepingArrangements.length &&
                                            properyDetails.sleepingArrangements[selectedRoom.index].roomType) ||
                                            '--'}
                                    </span>
                                </p>
                                <p className="tw-col-span-1">
                                    <span>Bathroom </span> <br />
                                    <span className="tw-font-medium tw-text-primary">
                                        {(properyDetails && selectedRoom &&
                                            properyDetails.sleepingArrangements.length &&
                                            properyDetails.sleepingArrangements[selectedRoom.index]
                                                .numberOfBathroom) ||
                                            '--'}
                                    </span>
                                </p>
                                <p className="tw-col-span-1">
                                    <span>Gender </span> <br />
                                    <span className="tw-font-medium tw-text-primary">
                                        {(properyDetails && selectedRoom &&
                                            properyDetails.sleepingArrangements.length &&
                                            properyDetails.sleepingArrangements[selectedRoom.index].genderType) ||
                                            '--'}
                                    </span>
                                </p>
                                <p className="tw-col-span-1">
                                    <span> Profession</span>
                                    <br />
                                    <span className="tw-font-medium tw-text-primary ">
                                        {(properyDetails && selectedRoom &&
                                            properyDetails.sleepingArrangements.length &&
                                            properyDetails.sleepingArrangements[selectedRoom.index].crewType) ||
                                            '--'}
                                    </span>
                                </p>
                            </>
                        )}
                    </div>
                    <div className="">
                        <div className="tw-flex tw-justify-between tw-mt-3 tw-items-center">
                            <span
                                onClick={scrollLeftHandlerForBed}
                                className="tw-flex-shrink-0 tw-mr-2 tw-hover:bg-gray-100 tw-h-8 tw-w-8 tw-rounded-full tw-grid tw-place-content-center"
                            >
                                <HiOutlineChevronLeft size={24} className="tw-cursor-pointer" />
                            </span>
                            <div
                                ref={bedsContainer}
                                className="tw-flex tw-justify-center tw-items-center tw-w-full tw-gap-2 tw-flex-nowrap tw-p-4 tw-px-2 tw-overflow-scroll tw-scrollbar-hide"
                            >
                                {properyDetails && selectedRoom &&
                                    properyDetails.sleepingArrangements.length &&
                                    properyDetails.sleepingArrangements[selectedRoom.index].beds.map(
                                        (el, ind) => {
                                            return (
                                                <React.Fragment key={ind}>
                                                    <BedCard
                                                        unSelectable={properyDetails.rentalType === 'Entire Place'}
                                                        data={el}
                                                        selected={selectedBed && properyDetails.rentalType !== 'Entire Place' && selectedBed._id === el._id}
                                                        onClick={() => {
                                                            setSelectedBed(el)
                                                        }}
                                                    />
                                                </React.Fragment>
                                            )
                                        }
                                    )}
                            </div>
                            <span
                                onClick={scrollRightHandlerForBed}
                                className="tw-flex-shrink-0 tw-ml-2 tw-hover:tw-bg-gray-100 tw-h-8 tw-w-8 tw-rounded-full tw-grid tw-place-content-center"
                            >
                                <HiOutlineChevronLeft
                                    size={24}
                                    className="tw-transform tw-rotate-180 tw-cursor-pointer"
                                />
                            </span>
                        </div>
                    </div>
                </div>

            </>
        }

        </div>
    )
}


