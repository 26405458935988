import React, { useState, useEffect, useRef, useCallback } from 'react'
import Picker from 'emoji-picker-react'
import { useHistory } from 'react-router-dom'
import { Client } from 'twilio-chat'
import { getChatToken } from './../../_services/index'

import ConversationHeader from './ConversationHeader'
import ConversationCard from './ConversationCard'
import MessageInput from './MessageInput'

const messageToReadable = (arr, userId) => {
    return arr.map(curr => ({
        text: curr.state.body,
        author: curr.state.author,
        type: curr.state.author.toString() === userId.toString() ? 'sent' : 'received',
        timestamp: new Date(curr.state.timestamp),
    }))
}

function Conversation({ userId, client, channelDetails, setViewList, cssClasses, cssChangeClass }) {
    const [loading, setLoading] = useState(true)
    const [messages, setMessages] = useState([])
    const [members, setMembers] = useState({})
    const [showPicker, setShowPicker] = useState(false)
    const [text, setText] = useState('')
    const channel = useRef()
    const chatRef = useRef()
    const observer = useRef()
    const history = useHistory()

    const scrollToBottom = () => {
        if (chatRef.current) {
            chatRef.current.scrollTop = chatRef.current.scrollHeight
        }
    }

    const messageAdded = async (newMessage) => {
        setMessages((prev) => [
            ...prev,
            {
                text: newMessage.state.body,
                author: newMessage.state.author,
                type: newMessage.state.author.toString() === userId.toString() ? 'sent' : 'received',
                timestamp: new Date(newMessage.state.timestamp),
            },
        ])
        scrollToBottom()

        try {
            // Update the last consumed message index to mark the message as read
            await channel.current.updateLastConsumedMessageIndex(newMessage.index)
        } catch (error) {
            console.error('Failed to update last consumed message index', error)
        }
    }

    const handleMessage = messages => {
        setMessages(messages)
        scrollToBottom()
    }

    const messagesLoaded = curr => {
        const messages = messageToReadable(curr.items || [], userId)

        handleMessage(messages)
    }

    const sendMessage = msg => {
        if (msg) {
            channel.current.sendMessage(msg)
            setText('')
        }
    }

    const onEmojiClick = (emojiObject) => {
        setText(prevInput => prevInput + emojiObject.emoji)
        setShowPicker(false)
    }

    const lastMessageRef = useCallback(
        node => {
            if (loading) return
            if (observer.current) observer.current.disconnect()
            observer.current = new IntersectionObserver(entries => {
                if (entries[0].isIntersecting && channel.current.hasPrevPage) {
                    // Load more messages if there are previous pages available
                    channel.current.getMessages().then(messagesLoaded)
                }
            })
            if (node) observer.current.observe(node)
        },
        [loading]
    )

    const setupChatClient = async () => {
        setMessages([])
        setLoading(true)
    
        try {
            // Handle channel retrieval or creation
            if (!channelDetails && history.location.state) {
                const { channelId, friendlyName, property } = history.location.state
    
                if (channelId && friendlyName && property) {
                    channel.current = await client.current.getChannelByUniqueName(channelId)
                } else {
                    console.error('Invalid channel details in history state')
                }
            } else if (channelDetails) {
                channel.current = await client.current.getChannelByUniqueName(channelDetails.channelId)
            }
        } catch (error) {
            if (error.body.code === 50300) {
                console.warn(`Channel not found, creating a new channel: ${error.message}`)
    
                try {
                    const { channelId, friendlyName, property } = history.location.state
                    if (channelId && friendlyName && property) {
                        channel.current = await client.current.createChannel({
                            uniqueName: channelId,
                            friendlyName: friendlyName,
                            attributes: { property },
                        })
    
                        // Trigger refresh of the chat list if a new channel is created
                        setTimeout(() => {
                            setViewList((prev) => !prev)
                        }, 2000)
                    } else {
                        console.error('Invalid channel details in history state')
                    }
                } catch (createError) {
                    console.error('Error creating a new channel:', createError)
                    setLoading(false)
                    return
                }
            } else {
                console.error('Error retrieving channel:', error)
                setLoading(false)
                return
            }
        }
    
        if (channel.current) {
            try {
                // Load messages
                try {
                    const lastConsumedMessageIndex = await channel.current.lastConsumedMessageIndex
                    const totalMessages = await channel.current.getMessagesCount()
    
                    // Attach messageAdded event listener
                    channel.current.on('messageAdded', messageAdded)
    
                    const messagesPage = await channel.current.getMessages()
                    messagesLoaded(messagesPage)
    
                    // Update the last consumed message index
                    if (totalMessages > 0) {
                        const newIndex = Math.max(lastConsumedMessageIndex, totalMessages - 1)
                        if (lastConsumedMessageIndex === null || newIndex >= 0) {
                            await channel.current.updateLastConsumedMessageIndex(newIndex)
                        }
                    }
                } catch (messageLoadError) {
                    console.error('Error loading messages:', messageLoadError)
                    setLoading(false)
                    return
                }
    
                // Load members
                try {
                    const members = {}
                    const membersList = await channel.current.getMembers()
                    if (membersList) {
                        for (let member of membersList) {
                            const userAttributes = await member.getUser().then((user) => user.attributes)
                            members[member.identity] = {
                                name: userAttributes.name || 'Unknown',
                                profile: userAttributes.profile || null,
                            }
                        }
                        setMembers(members)
                    }
                } catch (membersError) {
                    console.error('Error loading members:', membersError)
                    setLoading(false)
                    return
                }
    
                // Invite the host
                const hostId = history.location.state.host.id.toString() || history.location.state.host._id
                if (hostId) {
                    try {
                        await channel.current.invite(hostId)
                    } catch (inviteError) {
                        if (inviteError.body.code !== 50212) {
                            try {
                                const { data } = await getChatToken(hostId)
                                const newClient = await new Client(data.token)
                                await newClient.getChannelByUniqueName(channelDetails.channelId).then(async (newChannel) => {
                                    await newChannel.invite(hostId)
                                })
                            } catch (tokenError) {
                                console.error('Error inviting host:', tokenError)
                            }
                        } else {
                            console.error('Host already invited:', inviteError)
                        }
                    }
                }
    
                await channel.current.join()
            } catch (loadError) {
                console.error('Error loading messages or members:', loadError)
            }
        } else {
            console.warn('Channel could not be set up, please check channel details.')
        }
    
        setLoading(false)
    }

    useEffect(() => {
        setupChatClient()

        return () => {
            if (channel.current) {
                channel.current.removeAllListeners()
            }
        }
    }, [channelDetails, client])

    return (
        <div 
            style={{
                display: 'flex',
                flexDirection: 'column',
                borderRadius: '0.375rem',
                fontSize: '0.875rem',
                overflow: 'hidden',
                borderLeft: '1px solid #E5E7EB',
            }}
            className={ cssClasses ? "coonversation-content coversition-content":"coversition-content"}
        >
            <>
                {loading ? (
                    <>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                margin: 'auto',
                            }}
                        >
                            <div
                                style={{
                                    backgroundColor: 'white',
                                    // width: '100%',
                                    margin: 'auto',
                                    borderRadius: '0.375rem', // equivalent to tw-rounded
                                    padding: '1rem',
                                }}
                            >
                                <div className="loader"></div>
                            </div>
                        </div>
                    </>
                ) : channelDetails ? (
                    <>
                        <ConversationHeader cssChange={cssChangeClass} channelDetails={channelDetails} setViewList={setViewList} />
                        <div
                            ref={chatRef}
                            style={{
                                flexGrow: 1,
                                overflowY: 'scroll',
                                paddingLeft: '1rem',
                                msOverflowStyle: 'none',
                                scrollbarWidth: 'none',
                            }}
                        >
                            {/* {hasPrevPage && (
                                <p
                                    onClick={onLoadingPreviousMessage}
                                    style={{
                                        cursor: 'pointer',
                                        fontSize: '0.75rem',
                                        width: 'max-content',
                                        marginInline: 'auto',
                                        marginBlock: '0.5rem',
                                        textAlign: 'center',
                                        padding: '0.5rem',
                                        paddingInline: '1rem',
                                        backgroundColor: '#23426e',
                                        borderRadius: '0.375rem',
                                        color: 'white',
                                    }}
                                >
                                    Load more
                                </p>
                            )} */}
                            {messages.map((curr, ind) => (
                                <ConversationCard
                                    key={ind}
                                    user={channelDetails.user}
                                    host={channelDetails.host}
                                    messageInstance={curr}
                                    members={members}
                                    ref={ind === messages.length - 1 ? lastMessageRef : null}
                                />
                            ))}
                            {showPicker && <Picker pickerStyle={{ width: '100%' }} onEmojiClick={(e) => onEmojiClick(e)} />}
                        </div>
                        <MessageInput
                            text={text}
                            setText={setText}
                            sendMessage={sendMessage}
                            showPicker={showPicker}
                            setShowPicker={setShowPicker}
                            onEmojiClick={onEmojiClick}
                        />
                    </>
                ) : null}
            </>
        </div>
    )
}

export default Conversation
