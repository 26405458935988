import React, { useEffect, useState } from "react";
import { Wrapper } from "./Wrapper";
import { ToastContainer } from "react-toastify";
import Individual from "./Individual";
import Company from "./Company";
import { bankService } from "../../_services";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function UpdatePaymentInfo() {
  const [business_type, setBusiness_type] = useState("individual");

  const [stripeDetails, setStripeDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [updateStripeDetails, setUpdateStripeDetails] = useState(new Date());

  const history = useHistory();

  useEffect(() => {
    bankService
      .getStripeDetail()
      .then((res) => {
        if (res.data.business_type) {
          setBusiness_type(res.data.business_type);
        }
        setStripeDetails(res.data);
        setIsLoading(false);

        if (res.data.charges_enabled && res.data.payouts_enabled) {
          history.goBack();
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [updateStripeDetails]);

  return (
    <Wrapper>
      <ToastContainer />
      {isLoading ? (
        "Loading.."
      ) : (
        <div className="col-lg-6  tw-space-y-3 tw-my-4">
          <div className="mb-3">
            <label className="form-label">Business Type</label>
            <select
              value={business_type}
              onChange={(e) => {
                setBusiness_type(e.target.value);
              }}
              className="form-control  wide"
              name="business_type"
              required
            >
              <option value="individual">Individual</option>
              <option value="company">Company</option>
            </select>
          </div>

          {business_type === "individual" ? (
            <Individual
              setUpdateStripeDetails={setUpdateStripeDetails}
              stripeDetails={stripeDetails}
            />
          ) : business_type === "company" ? (
            <Company
              setUpdateStripeDetails={setUpdateStripeDetails}
              stripeDetails={stripeDetails}
            />
          ) : null}
        </div>
      )}
    </Wrapper>
  );
}

export default UpdatePaymentInfo;
