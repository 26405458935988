import React, { useEffect, useState } from "react";
import PersonalDetail from "./PersonalDetails";
import TextInput from "./TextInput";
import { payoutService } from "../../_services/payout.service";
import {
  isAgeValid,
  validateSSNLast4,
  validateUSPostalCode,
  validateWebsiteAddress,
} from "../../_helpers/helpers";
import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function Company({ setUpdateStripeDetails, stripeDetails }) {
  const [formError, setFormError] = useState(null);
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [allStripeVerificationErrorsFields, setAllStripeVerificationErrorsFields] = useState([])
  const [formData, setFormData] = useState({
    business_type: "company",
    capabilities: {
      card_payments: {
        requested: true,
      },
      transfers: {
        requested: true,
      },
    },
    business_profile: {
      mcc: "7011",
      url: "",
    },

    company: {
      name: "",
      structure: "sole_proprietorship",
      address: {
        line1: "",
        postal_code: "",
        city: "",
        state: "",
      },
      tax_id: "",
      phone: "",
    },
    representative: {
      dob: {
        day: "",
        month: "",
        year: "",
      },
      address: {
        line1: "",
        postal_code: "",
        city: "",
        state: "",
      },
      email: "",
      phone: "",
      ssn_last_4: "",
      id_number: "",
      relationship: {
        representative: true,
        percent_ownership: "100",
        title: "CEO",
      },
      first_name: "",
      last_name: "",
    },
  });
  const [owners, setOwners] = useState({
    first_name: "",
    last_name: "",
    email: "",
    relationship: {
      owner: true,
      percent_ownership: "100",
    },
  });

  useEffect(() => {
    getStripeConnectDraft();
  }, []);

  useEffect(() => {
    if (stripeDetails.requirements.errors.length > 0) {
      let getAllStripeFieldsInWhichErrorIsThere = [...stripeDetails.requirements.currently_due, ...stripeDetails.requirements.eventually_due];

      getAllStripeFieldsInWhichErrorIsThere.forEach((items) => {
        if (items.startsWith('person')) {
          const strList = items.split(".");
          if (strList.length > 3) {
            getAllStripeFieldsInWhichErrorIsThere.push(`representative.${strList[strList.length - 1]}`);
          } else {
            getAllStripeFieldsInWhichErrorIsThere.push(`representative.${strList[strList.length - 2]}.${strList[strList.length - 1]}`);
          }
        }
        items.split(".").forEach((subItems) => {
          getAllStripeFieldsInWhichErrorIsThere.push(subItems);
        })
      })
      setAllStripeVerificationErrorsFields(getAllStripeFieldsInWhichErrorIsThere)
    }

  }, [stripeDetails]);
  const getStripeConnectDraft = async () => {
    try {
      let result = await payoutService.getStripeConnectDraftData("company");

      if (result.data && result.data.data && result.data.data.formdata) {
        console.log(result.data.data.formdata, "HOOOOOOOOOOO");
        if ([
          "multi_member_llc",
          "private_partnership",
          "private_corporation",
          "unincorporated_association",
        ].includes(result.data.data.formdata.company.structure)) {
          setOwners((prev) => ({
            ...prev,
            ...result.data.data.formdata.owners,
          }))
          delete result.data.data.formdata.owners
        }
        setFormData((prev) => ({
          // ...prev,
          ...result.data.data.formdata,
          company: {
            ...result.data.data.formdata.company,
            tax_id: "",
          },
          representative: {
            ...result.data.data.formdata.representative,
            id_number: "",
            ssn_last_4: ""
          }
        }));

      }
    } catch (error) { }
  };

  const handleRepresentativeDetailChange = (e) => {
    if (e.target.name === "dob") {
      const dateObj = e.target.value.split("-");
      const d = {
        year: dateObj[0].toString(),
        month: dateObj[1].toString(),
        day: dateObj[2].toString(),
      };

      setFormData({
        ...formData,
        representative: {
          ...formData.representative,
          dob: d,
        },
      });
    } else {
      setFormData({
        ...formData,
        representative: {
          ...formData.representative,
          [e.target.name]: e.target.value,
        },
      });
    }
  };

  const handleRepresentativeRelationship = (e) => {
    setFormData({
      ...formData,
      representative: {
        ...formData.representative,
        relationship: {
          ...formData.representative.relationship,
          title: e.target.value,
        },
      },
    });
  };

  const handleBusinessUrl = (e) => {
    setFormData({
      ...formData,
      business_profile: {
        ...formData.business_profile,
        url: e.target.value,
      },
      settings: {
        payments: {
          statement_descriptor: "",
        },
      },
    });
  };

  const validateForm = () => {
    setFormError(null);
    function isValidEmail(email) {
      // Regular expression for a simple email validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      return emailRegex.test(email);
    }

    function isValidUSPhoneNumber(phoneNumber) {
      // Regular expression for a simple US phone number validation
      const phoneRegex = /^\d{10}$/;

      // Remove non-numeric characters from the phone number
      const numericPhoneNumber = phoneNumber.replace(/\D/g, "");

      return phoneRegex.test(numericPhoneNumber);
    }

    let error = {};

    if (!validateWebsiteAddress(formData.business_profile.url)) {
      error.business_profile_url = "Company's website is required";
    }

    const invalidDomains = {
      'facebook.com': "Please enter valid URL that links to your business. Example: www.facebook.com/crewmatesapp",
      'x.com': "Please enter valid URL that links to your business. Example: www.facebook.com/crewmatesapp",
      'twitter.com': "Please enter valid URL that links to your business. Example: www.facebook.com/crewmatesapp",
      'instagram.com': "Please enter valid URL that links to your business. Example: www.facebook.com/crewmatesapp",
      'linkedin.com': "Please enter valid URL that links to your business. Example: www.facebook.com/crewmatesapp",
      'crewmatesapp.com': "Please enter valid URL that links to your business. Example: www.facebook.com/crewmatesapp",
      'host.crewmatesapp.com': "Please enter valid URL that links to your business. Example: www.facebook.com/crewmatesapp",
      'crewmatescrashpads.com': "Please enter valid URL that links to your business. Example: www.facebook.com/crewmatesapp"
    };

    const urlPatterns = Object.keys(invalidDomains).map(domain => new RegExp(`^(http:\/\/|https:\/\/)?(www\.)?${domain}\/?$`, 'i'));
    const subdomainPattern = /^(http:\/\/|https:\/\/)?[a-z0-9]+(\.[a-z0-9]+)+\./i;

    let siteUrl = formData.business_profile.url.trim().toLowerCase() || '';

    if (!subdomainPattern.test(siteUrl) && !siteUrl.startsWith('www.')) {
      error.business_profile_url = error.business_profile_url ? error.business_profile_url : "Please add www. to the beginning of the website";
    } else {
      for (const [index, pattern] of urlPatterns.entries()) {
        if (pattern.test(siteUrl)) {
          error.business_profile_url = invalidDomains[Object.keys(invalidDomains)[index]];
          break;
        }
      }
    }

    if (formData.representative.first_name.trim().length === 0) {
      error.first_name = "First name is required";
    }
    if (formData.representative.last_name.trim().length === 0) {
      error.last_name = "Last name is required";
    }
    if (
      formData.representative.dob.month.trim().length === 0 ||
      formData.representative.dob.year.trim().length === 0 ||
      formData.representative.dob.day.trim().length === 0
    ) {
      error.dob = "Date of birth is required";
    } else if (
      !isAgeValid(
        formData.representative.dob.day,
        formData.representative.dob.month,
        formData.representative.dob.year
      )
    ) {
      error.dob = "Date of birth should be between 13 to 120 years";
    }

    if (!isValidEmail(formData.representative.email)) {
      error.email = "Valid email is required";
    }

    if (
      !["public_partnership", "public_corporation"].includes(
        formData.company.structure
      )
    ) {
      if (!isValidUSPhoneNumber(formData.representative.phone)) {
        error.phone = "Valid phone number is required";
      }
      if (formData.representative.address.city.trim().length === 0) {
        error.city = "City is required";
      }

      if (formData.representative.address.line1.trim().length === 0) {
        error.line1 = "Line 1 address is required";
      }

      if (formData.representative.address.postal_code.trim().length === 0) {
        error.line1 = "Postal code is required";
      }
      if (formData.representative.address.state.trim().length === 0) {
        error.state = "State is required";
      }
      if (!validateSSNLast4(formData.representative.ssn_last_4)) {
        error.ssn_last_4 = "Valid last 4 digit of SSN is required";
      }
      if (formData.representative.id_number.trim().length === 0) {
        error.id_number = "Id number is required";
      }

      if (formData.representative.address.line1.trim().length === 0) {
        error.line1 = "Line 1 address is required";
      }
      if (formData.representative.address.city.trim().length === 0) {
        error.city = "City is required";
      }

      if (formData.representative.address.postal_code.trim().length === 0) {
        error.postal_code = "Postal code is required";
      } else if (
        !validateUSPostalCode(formData.representative.address.postal_code)
      ) {
        error.postal_code = "Postal code is invalid";
      }

      if (formData.representative.address.state.trim().length === 0) {
        error.state = "State is required";
      }
    }

    if (formData.representative.id_number.trim().length === 0) {
      error.id_number = "Id number is required";
    }

    if (formData.representative.relationship.title.trim().length === 0) {
      error.relationship_title = "Your relationship with company is required";
    }

    /*
      company detail validation
      */

    if (formData.company.name.trim().length === 0) {
      error.company_name = "Company name is required";
    }

    if (
      [
        "multi_member_llc",
        "private_partnership",
        "private_corporation",
        "unincorporated_association",
        "public_partnership",
        "public_partnership",
      ].includes(formData.company.structure)
    ) {
      if (!isValidUSPhoneNumber(formData.company.phone)) {
        error.company_phone = "Valid phone number is required";
      }
    }

    if (formData.company.tax_id.trim().length === 0) {
      error.company_tax_id = "Tax id is required";
    }

    if (formData.company.address.line1.trim().length === 0) {
      error.company_line1 = "Line 1 is required";
    }

    if (formData.company.address.city.trim().length === 0) {
      error.company_city = "City is required";
    }

    if (formData.company.address.state.trim().length === 0) {
      error.company_state = "State is required";
    }

    if (formData.company.address.postal_code.trim().length === 0) {
      error.company_postal_code = "Postal code is required";
    } else if (!validateUSPostalCode(formData.company.address.postal_code)) {
      error.company_postal_code = "Postal code is invalid";
    }

    if (
      [
        "multi_member_llc",
        "private_partnership",
        "private_corporation",
        "unincorporated_association",
      ].includes(formData.company.structure)
    ) {
      if (owners.first_name.trim().length === 0) {
        error["owners_first_name"] = "First name is required";
      }

      if (owners.last_name.trim().length === 0) {
        error["owners_last_name"] = "Last name is required";
      }

      if (owners.email.trim().length === 0) {
        error["owners_email"] = "Email is required";
      } else if (!isValidEmail(owners.email)) {
        error["owners_email"] = "Valid email is required";
      }
    }

    console.log(error, "FORMERRRRRR");

    if (error && Object.entries(error).length === 0) {
      return true;
    } else {
      setFormError(error);
      return false;
    }
  };

  const handleCompanyDetailChange = (e) => {
    setFormData({
      ...formData,
      company: {
        ...formData.company,
        [e.target.name]: e.target.value,
      },
    });
  };

  const handleCompanyAddressChange = (e) => {
    setFormData({
      ...formData,
      company: {
        ...formData.company,
        address: {
          ...formData.company.address,
          [e.target.name]: e.target.value,
        },
      },
    });
  };

  const handleRepresentativeAddressChange = (e) => {
    setFormData({
      ...formData,
      representative: {
        ...formData.representative,
        address: {
          ...formData.representative.address,
          [e.target.name]: e.target.value,
        },
      },
    });
  };

  const handleOwnersChange = (e) => {
    setOwners({
      ...owners,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      toast.error("Highlighted fields are required.");
    } else {
      try {
        // let payload = {...formData};
        let payload = JSON.parse(JSON.stringify(formData));
        delete payload.company.tax_id;
        delete payload.representative.ssn_last_4;
        delete payload.representative.id_number;

        if ([
          "multi_member_llc",
          "private_partnership",
          "private_corporation",
          "unincorporated_association",
        ].includes(formData.company.structure)) {
          payload.owners = owners
        }
        // let 
        await payoutService.saveStripeConnectDraftData("company", payload);
        const { representative, ...data } = formData;

        if (data.company.structure === "sole_proprietorship") {
          const { phone, ...comp } = data.company;
          data.company = comp;
        }

        if (data.company.structure === "single_member_llc") {
          const { phone, ...comp } = data.company;
          data.company = comp;
        }

        if (data.company.structure === "multi_member_llc") {
          data.company.owners_provided = true;
        }

        if (data.company.structure === "private_partnership") {
          data.company.owners_provided = true;
        }

        if (data.company.structure === "private_corporation") {
          data.company.owners_provided = true;
        }

        if (data.company.structure === "unincorporated_association") {
          data.company.owners_provided = true;
        }

        setIsSubmitting(true);
        const result = await payoutService.updateStripeAccount(
          data,
          representative,
          owners
        );
        setIsSubmitting(false);
        console.log(result.data);
        setUpdateStripeDetails(new Date());

        if (result.data.status === false) {
          if (result.data.message) {
            const param = result.data.message.param;
            toast.info(result.data.message.raw.message);
            let error = {};
            setFormError(error);
          }
        } else {
          toast.info("Your Information has been submitted for review");
          history.goBack();
        }
      } catch (error) {
        console.log(error);
        toast.error("Failed to update");
      }
    }
  };
  return (
    <>
      <form className="company_details" onSubmit={handleSubmit}>
        <ToastContainer />
        <div>
          <div className="tw-flex tw-flex-col mt-5 mb-2">
            <label className="tw-text-sm">Account Information</label>
          </div>

          <TextInput
            type="text"
            value={formData.business_profile.url}
            onChange={handleBusinessUrl}
            label="Website"
            name={"business_profile_url"}
            highlightError={['business_profile.url', 'url'].some(value => allStripeVerificationErrorsFields.includes(value))}
            toolTipText={
              "No website? You can share a URL from a Facebook page or group, Instagram page, X profile, LinkedIn profile, or any other link that provides information about your business."
            }
            error={formError && formError.business_profile_url}
          />
          <div className="tw-flex tw-flex-col mt-5 mb-2">
            <label className="tw-text-sm">Company Information</label>
          </div>

          <div className="mb-3">
            <label className="form-label">Company structure*</label>
            <select
              onChange={handleCompanyDetailChange}
              className="form-control  wide"
              name="structure"
              required
              value={formData.company.structure}
            >
              <option value="sole_proprietorship">Sole Proprietorship</option>
              <option value="single_member_llc">Single Member LLC</option>
              <option value="multi_member_llc">Multi Member LLC</option>
              <option value="private_partnership">Private Partnership</option>
              <option value="private_corporation">Private Corporation</option>
              <option value="unincorporated_association">
                Unincorporated Association
              </option>
              <option value="public_partnership">Public Partnership</option>
              <option value="public_corporation">Public Corporation</option>
            </select>
          </div>

          <TextInput
            type="text"
            value={formData.company.name}
            onChange={handleCompanyDetailChange}
            label="Company name*"
            name={"name"}
            highlightError={['company.name'].some(value => allStripeVerificationErrorsFields.includes(value))}
            toolTipText={
              "The combination of the connected account's name and Employer Identification Number (EIN) must exactly match the one listed on the connected account's IRS documents (e.g., Letter 147C or SS-4 Confirmation letter), including capitalization and punctuation."
            }
            error={formError && formError.company_name}
          />

          {[
            "multi_member_llc",
            "private_partnership",
            "private_corporation",
            "unincorporated_association",
            "public_partnership",
            "public_corporation",
          ].includes(formData.company.structure) && (
              <TextInput
                type="text"
                value={formData.company.phone}
                onChange={handleCompanyDetailChange}
                label="Company's Phone number*"
                name={"phone"}
                highlightError={['company.phone'].some(value => allStripeVerificationErrorsFields.includes(value))}
                error={formError && formError.company_phone}
              />
            )}

          <TextInput
            type="text"
            value={formData.company.address.line1}
            onChange={handleCompanyAddressChange}
            label="Address line 1*"
            highlightError={['company.address.line1'].some(value => allStripeVerificationErrorsFields.includes(value))}
            name={"line1"}
            error={formError && formError.company_line1}
          />

          <TextInput
            type="text"
            value={formData.company.address.postal_code}
            onChange={handleCompanyAddressChange}
            label="Postal code*"
            name={"postal_code"}
            highlightError={['company.address.postal_code'].some(value => allStripeVerificationErrorsFields.includes(value))}
            error={formError && formError.company_postal_code}
          />
          <TextInput
            type="text"
            value={formData.company.address.city}
            onChange={handleCompanyAddressChange}
            label="City*"
            name={"city"}
            highlightError={['company.address.city'].some(value => allStripeVerificationErrorsFields.includes(value))}
            error={formError && formError.company_city}
          />

          <TextInput
            type="text"
            value={formData.company.address.state}
            onChange={handleCompanyAddressChange}
            label="State*"
            name={"state"}
            highlightError={['company.address.state'].some(value => allStripeVerificationErrorsFields.includes(value))}
            error={formError && formError.company_state}
          />

          <TextInput
            type="text"
            value={formData.company.tax_id}
            onChange={handleCompanyDetailChange}
            label="Company Tax Id*"
            name={"tax_id"}
            toolTipText={
              "If the connected account uses their Social Security number for business tax purposes, you can enter that instead."
            }
            highlightError={['company.tax_id'].some(value => allStripeVerificationErrorsFields.includes(value))}
            error={formError && formError.company_tax_id}
          />
        </div>

        <div className="representative_details">
          <div className="tw-flex tw-flex-col mt-5 mb-2">
            <label className="tw-text-sm">Representative Details</label>
          </div>
          <PersonalDetail
            businessStructure={formData.company.structure}
            onChange={handleRepresentativeDetailChange}
            person={formData.representative}
            error={formError}
            formDataKey={'representative'}
            verificationErrorFieldsList={allStripeVerificationErrorsFields}
          />

          {![
            "public_partnership",
            "public_partnership",
            "public_corporation",
          ].includes(formData.company.structure) && (
              <>
                <TextInput
                  type="text"
                  value={formData.representative.address.line1}
                  onChange={handleRepresentativeAddressChange}
                  label="Address line 1*"
                  name={"line1"}
                  highlightError={['representative.address.line1'].some(value => allStripeVerificationErrorsFields.includes(value))}
                  error={formError && formError.line1}
                />

                <TextInput
                  type="text"
                  value={formData.representative.address.postal_code}
                  onChange={handleRepresentativeAddressChange}
                  label="Postal code*"
                  name={"postal_code"}
                  highlightError={['representative.address.postal_code'].some(value => allStripeVerificationErrorsFields.includes(value))}
                  error={formError && formError.postal_code}
                />
                <TextInput
                  type="text"
                  value={formData.representative.address.city}
                  onChange={handleRepresentativeAddressChange}
                  label="City*"
                  name={"city"}
                  highlightError={['representative.address.city'].some(value => allStripeVerificationErrorsFields.includes(value))}
                  error={formError && formError.city}
                />

                <TextInput
                  type="text"
                  value={formData.representative.address.state}
                  onChange={handleRepresentativeAddressChange}
                  label="State*"
                  name={"state"}
                  highlightError={['representative.address.state'].some(value => allStripeVerificationErrorsFields.includes(value))}
                  error={formError && formError.state}
                />
              </>
            )}

          {[
            "multi_member_llc",
            "private_partnership",
            "private_corporation",
            "unincorporated_association",
            "public_partnership",
          ].includes(formData.company.structure) && (
              <TextInput
                required
                type="text"
                onChange={handleRepresentativeRelationship}
                label={"Relationship with company*"}
                value={formData.representative.relationship.title}
                highlightError={['representative.relationship.title'].some(value => allStripeVerificationErrorsFields.includes(value))}

                error={formError && formError.relationship_title}
              />
            )}

          {[
            "multi_member_llc",
            "private_partnership",
            "private_corporation",
            "unincorporated_association",
          ].includes(formData.company.structure) && (
              <>
                <div className="tw-flex tw-flex-col mt-5 mb-2">
                  <label className="tw-text-sm">Owner Information</label>
                </div>

                <TextInput
                  type="text"
                  onChange={handleOwnersChange}
                  label={"Owner First Name*"}
                  name={"first_name"}
                  value={owners.first_name}
                  error={formError && formError.owners_first_name}
                  highlightError={['owners.first_name'].some(value => allStripeVerificationErrorsFields.includes(value))}

                />

                <TextInput
                  type="text"
                  onChange={handleOwnersChange}
                  label={"Owner Last Name*"}
                  name={"last_name"}
                  value={owners.last_name}
                  highlightError={['owners.last_name'].some(value => allStripeVerificationErrorsFields.includes(value))}
                  error={formError && formError.owners_last_name}
                />

                <TextInput
                  type="text"
                  onChange={handleOwnersChange}
                  label={"Owner Email*"}
                  name={"email"}
                  value={owners.email}
                  highlightError={['owners.email'].some(value => allStripeVerificationErrorsFields.includes(value))}
                  error={formError && formError.owners_email}
                />
              </>
            )}

          <div className="">
            <button
              type="submit"
              className="tw-rounded-md mt-4  tw-p-3 tw-px-8 tw-bg-primary tw-border-0 tw-text-white tw-text-sm "
            >
              {isSubmitting ? "..." : "Submit"}
            </button>
          </div>
        </div>
      </form>
    </>
  );
}

export default Company;
