import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getToken } from '../_helpers';


const PrivateRoute = ({ component: Component, ...rest }) => {
    return (
        <Route {...rest} render={props => (
            getToken() ?<> 
            
            <p style={{display:'none'}}>private route active</p>
            
            <Component {...props} /> </>: <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        )} />
    );

};

export default PrivateRoute;
