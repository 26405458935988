import React, { useEffect, useState } from "react";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { paymentService } from "../../_services";
import { ToastContainer } from "react-toastify";
import { Wrapper } from "./Wrapper";

function TransactionDetail(data) {
  const { transactionId } = useParams();
  const [transaction, setTransaction] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return date.toLocaleDateString();
  };

  useEffect(() => {
    fetchTransaactinon();
  }, []);

  const fetchTransaactinon = async () => {
    try {
      setIsLoading(true);
      const result = await paymentService.fetchTransactionById(transactionId);
      console.log(result.data);
      setTransaction(result.data);
    } catch (error) {}
    setIsLoading(false);
  };

  return (
    <div>
      <ToastContainer />
      <Wrapper title={`Transaction id: ${transactionId}`}>
        {isLoading ? (
          "Loading..."
        ) : (
          <>
            {transaction && (
              <>
                <div className="card mb-3">
                  <div className="card-body">
                    <table className="table table-bordered">
                      <tbody>
                        <tr>
                          <th>ID</th>
                          <td>{transaction.id}</td>
                        </tr>
                        <tr>
                          <th>Amount</th>
                          <td>${transaction.amount / 100}</td>
                        </tr>
                        <tr>
                          <th>Available On</th>
                          <td>{formatDate(transaction.available_on)}</td>
                        </tr>
                        <tr>
                          <th>Created</th>
                          <td>{formatDate(transaction.created)}</td>
                        </tr>
                        <tr>
                          <th>Description</th>
                          <td>{transaction.description}</td>
                        </tr>
                        <tr>
                          <th>Type</th>
                          <td>{transaction.type}</td>
                        </tr>
                        <tr>
                          <th>Status</th>
                          <td>{transaction.status}</td>
                        </tr>
                        <tr>
                          <th>Source</th>
                          <td>{transaction.source}</td>
                        </tr>
                        <tr>
                          <th>Net</th>
                          <td>
                            {transaction.net / 100} {transaction.currency}
                          </td>
                        </tr>
                        <tr>
                          <th>Reporting Category</th>
                          <td>{transaction.reporting_category}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </Wrapper>
    </div>
  );
}

export default TransactionDetail;
