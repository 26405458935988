import React, { useState, useRef } from "react";

import "react-toastify/dist/ReactToastify.css";

var Modal = require("react-bootstrap-modal");

export const Details = ({
  onNext,
  onPrev,
  className,
  formData,
  setFormData,
  save,
  updating,
}) => {
  const summaryRef = useRef();
  const [stepForm, setStepForm] = useState({
    listingName: formData.listingName,
    aboutListing: formData.aboutListing,
    transportation: formData.transportation,
    parking: formData.parking,
  });

  const getFile = (e) => {
    setStepForm({ ...stepForm, file: e.target.files[0] });
  };

  const handleStep = (tag, value) => {
    setStepForm({ ...stepForm, [tag]: value });
  };

  const handleNextStep = (e) => {
    e.preventDefault();
    // setFormData((el) => {
    //   return { ...el, ...stepForm };
    // });
    save(stepForm);
    onNext();
  };

  return (
    <form onSubmit={handleNextStep} className={className}>
      <div className={`tw-min-h-[60vh]`}>
        <div className={`row`}>
          <h4 className="mb-5 tw-flex tw-justify-start tw-items-center">
            <span className="tw-bg-[#23426e] tw-mr-2 tw-flex tw-justify-center tw-items-center  tw-h-10 tw-w-10 tw-text-white  tw-rounded-full">
              2
            </span>{" "}
            Details
          </h4>
          <div className="col-lg-6 mb-2">
            <div className="mb-3">
              <label className="text-label form-label">Listing Name</label>
              <input
                type="text"
                value={stepForm["listingName"]}
                onChange={(e) => handleStep("listingName", e.target.value)}
                name="listingName"
                className="form-control"
                placeholder="Listing Name"
                required
              />
            </div>
          </div>

          <div className="col-lg-6 mb-2">
            <div className="mb-3">
              <label className="text-label form-label">Transportation</label>
              <input
                value={stepForm["transportation"]}
                onChange={(e) => handleStep("transportation", e.target.value)}
                type="text"
                name="transportation"
                className="form-control"
                placeholder="Enter Something"
                required
              />
            </div>
          </div>
          <div className="col-lg-6 mb-2">
            <div className="mb-3">
              <label className="text-label form-label">Parking</label>
              <input
                value={stepForm["parking"]}
                onChange={(e) => handleStep("parking", e.target.value)}
                type="text"
                name="parking"
                className="form-control"
                placeholder="Enter Something"
                required
              />
            </div>
          </div>
          <div className="col-lg-6 mb-2">
            <div className="mb-3">
              <label className="text-label form-label">Summary</label>
              <textarea
                ref={summaryRef}
                value={stepForm["aboutListing"]}
                onChange={function(e) {
                  handleStep("aboutListing", e.target.value);
                  summaryRef.current.style.height =
                    summaryRef.current.scrollHeight + "px";
                }}
                type="aboutListing"
                className="form-control"
                placeholder="Summary"
                required
              ></textarea>
            </div>
          </div>
          {/* <div className="col-lg-6 mb-2">
              <div className="mb-3">
                <label className="text-label form-label">Bathrooms</label>
                <input
                  value={stepForm["bathrooms"]}
                  onChange={(e) => handleStep("bathrooms", e.target.value)}
                  type="text"
                  name="bathrooms"
                  className="form-control"
                  placeholder="Enter Something"
                  required
                />
              </div>
            </div> */}
          {/* <div className="col-lg-12 mb-2">
              <div className="mb-3">
                <label className="text-label form-label">Add Photos</label>
                <input
                  type="file"
                  name="transportation"
                  className="form-control"
                  placeholder="Enter Something"
                  onChange={getFile}
                  required
                />
              </div>
            </div> */}
        </div>
      </div>
      <div>
        <button
          onClick={onPrev}
          type="button"
          className="btn btn-secondary mx-2 tw-mb-2"
        >
          Prev
        </button>

        <button
          type="submit"
          disabled={updating}
          className="btn btn-secondary tw-mb-2"
        >
          {updating ? "Updating ..." : "Save & Next"}
        </button>
      </div>
    </form>
  );
};
