import React from "react";
import Navbar from "../Navbar";
import TopBar from "../topbar";

import "react-toastify/dist/ReactToastify.css";

import constant from "../../_config/constant";
var Modal = require("react-bootstrap-modal");
export const Wrapper = ({ children }) => {
  var data = localStorage.getItem(constant.DATA_KEY);
  const decoded = data ? JSON.parse(data) : {};
  return (
    <div id="main-wrapper">
      <TopBar user={decoded} title="Earnings" />

      <div className="deznav">
        {/* <div className="deznav-scroll"> */}
        <Navbar />
        {/* </div> */}
      </div>

      <div className="content-body">
        <div className="container-fluid">{children}</div>
      </div>
    </div>
  );
};
