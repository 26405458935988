import React from "react";
import ReactModal from "./ReactModal";

function AlertBox({ message, onCancle, onProceed }) {
  return (
    <ReactModal>
      <div className="tw-bg-white tw-p-4 tw-rounded-md tw-w-max tw-mx-auto">
        <p>{message}</p>
        <div className="tw-flex tw-gap-2 tw-justify-end">
          <button onClick={onCancle} className="btn btn-sm btn-primary">
            Cancel
          </button>
          <button onClick={onProceed} className="btn btn-sm btn-secondary">
            Delete
          </button>
        </div>
      </div>
    </ReactModal>
  );
}

export default AlertBox;
