import constant from '../_config/constant'
import {
    authHeader,
    // logout,
    basicAuthHeader,
} from '../_helpers'
import { history } from '../_helpers/history'
import { handleResponse } from '../_helpers/utils'

export const userService = {
    login,
    getDetail,
    UpdateProfile,
    changePassword,
    sendMailVerificationLink,
    updateIdVerificationStatus,
    sendVerifyPhoneOtp,
    sendVerifyEmailOtp,
    verifyPhone,
    verifyEmail,
    becomeHost,
    updateSocialAuthenticationStatus,
    updatePersonalEmailAndOtp,
    verifyPersonalEmailOTP,
    sendAirlineEmailVerificationLink,
    switchUserType,
}

async function login(emailId, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...basicAuthHeader() },
        body: JSON.stringify({
            emailId,
            password,
            type: 'Host',
            loginType: 'Normal',
        }),
    }
    return await fetch(`${constant.apiUrl}/login`, requestOptions).then(handleResponse)
}

function getDetail() {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    }
    return fetch(`${constant.apiUrl}/profile`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user
        })
}

function UpdateProfile(data) {
    var fd = new FormData()
    fd.append('name', data.name)
    fd.append('personalEmail', data.personalEmail)
    fd.append('phone', data.phone)
    fd.append('countryCode', data.countryCode)
    fd.append('airlineEmail', data.airlineEmail)
    fd.append('dob', data.dob)
    fd.append('file', data.fileData)

    if (data.payoutVerification !== undefined) {
        fd.append('payoutVerification', data.payoutVerification ? data.payoutVerification : 0)
    }

    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader() },
        body: fd,
    }
    return fetch(`${constant.apiUrl}/profile`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user
        })
}

function changePassword(data) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
    }
    return fetch(`${constant.apiUrl}/changePassword`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user
        })
}

function sendMailVerificationLink(data) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
    }
    return fetch(`${constant.apiUrl}/sendMailVerificationLink`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user
        })
}

function sendAirlineEmailVerificationLink(data) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
    }
    return fetch(`${constant.apiUrl}/send-airline-email-verification-link`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user
        })
}

function updateIdVerificationStatus() {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    }
    return fetch(`${constant.apiUrl}/updateIdVerificationStatus`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user
        })
}

function sendVerifyPhoneOtp(data) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
    }
    return fetch(`${constant.apiUrl}/sendVerifyPhoneNumber`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user
        })
}

function sendVerifyEmailOtp() {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    }
    return fetch(`${constant.apiUrl}/sendVerifyEmailOtp`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user
        })
}

function updatePersonalEmailAndOtp(data) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
    }
    return fetch(`${constant.apiUrl}/update-personal-email`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user
        })
}

function verifyPhone(data) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
    }
    return fetch(`${constant.apiUrl}/verifyPhoneNumber`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user
        })
}

function verifyEmail(data) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
    }
    return fetch(`${constant.apiUrl}/verifyEmail`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user
        })
}

function verifyPersonalEmailOTP(data) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
    }
    return fetch(`${constant.apiUrl}/verifyPersonalEmailOTP`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user
        })
}

function becomeHost(data) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
    }
    return fetch(`${constant.apiUrl}/becomeHost`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user
        })
}

function updateSocialAuthenticationStatus(data) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
    }
    return fetch(`${constant.apiUrl}/updateSocialAuthentication`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response
        })
}

async function switchUserType(data) {
    const requestOptions = {
        method: 'POST',
        headers: { ...basicAuthHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
    }
    return fetch(`${constant.apiUrl}/becomeBoth`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response
        })
}

// function handleResponse(response) {
//   return response.text().then((text) => {
//     const data = text && JSON.parse(text);
//     if (data.statusCode == 401 || data.statusCode == 403) {
//       logout();
//       window.location.reload();
//     }
//     if (data.statusCode != 200) {
//       const error = (data && data.userMessage) || response.statusText;
//       return Promise.reject(error);
//     }
//     return data;
//   });
// }
