// import React from "react";

// // import { useTranslation } from "react-i18next";

// const Home = () => {
//   // const { t, i18n } = useTranslation();
//   return (
//     <div className="Home">
//       <h2>Welcome to Home us page.</h2>
//       {/* <h3> {t("Home.welcome")}</h3> */}
//     </div>
//   );
// };

// export default Home;

import React, { useEffect } from "react";
import constant from '../../_config/constant';
import { getToken } from "../../_helpers";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";


const Home = () => {
  console.log("before redirect");
  const history=useHistory()
  useEffect(() => {

    getToken() ? history.push('/properties'):history.replace('/login')
    // window.location.replace(`${constant.USER_WEB_URL}`);
  }, [])
  
  return (
    <div className="authincation h-100">
      {/* <div className="container h-100">
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-7">
            <div className="form-input-content text-center error-page">
              <h1 className="error-text fw-bold"></h1>
              <h4>
                <i className="fa fa-exclamation-triangle text-warning"></i> Something went wrong
              </h4>
              <p>
                You may have mistyped the address or the page may have moved.
              </p>
              <div>
                <a className="btn btn-primary" href={constant.USER_WEB_URL}>
                  Back to home
                </a>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Home;

