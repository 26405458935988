import React from "react";
import { Link } from "react-router-dom";
import constant from "../../../_config/constant";
import { processMediaUrl } from "../../../_helpers/utils";

function PropertyDetails({ details, host }) {
  return (
    <div className="py-4 tw-grid lg:tw-grid-cols-3 tw-gap-4">
      <img
        className="tw-rounded-md tw-w-full tw-object-cover lg:tw-h-auto tw-h-56"
        src={details.images ? processMediaUrl(details.images[0]) : ""}
        alt="property image"
      />
      <div className="lg:tw-col-span-2 tw-grid tw-gap-2 tw-grid-cols-2 tw-text-sm ">
        <div>
          <label className="tw-text-gray-800 tw-mb-1 tw-font-medium">
            Listing name
          </label>
          <p className="tw-text-700">{details.listingName}</p>
        </div>
        {/* <div>
          <label className="tw-text-gray-800 tw-mb-1 tw-font-medium">
            Host
          </label>
          <p className="tw-text-700">{host && host.name}</p>
        </div> */}
        <div>
          <label className="tw-text-gray-800 tw-mb-1 tw-font-medium">
            Rental Type
          </label>
          <p className="tw-text-700">{details.rentalType}</p>
        </div>
        {/* <div>
          <label className="tw-text-gray-800 tw-mb-1 tw-font-medium">
            Booking Type
          </label>
          <p className="tw-text-700">{details.bookingType}</p>
        </div> */}
        <div>
          <label className="tw-text-gray-800 tw-mb-1 tw-font-medium">
            Address
          </label>
          <p className="tw-text-700">{details.address}</p>
        </div>
        <div className="tw-col-span-full ">
          <a
            target="_blank"
            href={`${constant.USER_WEB_URL}/property-details/${details._id}`}
            className="tw-underline tw-text-primary"
          >
            View more
          </a>
        </div>
      </div>
    </div>
  );
}

export default PropertyDetails;
