import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { saveToken, saveData } from "../_helpers";

const Home = () => {
  const history = useHistory();

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    
    if (params.token && params.data) {
      saveToken(params.token);
      saveData(JSON.parse(params.data));
      history.push(`/properties`);
      // setTimeout(() => {
      // }, 2000);
    } else {
      history.push(`/`);
    }
  }, [history]);

  return (
    <div className="App">
      <div className="h-100 text-center">
        <img src="images/loading.gif" alt="" style={{ height: "200px" }} />
        <p>Please wait ...</p>
      </div>
    </div>
  );
}

export default Home;
