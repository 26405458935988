import React, { useEffect } from "react";
import useTextZoomDisable from "../hooks/useTextZoomDisable";
import loadjs from "loadjs";

function Plugin() {
  useTextZoomDisable();
  useEffect(() => {
    loadjs(
      [
        "/vendor/global/global.min.js",
        "/vendor/jquery-nice-select/js/jquery.nice-select.min.js",
        "/js/custom.min.js",
        "/js/deznav-init.js",
      ],
      {
        async: false,
      }
    );
  }, []);
  return <div></div>;
}

export default Plugin;
