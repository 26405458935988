import React, { useState } from "react";

import { AiFillDelete } from "react-icons/ai";
import "react-toastify/dist/ReactToastify.css";

var Modal = require("react-bootstrap-modal");

export function HouseRules({
  onNext,
  onPrev,
  formData,
  setFormData,
  className,
  save,
  updating,
}) {
  const [houseRules, setHouseRules] = useState(formData["houseRules"] || []);
  const [rule, setRule] = useState("");

  const handleRules = (curr) => {
    if (curr) {
      setHouseRules([...houseRules, curr]);
      setRule("");
    }
  };

  const deleteRule = (id) => {
    const ruleArr = [...houseRules];
    ruleArr.splice(id, 1);
    setHouseRules([...ruleArr]);
  };

  const handleNextStep = () => {
    // setFormData((el) => {
    //   return { ...el, houseRules: houseRules };
    // });
    if (houseRules.length) {
      save({ houseRules });
      onNext();
    }
  };

  return (
    <div className={className}>
      <div className=" tw-min-h-[60vh] ">
        <div className="row gx-5">
          <h4 className="mb-5 h-min tw-flex tw-justify-start tw-items-center">
            <span className="tw-bg-[#23426e] tw-mr-2 tw-flex tw-justify-center tw-items-center  tw-h-10 tw-w-10 tw-text-white  tw-rounded-full">
              3
            </span>{" "}
            House Rules
          </h4>
          <div className="col-lg-6">
            {Boolean(houseRules.length) ? (
              <div className="mb-2">
                {houseRules.map((curr, index) => {
                  return (
                    <div
                      key={index}
                      className="tw-flex tw-items-start tw-justify-between"
                    >
                      <p className="tw-text-gray-700 tw-text-lg">
                        {index + 1}. {curr}
                      </p>
                      <AiFillDelete
                        size={24}
                        onClick={() => deleteRule(index)}
                        className="tw-cursor-pointer"
                      />
                    </div>
                  );
                })}
              </div>
            ) : (
              <p className="tw-mb-4 tw-text-center tw-text-gray-600 tw-text-xl ">
                No rules added
              </p>
            )}
            <div className="mb-2 tw-flex tw-items-center">
              <input
                onChange={(e) => {
                  setRule(e.target.value);
                }}
                value={rule}
                type="text"
                name="houseRules"
                className="form-control"
                placeholder="House Rules"
                required
              />

              <button
                onClick={() => handleRules(rule)}
                type="button"
                className="btn btn-sm btn-primary m-2 "
              >
                Add
              </button>
            </div>
          </div>
          <div className="col-lg-6 ">
            <h4>House Rules (Examples)</h4>
            <p className="tw-text-gray-800 tw-text-base tw-mb-2">
              1. Be courteous and clean up after yourself
            </p>
            <p className="tw-text-gray-800 tw-text-base tw-mb-2">
              2. Quiet hours are from 10pm-8am
            </p>
            <p className="tw-text-gray-800 tw-text-base tw-mb-2">
              3. Provide your own sheets, towels, and toiletries
            </p>
            <p className="tw-text-gray-800 tw-text-base tw-mb-2">
              4. Overnight guests are not allowed{" "}
            </p>
          </div>
        </div>
      </div>
      <div>
        <button
          onClick={onPrev}
          type="button"
          className="btn btn-secondary mx-2  tw-mb-2"
        >
          Prev
        </button>
        <button
          onClick={handleNextStep}
          type="submit"
          className="btn btn-secondary mx-2 tw-mb-2"
          disabled={updating}
        >
          {updating ? "Updating ..." : "Save & Next"}
        </button>
      </div>
    </div>
  );
}
