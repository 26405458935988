import React from 'react'

export default function ContainerTwo() {
    return (
        <div className="container2 tw-flex tw-flex-col tw-w-full tw-gap-4">
            <div
                className="cs-card"
                style={{
                    padding: '24px',
                }}
            >
                <div className="tw-mb-4">
                    <p className="tw-text-primary tw-font-bold">Hot Bed Only</p>
                    <div className="tw-flex tw-flex-row tw-gap-2 tw-ml-2">
                        <span
                            className="tw-bg-primary bullet"
                            style={{
                                borderRadius: '9999px',
                                marginTop: '6px',
                            }}
                        ></span>
                        <p className="tw-text-primary">
                            When hot bed dates are blocked, those selected dates for hot bed will be blocked.
                        </p>
                    </div>
                </div>
                <div className="tw-mb-4">
                    <p className="tw-text-primary tw-font-bold">Cold Bed Only</p>
                    <div className="tw-flex tw-flex-row tw-gap-2 tw-ml-2">
                        <span
                            className="tw-bg-primary bullet"
                            style={{
                                borderRadius: '9999px',
                                marginTop: '6px',
                            }}
                        ></span>
                        <p className="tw-text-primary">
                            When a cold bed date is blocked on the calendar, all prior dates for cold bed will be blocked.
                        </p>
                    </div>
                </div>
                <div className="">
                    <p className="tw-text-primary tw-font-bold">Flexible Booking</p>
                    <div className="tw-flex tw-flex-row tw-gap-2 tw-ml-2 tw-mb-4">
                        <span
                            className="tw-bg-primary bullet"
                            style={{
                                borderRadius: '9999px',
                                marginTop: '6px',
                            }}
                        ></span>
                        <p className="tw-text-primary">
                            When a cold bed date is blocked on the calendar, all prior dates for cold bed will be blocked.
                        </p>
                    </div>
                    <div className="tw-flex tw-flex-row tw-gap-2 tw-ml-2 tw-mb-4">
                        <span
                            className="tw-bg-primary bullet"
                            style={{
                                borderRadius: '9999px',
                                marginTop: '6px',
                            }}
                        ></span>
                        <p className="tw-text-primary">
                            When hot bed dates are blocked, those selected dates for hot bed will be blocked.
                        </p>
                    </div>
                    <div className="tw-ml-4">
                        <div className="tw-flex tw-flex-row tw-gap-2 tw-ml-2 tw-mb-4">
                            <span
                                className="tw-bg-primary bullet-2"
                                style={{
                                    borderRadius: '9999px',
                                    marginTop: '6px',
                                }}
                            ></span>
                            <p className="tw-text-primary">
                                When hot bed dates are blocked, all earlier dates up to the furthest checkout date will be
                                automatically blocked for cold bed.
                            </p>
                        </div>
                        <div className="tw-flex tw-flex-row tw-gap-2 tw-ml-2">
                            <span
                                className="tw-bg-primary bullet-2"
                                style={{
                                    borderRadius: '9999px',
                                    marginTop: '6px',
                                }}
                            ></span>
                            <p className="tw-text-primary">
                                Example: hot bed dates blocked: 8/20/24-8/28/24 and 9/3/24-9/7/24. Everything prior to 9/7/24 will
                                be blocked for cold bed.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="cs-card"
                style={{
                    padding: '38px 24px 38px 24px',
                }}
            >
                <div className="tw-flex tw-flex-row tw-gap-2">
                    <span>
                        <i className="fa fa-angle-right"></i>
                    </span>
                    <p className="tw-text-primary tw-mb-4">
                        Pro Tip: Onboard all current tenants to pay through the portal for best results and automated bed
                        availability.
                    </p>
                </div>
                <p className="tw-text-primary">
                    Properties with manually blocked dates may be ranked lower in search results and receive fewer booking
                    requests.
                </p>
            </div>
        </div>
    )
}
