import React, { useState } from "react";

import {
  Map,
  GoogleApiWrapper,
  Marker,
  InfoWindow,
  Circle,
} from "google-maps-react";

import PlacesAutocomplete from "react-places-autocomplete";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { getAddressByLatLng } from "../../../_helpers/GeoCoder";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import constant from "../../../_config/constant";
var Modal = require("react-bootstrap-modal");
const EditShouldBeAllowedForStatuses = ['Rejected'];
export const BasicDetailsForm = GoogleApiWrapper({
  apiKey: constant.googleAPI_KEY,
})(
  ({
    onNext,
    onPrev,
    formData,
    setFormData,
    className,
    google,
    save,
    updating,
  }) => {
    const [stepForm, setStepForm] = useState({
      rentalType: formData["rentalType"],
      accomodationCapacity: formData["accomodationCapacity"],
      propertyType: formData["propertyType"],
      bookingType: formData["bookingType"],
      reservedDays: formData["reservedDays"],
      lineholderDays: formData["lineholderDays"],
      covidVerified: formData["covidVerified"],
      address: formData["address"],
      apartmentNumber: formData["apartmentNumber"],
      location: formData["location"],
    });

    const [coords, setCoords] = useState(formData["location"]);
    const [mapCenter, setMapCenter] = useState(formData["location"]);
    const [latLng, setLatLng] = useState(formData["location"]);

    const handleStep = (tag, value) => {
      setStepForm({ ...stepForm, [tag]: value });
    };

    const days = [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
      "24",
      "25",
      "26",
      "27",
      "28",
      "29",
      "30",
    ];

    const handleNextStep = (e) => {
      e.preventDefault();
      //:handlenext
      console.log("sleeping next");

      // setFormData((el) => {
      //   return { ...stepForm };
      // });
      if (!stepForm.address) {
        toast.info("Address is required.");
        return;
      }
      if (!stepForm.location) {
        toast.info("Unable to found location of selected address.");
        return;
      }
      save(stepForm);
      onNext();
    };

    const handleSelect = (address) => {
      geocodeByAddress(address)
        .then((results) => getLatLng(results[0]))
        .then((latLng) => {
          setLatLng(latLng);
          setCoords({ ...latLng });
          setMapCenter(new google.maps.LatLng({ ...latLng }));
          setStepForm({ ...stepForm, location: latLng, address: address });
        })
        .catch((error) => console.error("Error", error));
    };

    const selectLocation = async (location) => {
      setLatLng({ lat: location.lat, lng: location.lng });
      setStepForm({ ...stepForm, location: location });
      var newAddress = await getAddressByLatLng(location);
      setStepForm({ ...stepForm, address: newAddress });
    };

    return (
      <form onSubmit={handleNextStep} className={className}>
        <div className={`tw-min-h-[60vh]`}>
          <div className={`row`}>
            <h4 className="mb-5 tw-flex tw-justify-start tw-items-center">
              <span className="tw-bg-[#23426e] tw-mr-2 tw-flex tw-justify-center tw-items-center  tw-h-10 tw-w-10 tw-text-white  tw-rounded-full">
                1
              </span>{" "}
              Basics
            </h4>
            <div className="col-lg-6 mb-2 ">
              <div className="mb-3">
                <label className="form-label">Rental Type</label>
                <select
                  value={stepForm["rentalType"]}
                  onChange={(e) => {
                    handleStep("rentalType", e.target.value);
                  }}
                  className="form-control  wide"
                  name="rentalType"
                  required
                  disabled={!EditShouldBeAllowedForStatuses.includes(formData.status)}
                >
                  <option value="">Select</option>
                  <option value="Crashpad">Crashpad</option>
                  <option value="Entire Place">Entire Place</option>
                </select>
                {stepForm["rentalType"] === "Crashpad" && (
                  <p className="tw-text-sm tw-text-gray-400 tw-my-2">
                    ⓘ Guest reserves a bed in a private or shared room with
                    common space
                  </p>
                )}
                {stepForm["rentalType"] === "Entire Place" && (
                  <p className="tw-text-sm tw-text-gray-400 tw-my-2">
                    ⓘ Guest reserves an entire property or their exclusive hotel
                    room
                  </p>
                )}
              </div>
            </div>
            <div className="col-lg-6 mb-2">
              <div className="mb-3">
                <label className="form-label">Accommodates</label>
                <input
                  onChange={(e) =>
                    handleStep(
                      "accomodationCapacity",
                      Math.abs(e.target.value) || ""
                    )
                  }
                  min={0}
                  value={stepForm["accomodationCapacity"]}
                  type="number"
                  className="form-control"
                  name="accomodationCapacity"
                  placeholder="Capacity"
                  required
                />
                {stepForm["rentalType"] === "Crashpad" && (
                  <p className="tw-text-sm tw-text-gray-400 tw-my-2">
                    ⓘ Total number of guests at the crashpad when full including
                    yourself and anyone else that sleeps there
                  </p>
                )}
                {stepForm["rentalType"] === "Entire Place" && (
                  <p className="tw-text-sm tw-text-gray-400 tw-my-2">
                    ⓘ Total number of guests allowed at the property
                  </p>
                )}
              </div>
            </div>
            <div className="col-lg-6 mb-2">
              <div className="mb-3">
                <label className="form-label">Property Type</label>
                <select
                  name="propertyType"
                  onChange={(e) => {
                    handleStep("propertyType", e.target.value);
                  }}
                  value={stepForm["propertyType"]}
                  className=" form-control"
                  required
                  // disabled
                  disabled={!EditShouldBeAllowedForStatuses.includes(formData.status)}
                >
                  <option value="">Select</option>
                  <option value="house">House</option>
                  <option value="hotel">Hotel</option>
                  <option value="apartment">Apartment</option>
                </select>
              </div>
            </div>
            <div className="col-lg-6 mb-2">
              <div className="mb-3">
                <label className="form-label">Booking Type</label>
                <select
                  name="bookingType"
                  onChange={(e) => handleStep("bookingType", e.target.value)}
                  value={stepForm["bookingType"]}
                  className="  form-control wide"
                  required
                  // disabled
                  disabled={!EditShouldBeAllowedForStatuses.includes(formData.status)}
                >
                  <option value="">Select</option>

                  <option value="Hot bed">Hot Bed</option>
                  <option value="Cold bed">Cold Bed</option>
                  <option value="Flexible">Flexible</option>
                </select>
                {stepForm["bookingType"] === "Hot bed" && (
                  <p className="tw-text-sm tw-text-gray-400 tw-my-2">
                    ⓘ Listing offers nightly rates
                  </p>
                )}
                {stepForm["bookingType"] === "Cold bed" && (
                  <p className="tw-text-sm tw-text-gray-400 tw-my-2">
                    ⓘ Listing offers monthly rates
                  </p>
                )}
                {stepForm["bookingType"] === "Flexible" && (
                  <p className="tw-text-sm tw-text-gray-400 tw-my-2">
                    ⓘ Listing offers nightly and monthly rates
                  </p>
                )}
              </div>
            </div>
            {/* {(stepForm.bookingType === "Cold bed" ||
            stepForm.bookingType === "Flexible") && (
              <div className="col-lg-6 mb-2">
                <div className="mb-3">
                  <label className="form-label">Stay Limit</label>
                  <select
                    name="stayLimit"
                    onChange={(e) => handleStep("stayLimit", e.target.value)}
                    value={stepForm["stayLimit"]}
                    className="  form-control wide"
                    required
                  >
                    <option value="">Select</option>
                    <option value="Reserved">
                      {" "}
                      Reserve Stay Limit
                    </option>
                    <option value="Lineholder">
                      Lineholder Stay Limit
                    </option>
                  </select>
                </div>
              </div>
            )} */}
            {(stepForm.bookingType === "Cold bed" ||
              stepForm.bookingType === "Flexible") && (
                <div className="col-lg-6 mb-2">
                  <div className="mb-3">
                    <label className="form-label">Reserve Stay Limit</label>
                    <select
                      name="reservedDays"
                      onChange={(e) => handleStep("reservedDays", e.target.value)}
                      value={stepForm["reservedDays"]}
                      className="  form-control wide"
                      required
                    >
                      <option value="">Select</option>
                      {days.map((day) => (
                        <option value={day}>{day}</option>
                      ))}
                    </select>
                    <p className="tw-text-sm tw-text-gray-400 tw-my-2">
                      ⓘ Total number of nights a guest can stay in a month
                    </p>
                  </div>
                </div>
              )}
            {(stepForm.bookingType === "Cold bed" ||
              stepForm.bookingType === "Flexible") && (
                <div className="col-lg-6 mb-2">
                  <div className="mb-3">
                    <label className="form-label">Lineholder Stay Limit</label>
                    <select
                      name="lineholderDays"
                      onChange={(e) =>
                        handleStep("lineholderDays", e.target.value)
                      }
                      value={stepForm["lineholderDays"]}
                      className="  form-control wide"
                      required
                    >
                      <option value="">Select</option>
                      {days.map((day) => (
                        <option value={day}>{day}</option>
                      ))}
                    </select>
                    <p className="tw-text-sm tw-text-gray-400 tw-my-2">
                      ⓘ Total number of nights a guest can stay in a month
                    </p>
                  </div>
                </div>
              )}
            <div className="col-lg-6 mb-2">
              <div className="mb-3">
                <label className="form-label">
                  Is Covid vaccination required to book at property?
                </label>
                <select
                  name="covidVerified"
                  onChange={(e) => handleStep("covidVerified", e.target.value)}
                  value={stepForm["covidVerified"]}
                  className="  form-control wide"
                  required
                >
                  <option value="">Select</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </div>
            </div>
            <div className="col-lg-6 mb-3">
              <div className="mb-3">
                <label className="text-label form-label">Address</label>

                {
                  !(formData.status === 'Listed' || formData.status === 'Unlisted') ? <>
                    <PlacesAutocomplete
                      searchOptions={{ componentRestrictions: { country: "us" } }}
                      value={stepForm["address"]}
                      onChange={(e) => handleStep("address", e)}
                      // onSelect={handleSelect}
                      className="form-control" 
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div className="tw-relative">
                          <input
                            {...getInputProps({
                              placeholder: "Search Places ...",
                              className: "form-control",
                            })}
                          />
                          <div className="autocomplete-dropdown-container tw-absolute tw-left-0 tw-right-0 tw-top-14 tw-z-[4000]">
                            {loading && <div className="tw-p-4 ">Loading...</div>}
                            {suggestions.map((suggestion) => {
                              const className = suggestion.active
                                ? "suggestion-item--active"
                                : "suggestion-item";
                              // inline style for demonstration purpose
                              const style = suggestion.active
                                ? { backgroundColor: "#fafafa", cursor: "pointer" }
                                : { backgroundColor: "#ffffff", cursor: "pointer" };
                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                >
                                  <span
                                    onClick={() =>
                                      handleSelect(suggestion.description)
                                    }
                                    className="tw-p-4 tw-block"
                                  >
                                    {suggestion.description}
                                  </span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </> :
                    <input className="form-control tw-cursor-not-allowed tw-bg-gray-100" disabled={true} value={stepForm["address"]} />
                }
              </div>
            </div>
            <div className="col-lg-6 mb-2">
              <div className="mb-3">
                <label className="form-label">
                  Apt, suite, etc. (Optional)
                </label>
                <input
                  onChange={(e) =>
                    handleStep("apartmentNumber", e.target.value)
                  }
                  value={stepForm["apartmentNumber"]}
                  type="text"
                  className="form-control"
                  name="apartmentNumber"
                  placeholder="e.g. Apt #8"
                />
              </div>
            </div>
            <div className="col-lg-6 mb-4 tw-h-64  tw-w-full ">
              <div className="tw-h-64 tw-h-64 tw-relative tw-rounded-md tw-overflow-hidden ">
                <Map
                  google={google}
                  disableDefaultUI
                  zoom={14}
                  style={{ height: "100%", width: "100%" }}
                  // onClick={handleMapClick}
                  center={mapCenter}
                  initialCenter={latLng}
                >
                  <Marker title={stepForm.address} position={coords}>
                    <InfoWindow visible={true}>
                      <div className="p-4 bg-white text-sm">
                        <p>
                          Click on the map or drag the marker to select location
                          where the incident occurred
                        </p>
                      </div>
                    </InfoWindow>
                  </Marker>
                  <Circle
                    radius={100}
                    center={coords}
                    strokeColor="transparent"
                    strokeOpacity={0}
                    strokeWeight={5}
                    fillColor="#1362fc"
                    fillOpacity={0.5}
                  />
                </Map>
              </div>
            </div>
          </div>
        </div>
        <div className="tw-mb-4">
          <button
            onClick={onPrev}
            className="btn btn-secondary mx-2  tw-mb-2"
            disabled
          >
            Prev
          </button>

          <button
            disabled={updating}
            type="submit"
            className="btn btn-secondary tw-mb-2"
          >
            {updating ? "Updating ..." : "Save & Next"}
          </button>
        </div>
      </form>
    );
  }
);
