import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "./../../components/Navbar";
import TopBar from "./../../components/topbar";
import { disputeService } from "../../_services";
import moment from "moment";
import "react-confirm-alert/src/react-confirm-alert.css";
import Footer from "./../../layout/footer";
import constant from "../../_config/constant";
import Pagination from "react-js-pagination";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Countdown from "react-countdown";
// import { addHours } from "date-fns";

export default function DisputeList(props) {
  const [requesting, setRequesting] = useState(true);

  const [list, setList] = useState([]);
  const [refreshKey, setRefreshKey] = useState(0);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  var limit = 10;

  var data = localStorage.getItem(constant.DATA_KEY);
  const decoded = data ? JSON.parse(data) : {};

  const pageChange = (page) => {
    setPage(page);
    setRequesting(true);
    setRefreshKey((oldKey) => oldKey + 1);
  };

  useEffect(() => {
    setRequesting(true);
    disputeService.list({ page, limit: 10 }).then(
      (objS) => {
        console.clear();
        setList(objS.data.docs);

        setTotal(objS.data.total);
        setRequesting(false);
        window.scrollTo({ top: 0, behavior: "smooth" });
      },
      (error) => {
        setRequesting(false);
      }
    );
  }, [refreshKey]);

  return (
    <div id="main-wrapper">
      <ToastContainer />
      <TopBar
        user={decoded}
        showSearch={true}
        title="Dispute List"
        search={search}
      />

      <div className="deznav">
        <div className="deznav-scroll">
          <Navbar />
        </div>
      </div>

      <div className="content-body">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12">
              {requesting && (
                <div className="tw-my-10 tw-flex tw-items-center tw-justify-center">
                  <div className="bg-white tw-w-max rounded shadow-md p-4">
                    <div className="loader"></div>
                  </div>
                </div>
              )}
              <div className="tab-content">
                <div className="tab-pane active show" id="All">
                  <div className="table-responsive">
                    <table
                      className="table card-table  display mb-4 dataTablesCard booking-table room-list-tbl table-responsive-lg "
                      id="guestTable-all"
                    >
                      <thead>
                        <tr>
                          <th>Action</th>
                          <th>Status</th>
                          <th>Dispute ID</th>
                          <th>Reservation ID</th>
                          <th>User From</th>
                          <th>Listing Name</th>
                          <th>Dispute Amount</th>
                          {/* <th>Room Name</th>
                          <th>Bed #</th>
                          <th>Reservation Dates</th> */}

                          <th> Created At</th>
                          <th>Updated At</th>

                          {/* Button: Message Guest and Host individually through
                          chat View Dispute Conversation View Dispute Reason
                          View Reservation Information View Documents/Photos
                          uploaded */}
                        </tr>
                      </thead>
                      <tbody>
                        {list.map((curr) => (
                          <tr>
                            <td>
                              <div>
                                <Link to={`/dispute/details/${curr._id}`}>
                                  <li className="tw-cursor-pointer  tw-text-center tw-rounded-md tw-p-2 tw-px-4 p-2 tw-text-xs tw-bg-primary tw-text-white">
                                    View
                                  </li>
                                </Link>
                              </div>
                            </td>
                            <td>{curr.status}</td>
                            <td>{curr._id}</td>
                            <td>{curr.bookingId ?curr.bookingId.bookingId?curr.bookingId.bookingId:curr.bookingId._id:''}</td>
                            <td>{curr.userId.name}</td>
                            <td>{curr.bookingId?curr.bookingId.propertyId.listingName :''}</td>
                            <td>
                              {curr.amount.toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              })}
                            </td>
                            <td>
                              {curr.createdAt
                                ? moment(curr.createdAt).format("MMMM Do, YYYY")
                                : "--"}
                            </td>
                            <td>
                              {curr.updatedAt
                                ? moment(curr.updatedAt).format("MMMM Do, YYYY")
                                : "--"}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Pagination
            activePage={page}
            itemsCountPerPage={limit}
            totalItemsCount={total}
            pageRangeDisplayed={10}
            onChange={pageChange}
          />
        </div>
      </div>

      <Footer />
    </div>
  );
}
