import axios from "axios";
import constant from "../_config/constant";
import { authHeader, logout, basicAuthHeader } from "../_helpers";

const api = axios.create({ baseURL: constant.apiUrl });

export const getChatToken = async (id) => {
  try {
    const response = await api.get(`/chat/token?identity=${id}`, {
      headers: authHeader(),
    });

    // const response = await api.post(
    //   `http://localhost:3001/chat/token`,
    //   {
    //     identity: id,
    //   },
    //   { headers: authHeader() }
    // );

    return response;
  } catch (error) {
    if (error.response.status === 403) {
      logout();
      window.location.reload();
    }
  }
};

export const updateUserResource = async (id, data) => {
  try {
    const response = await api.post(
      `/chat/update`,
      {
        identity: id,
        user: data,
      },
      { headers: authHeader() }
    );
    return response;
  } catch (error) {}
};
