import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { userService } from '../../_services'
import { saveToken } from '../../_helpers'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const CREWMEMBER = 'Crewmember'
const HOST = 'Host'

function SwitchUserAccount({
    userType = HOST,
    user = {
        firstName: '',
        lastName: '',
        personalEmail: '',
        dob: null,
        crewType: 'Host',
        countryCode: null,
        id: '',
        phone: '',
    },
}) {
    const history = useHistory()
    const [isRequesting, setRequesting] = useState(false)

    async function handleSwitchAccount(e) {
        try {
            e.preventDefault()
            let res = null
            setRequesting(true)

            res = await userService.switchUserType({
                userId: user.id,
                crewType: '',
                type: HOST,
            })
            if (res.data.status) {
                toast.success(res.data.message, { toastId: 6 })
            } else {
                toast.error(res.data.message, { toastId: 7 })
            }
            saveToken(res.data.token)
            history.push('/')
        } catch (error) {
            toast.error('Something went wrong')
        } finally {
            setRequesting(false)
        }
    }

    return (
        <>
            <p className="text-center my-4" style={{ color: 'black' }}>
                {user.message}
            </p>
            <button
                className="btn btn-primary btn-block btn-sm"
                style={{ width: '100px', borderRadius: '4px' }}
                type="button"
                onClick={handleSwitchAccount}
                disabled={isRequesting}
            >
                {isRequesting ? 'Please wait ...' : 'Proceed'}
            </button>
        </>
    )
}

export default SwitchUserAccount
