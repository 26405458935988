import React, { useState } from "react";
import Navbar from "./Navbar";
import TopBar from "./topbar";
import Footer from "./../layout/footer";
import { report } from "./../_services/index";
import { toast, ToastContainer } from "react-toastify";
import { nanoid } from "nanoid";
import { AiOutlinePlus } from "react-icons/ai";

function Report() {
  const [message, setMessage] = useState("");
  const [photos, setPhotos] = useState([]);
  const [bookingId, setBookingId] = useState("");

  const [requesting, setRequesting] = useState(false);

  const getImage = (file) => {
    const url = URL.createObjectURL(file.current);
    return url;
  };

  const handleDelete = (id) => {
    const arr = photos.filter((curr) => curr.id !== id);
    setPhotos([...arr]);
  };

  const handleReportSubmission = async () => {
    try {
      setRequesting(true);
      if (!message) {
        return toast.error("Please provide some details about the report.");
      }
      const data = await report({
        message,
        bookingId,
        photos,
      });
    } catch (error) {
      toast.error("Something went wrong!");
    } finally {
      setRequesting(false);
    }
  };

  return (
    <div id="main-wrapper">
      <TopBar title="Report" />
      <div className="deznav">
        <div className="deznav-scroll">
          <Navbar />
        </div>
      </div>
      <ToastContainer />

      <div className="content-body">
        <div className="container-fluid">
          <div className="row">
            <div className="tw-p-4">
              <div className="lg:tw-w-8/12 tw-mx-auto tw-my-8 ">
                <label className="name tw-block  tw-mb-2 tw-font-medium   ">
                  Add Photos
                </label>
                <div className="tw-w-full">
                  <div className=" tw-mb-2">
                    <div className="tw-grid tw-grid-cols-3 md:tw-grid-cols-5 tw-gap-2 tw-items-start tw-flex-wrap tw-mb-4">
                      {photos.map((curr, i) => (
                        <div
                          key={i}
                          id={curr.id}
                          className="tw-w-full  tw-aspect-square tw-flex-shrink-0 tw-grow-0 tw-relative   tw-object-cover tw-rounded-md tw-overflow-hidden"
                        >
                          <span
                            onClick={() => handleDelete(curr.id)}
                            className="tw-absolute tw-z-4 tw-left-4 tw-top-4 tw-h-8 tw-w-8 tw-flex tw-items-center tw-justify-center tw-cursor-pointer tw-rounded-full tw-bg-gray-500 tw-bg-opacity-50"
                          >
                            <AiOutlinePlus
                              size={22}
                              className="tw-transform tw-rotate-45 tw-text-white"
                            />
                          </span>
                          <img
                            className="tw-w-full tw-h-full tw-object-cover "
                            src={curr.src}
                          />
                        </div>
                      ))}{" "}
                    </div>
                    <div className="tw-col-lg-6 tw-mb-4">
                      <div className="tw-mb-3">
                        <div className="tw-flex tw-items-center">
                          <label
                            htmlFor="add_photos"
                            className="btn btn-primary btn-sm"
                          >
                            Choose
                          </label>
                          <p className="tw-text-sm tw-ml-4">
                            {!photos.length
                              ? "No file choosen"
                              : `${photos.length} File choosen`}
                          </p>
                        </div>
                        <input
                          max={5}
                          id="add_photos"
                          type="file"
                          multiple
                          className="form-control tw-hidden"
                          accept="image/*"
                          onChange={(e) => {
                            const curr = Array.from(e.target.files).map(
                              (file, i) => {
                                return {
                                  current: file,
                                  id: nanoid(),
                                  src: getImage({ current: file }),
                                  order: photos.length + i + 1,
                                };
                              }
                            );

                            const arr = [...photos, ...curr];
                            if (arr.length > 8) {
                              return toast.error(
                                "Maximum number of files allowed is 8."
                              );
                            }
                            setPhotos(arr);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="tw-mb-2">
                    <span className="tw-block tw-mb-2 tw-font-medium  ">
                      Booking Id
                    </span>
                    <input
                      type="text"
                      className="tw-w-full  form-control"
                      value={bookingId}
                      onChange={(e) => setBookingId(e.target.value)}
                    />
                  </div>

                  <span className="tw-block tw-mb-2 tw-font-medium  ">
                    Write Message
                  </span>
                  <textarea
                    type="text"
                    id="name"
                    rows={8}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    className="tw-w-full tw-min-h-[80px] form-control tw-block tw-mb-2 tw-p-2 "
                  ></textarea>
                </div>
                <button
                  onClick={handleReportSubmission}
                  className="btn btn-primary btn-sm"
                >
                  {requesting ? "Please wait..." : "Submit Report"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Report;
