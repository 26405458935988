import React from 'react'

import SendImage from '../../images/send.png'
import SmilyImage from '../../images/smily.svg'

function MessageInput({ text, setText, sendMessage, setShowPicker }) {
    return (
        <div
            style={{
                display: 'flex',
                borderTop: '1px solid #E4ECF8',
                paddingTop: '1rem',
                paddingLeft: '1rem',
            }}
        >
            <div
                style={{
                    backgroundColor: '#E4ECF8',
                    padding: '0.75rem',
                    paddingLeft: '1rem',
                    paddingRight: '1rem',
                    display: 'flex',
                    alignItems: 'center',
                    flexGrow: 1,
                    borderRadius: '0.375rem',
                }}
            >
                <form
                    style={{ width: '100%' }}
                    onSubmit={e => {
                        e.preventDefault()
                        sendMessage(text)
                    }}
                >
                    <input
                        placeholder="Enter Message..."
                        value={text}
                        onChange={e => setText(e.target.value)}
                        style={{
                            width: '100%',
                            outline: 'none',
                            backgroundColor: 'transparent',
                            border: 'none',
                        }}
                    />
                </form>
            </div>

            <div style={{ display: 'flex' }}>
                <button
                    style={{
                        marginLeft: '1.25rem',
                        border: 'none',
                        backgroundColor: 'transparent',
                    }}
                    onClick={() => setShowPicker(val => !val)}
                >
                    <img src={SmilyImage} alt="Smily" style={{ width: '1rem', height: '1rem' }} />
                </button>
                <button
                    onClick={() => sendMessage(text)}
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginLeft: '1.25rem',
                        backgroundColor: '#23426e',
                        paddingLeft: '1rem',
                        paddingRight: '1rem',
                        paddingTop: '0.75rem',
                        paddingBottom: '0.75rem',
                        borderRadius: '0.375rem',
                        flexShrink: 0,
                        border: 'none',
                    }}
                >
                    <img src={SendImage} alt="Sent icon" style={{ width: '1rem', height: '1rem' }} />
                </button>
            </div>
        </div>
    )
}

export default MessageInput
