import React, { Component } from "react";

class Return extends Component {
  constructor() {
    super();
  }

  render() {
 
    return (
      <div className="App">
        <div className=" h-100">
          <div className="container h-100">
            <div className="row justify-content-center h-100 align-items-center">
              <div className="col-md-6">
                <div className="authincation-content">
                  <div className="row no-gutters">
                    <div className="col-xl-12">
                      <div className="auth-form">
                        <div className="text-center mb-3">
                          <a href="index.html">
                            <img src="images/logo-full.png" alt="" />
                          </a>
                        </div>
                        <h4 className="text-center mb-4">
                         Return Page
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Return;
