const moment = require('moment');

export function generateDateRangeUTC(_startDate, _endDate) {
    let startDate=FixedDate(_startDate);
    let endDate=FixedDate(_endDate)
    const dateRange=[]
    while(startDate<=endDate){
        const year = startDate.getFullYear();
        const month = String(startDate.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
        const day = String(startDate.getDate()).padStart(2, '0');
        const d= `${year}-${month}-${day}`; 
        dateRange.push(d)
        const nextDate=moment(startDate).add(1,'d').toDate()
        startDate=nextDate
    }
    return dateRange;
}


export function FixedDate(DATE_OR_YYYY_MM_DD) {
    if (typeof DATE_OR_YYYY_MM_DD === 'string') {
        
        const [year, month, day] = DATE_OR_YYYY_MM_DD.split('-');
        const d= new Date(Number(year), Number(month) - 1, Number(day));
        // console.log(d,'minimumColdbedBlockedDate')
        return d
    } else if (DATE_OR_YYYY_MM_DD instanceof Date) {
        
        return new Date(
            DATE_OR_YYYY_MM_DD.getFullYear(),
            DATE_OR_YYYY_MM_DD.getMonth(),
            DATE_OR_YYYY_MM_DD.getDate(),
        );
    } else if (typeof DATE_OR_YYYY_MM_DD === 'number') {
        return new Date(DATE_OR_YYYY_MM_DD);
    } else {
        throw new Error('Invalid input: Expected a string, Date object, or number (timestamp)');
    }
}
