import React, { useEffect, useState } from "react";
import { Wrapper } from "./Wrapper";
import { ToastContainer } from "react-toastify";
import { payoutService } from "../../_services/payout.service";
import TextInput from "./TextInput";

export default function PersonalDetail({
  businessStructure,
  onChange,
  person,
  error,
  verificationErrorFieldsList,
  formDataKey
}) {
  function parseDOB(dob) {
    let dateString = "";
    if (dob.month && dob.day && dob.year) {
      const month = String(dob.month).padStart(2, "0");
      const day = String(dob.day).padStart(2, "0");
      dateString = `${dob.year}-${month}-${day}`;
    }
    // console.log(dateString);
    return dateString;
  }
  const thirteenYearsAgo = new Date();
  thirteenYearsAgo.setFullYear(thirteenYearsAgo.getFullYear() - 13);

  // Format the date as YYYY-MM-DD
  const maxDate = thirteenYearsAgo.toISOString().split("T")[0];

  return (
    <>
      <TextInput
        type="text"
        value={person.first_name}
        onChange={onChange}
        label="First name*"
        name={"first_name"}
        highlightError={[`${formDataKey}.first_name`].some(value => verificationErrorFieldsList.includes(value))}
        // highlightError={verificationErrorFieldsList.includes('individual.first_name')}
        error={error && error.first_name}
      />
      <TextInput
        type="text"
        value={person.last_name}
        onChange={onChange}
        label="Last name*"
        name={"last_name"}
        highlightError={[`${formDataKey}.last_name`].some(value => verificationErrorFieldsList.includes(value))}

        error={error && error.last_name}
      />
      <TextInput
        type="date"
        value={parseDOB(person.dob)}
        onChange={onChange}
        label="Date of birth*"
        name="dob"
        highlightError={[`${formDataKey}.dob`, 'dob'].some(value => verificationErrorFieldsList.includes(value))}
        error={error && error.dob}
        max={maxDate}
      />

      <TextInput
        type="text"
        value={person.email}
        onChange={onChange}
        label="Email*"
        name={"email"}
        highlightError={[`${formDataKey}.email`].some(value => verificationErrorFieldsList.includes(value))}
        error={error && error.email}
      />

      <TextInput
        type="text"
        value={person.phone}
        onChange={onChange}
        label="Phone*"
        name={"phone"}
        placeHolder={"0123456789"}
        highlightError={[`${formDataKey}.phone`].some(value => verificationErrorFieldsList.includes(value))}
        toolTipText={"Enter phone number without dashes"}
        error={error && error.phone}
      />

      <TextInput
        type="text"
        value={person.id_number}
        onChange={onChange}
        label="SSN*"
        name={"id_number"}
        placeHolder={"000000000"}
        highlightError={[`${formDataKey}.id_number`,'id_number','document'].some(value => verificationErrorFieldsList.includes(value))}
        toolTipText={"The government-issued ID number (SSN) of the individual without dashes"}
        error={error && error.id_number}
      />

      <TextInput
        type="text"
        value={person.ssn_last_4}
        onChange={onChange}
        label="Tax information*"
        name={"ssn_last_4"}
        placeHolder={"0000"}
        highlightError={[`${formDataKey}.ssn_last_4`].some(value => verificationErrorFieldsList.includes(value))}
        toolTipText={
          "Must be the last 4 digits of a 9 digit U.S. Social Security number (SSN)"
        }
        error={error && error.ssn_last_4}
      />
    </>
  );
}
