import moment from 'moment'
import { FixedDate, generateDateRangeUTC } from './DateUTCHelpers'

export function isValidEmail(email) {
    // Regular expression for a simple email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

    return emailRegex.test(email)
}

export function isValidUSPhoneNumber(phoneNumber) {
    // Regular expression for a simple US phone number validation
    const phoneRegex = /^\d{10}$/

    // Remove non-numeric characters from the phone number
    const numericPhoneNumber = phoneNumber.replace(/\D/g, '')

    return phoneRegex.test(numericPhoneNumber)
}
export function validateWebsiteAddress(website) {
    const urlPattern = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w.-]*)*\/?$/
    return urlPattern.test(website)
}

export function validateUSPostalCode(zip) {
    // Regular expression to match a valid US postal code
    const zipRegex = /^\d{5}(?:-\d{4})?$/

    return zipRegex.test(zip)
}

export function validateSSNLast4(ssnLast4) {
    // Use a regular expression to match exactly 4 digits
    const ssnLast4Pattern = /^\d{4}$/

    // Test if the provided input matches the pattern
    return ssnLast4Pattern.test(ssnLast4)
}

export function isAgeValid(day, month, year) {
    // Create a Date object with the current date
    const currentDate = new Date()

    // Extract the current year, month, and day
    const currentYear = currentDate.getFullYear()
    const currentMonth = currentDate.getMonth() + 1 // Note: Month is zero-based, so we add 1
    const currentDay = currentDate.getDate()

    // Calculate the age based on the current date
    const age = currentYear - year - (currentMonth > month || (currentMonth === month && currentDay >= day) ? 0 : 1)

    // Check if the age is between 13 and 120 (inclusive)
    return age >= 13 && age <= 120
}

export const getSystemTimezoneOffset = () => {
    const offset = new Date().getTimezoneOffset()
    const absOffset = Math.abs(offset)
    const hours = String(Math.floor(absOffset / 60)).padStart(2, '0')
    const minutes = String(absOffset % 60).padStart(2, '0')
    const sign = offset > 0 ? '-' : '+'

    return `${sign}${hours}:${minutes}`
}

export const checkInitialDate = dd => {
    if (moment().isAfter(moment(dd))) {
        return moment(new Date()).toDate()
    } else return dd
}

export const isDisableBlockedDate = data => {
    if (data && data.hotbedManualBlockedDates && data.hotbedManualBlockedDates.length > 0) {
        return data.hotbedManualBlockedDates.map(dateStr => moment(dateStr).format('YYYY-MM-DD'))
    }
    return []
}

export const isDisabledBookedDates = data => {
    if (data && data.hotbedBookedDates && data.hotbedBookedDates.length > 0) {
        return data.hotbedBookedDates.map(dateStr => moment(dateStr).format('YYYY-MM-DD'))
    }
    return []
}

export const isDisabledAutoBlockedDates = data => {
    if (data && data.autoBlocked && data.autoBlocked.length > 0) {
        return data.autoBlocked.map(dateStr => moment(dateStr).format('YYYY-MM-DD'))
    }
    return []
}

export const getHotbedMaxDate = room_or_bed => {
    const today = new Date()
    if (!room_or_bed) {
        return new Date(today.getFullYear(), today.getMonth() + 2, 0)
    }
    const coldbedBookedDates = room_or_bed.coldbedBookedDates || []

    const minimunBlockedDate = findOldestDate(coldbedBookedDates)
    if (!minimunBlockedDate) {
        return FixedDate(new Date(today.getFullYear(), today.getMonth() + 2, 0))
    }
    return FixedDate(minimunBlockedDate)
}

function findOldestDate(dateArray) {
    const dates = dateArray
    if (dates.length === 0) return null
    const dateObjects = dates.map(date => FixedDate(date))
    const oldestDate = FixedDate(Math.min(...dateObjects))
    return moment(oldestDate).format('YYYY-MM-DD')
}

function findLatestDate(dateArray) {
    if (dateArray.length === 0) return null

    const dateObjects = dateArray.map(date => FixedDate(date))

    const latestTimestamp = Math.max(...dateObjects.map(date => date.getTime()))

    const latestDate = FixedDate(latestTimestamp)
    const finalDate = moment(latestDate).format('YYYY-MM-DD')

    return finalDate
}

export const getMinimumDateToDisableCalenderForColdBed = room_or_bed => {
    if (!room_or_bed) {
        return FixedDate(new Date())
    }
    const hotbedBookedDates = room_or_bed.hotbedBookedDates || []
    const coldbedBookedDates = room_or_bed.coldbedBookedDates || []
    // const coldBedManualBlockedDate = room_or_bed.coldbedManualBlockedDate
    const hotbedManualBlocked = room_or_bed.hotbedManualBlockedDates || []
    const allDates = [...hotbedBookedDates, ...coldbedBookedDates, ...hotbedManualBlocked]

    const latestDate = findLatestDate(allDates)
    if (!latestDate) {
        return FixedDate(new Date())
    }

    const d = FixedDate(latestDate)
    if (!d) {
        return FixedDate(new Date())
    }
    const date = moment(d)
        .utc()
        .add(1, 'd')
        .toDate()

    return date
}

export const getManualBlockedDatesForColdBed = (room_or_bed, inYYYY_MM_DD = false) => {
    if (!room_or_bed) {
        return []
    }

    const coldBedManualBlockedDate = room_or_bed.coldbedManualBlockedDate
    if (!coldBedManualBlockedDate) {
        return []
    }
    const minimumColdbedBlockedDate = getMinimumDateToDisableCalenderForColdBed(room_or_bed)
    let range = []
    const maxDate = FixedDate(coldBedManualBlockedDate)
    if (minimumColdbedBlockedDate < maxDate) {
        const maxPlusOne = moment(maxDate)
            .utc()
            .add(1, 'd')
            .toDate()
        range = generateDateRangeUTC(minimumColdbedBlockedDate, maxDate)
    }
    const d = range.map(item => FixedDate(item))
    if (d) {
        if (inYYYY_MM_DD) {
            return range
        } else {
            return d
        }
    }
    return []
}

export const getBookedDatesForColdBed = room_or_bed => {
    if (!room_or_bed) {
        return new Date()
    }
    const coldbedBookedDates = room_or_bed.coldbedBookedDates || []
    const d = coldbedBookedDates.map(item => FixedDate(item))
    return d
}

export const splitIntoRanges = dates => {
    const sortedDates = dates.map(date => moment(date)).sort((a, b) => a - b)

    const ranges = []
    let currentRange = [sortedDates[0]]

    for (let i = 1; i < sortedDates.length; i++) {
        const previousDate = sortedDates[i - 1]
        const currentDate = sortedDates[i]

        if (currentDate.diff(previousDate, 'days') === 1) {
            currentRange.push(currentDate)
        } else {
            ranges.push(currentRange.map(date => date.format('YYYY-MM-DD')))
            currentRange = [currentDate]
        }
    }

    if (currentRange.length > 0) {
        ranges.push(currentRange.map(date => date.format('YYYY-MM-DD')))
    }

    return ranges
}

export const generateDateRange = (blockFromDate, blockToDate) => {
    const dates = []
    const today = new Date()
    today.setHours(0, 0, 0, 0)

    let startDate = new Date(blockFromDate)
    let endDate = new Date(blockToDate)

    if (startDate <= today) {
        startDate = new Date(today)
    }

    if (endDate <= today) {
        endDate = new Date(today)
    }

    while (startDate <= endDate) {
        dates.push(new Date(startDate).toISOString().split('T')[0])
        startDate.setDate(startDate.getDate() + 1)
    }

    return dates
}

export const isDateInRangeAutoBlocked = (start, end, autoBlockedDates) => {
    let currentDate = moment(start)
    while (currentDate <= moment(end)) {
        if (autoBlockedDates.includes(currentDate.format('YYYY-MM-DD'))) {
            return true
        }
        currentDate = currentDate.add(1, 'days')
    }
    return false
}

export const isDateInRangeBlocked = (start, end, blockedDates) => {
    let currentDate = moment(start)
    while (currentDate <= moment(end)) {
        if (blockedDates.includes(currentDate.format('YYYY-MM-DD'))) {
            return true
        }
        currentDate = currentDate.add(1, 'days')
    }
    return false
}

export const getManualBlockedDatesForHotbed = room_or_bed => {
    if (!room_or_bed) {
        return []
    }
    const manualBlocked = room_or_bed.hotbedManualBlockedDates || []
    return manualBlocked
}

export const getBookedDatesForHotbed = room_or_bed => {
    if (!room_or_bed) {
        return []
    }
    const manualBlocked = room_or_bed.hotbedBookedDates || []

    return manualBlocked
}

export const getColdbedInitialDate = room_or_bed => {
    const manualBlocked = room_or_bed.coldbedManualBlockedDate

    if (manualBlocked) {
        return FixedDate(
            moment(manualBlocked)
                .add(1, 'd')
                .toDate()
        )
    }

    return FixedDate(
        moment()
            .add(1, 'd')
            .toDate()
    )
}

export const getHotbedInitialVisitDate = room_or_bed => {
    if (!room_or_bed) {
        return [
            {
                startDate: checkInitialDate(moment(new Date()).toDate()),
                endDate: checkInitialDate(moment(moment(new Date()).add(1, 'day')).toDate()),
                key: 'selection',
            },
        ]
    }

    const manualBlocked = room_or_bed.hotbedManualBlockedDates || []

    let latestDate = findLatestDate(manualBlocked)
    if (!latestDate) {
        latestDate = moment(FixedDate(new Date())).format('YYYY-MM-DD')
    }

    const bookedDates = getBookedDatesForHotbed(room_or_bed)
    if (!bookedDates.includes(latestDate)) {
        const latestDatePlusOne = moment(FixedDate(latestDate))
            .add(1, 'd')
            .toDate()
        return [
            {
                startDate: checkInitialDate(latestDatePlusOne),
                endDate: checkInitialDate(
                    moment(latestDatePlusOne)
                        .add(1, 'd')
                        .toDate()
                ),
                key: 'selection',
            },
        ]
    } else {
        const latestDatePlusOne = moment(FixedDate(latestDate))
            .add(1, 'd')
            .toDate()
         latestDate = findLatestDate(bookedDates)
        if (!bookedDates) {
            return [
                {
                    startDate: checkInitialDate(latestDatePlusOne),
                    endDate: checkInitialDate(
                        moment(latestDatePlusOne)
                            .add(1, 'd')
                            .toDate()
                    ),
                    key: 'selection',
                },
            ]
        } else {
            const lastBookedDate = FixedDate(latestDate)
            const lastBookedDatePlusOne = moment(lastBookedDate)
                .add(1, 'd')
                .toDate()

            return [
                {
                    startDate: checkInitialDate(lastBookedDatePlusOne),
                    endDate: checkInitialDate(
                        moment(lastBookedDatePlusOne)
                            .add(1, 'd')
                            .toDate()
                    ),
                    key: 'selection',
                },
            ]
        }
    }
}
