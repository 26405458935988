import React from "react";
import { Route, Switch, NavLink } from "react-router-dom";
import Navbar from "./../../components/Navbar";
import TopBar from "./../../components/topbar";
import Footer from "./../../layout/footer";

import PayoutMethod from "./PayoutMethod";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Setting() {
  const decoded = localStorage.usertoken;

  return (
    <div id="main-wrapper">
      <TopBar user={decoded} title="Payout Method" />

      <div className="deznav">
        <div className="deznav-scroll">
          <Navbar />
        </div>
      </div>

      <ToastContainer />

      <div className="content-body">
        <div className="container-fluid">
          <div className="row">
            <div>
              <div>
                {/* <div className=" tw-grid tw-grid-cols-2 lg:tw-grid-cols-4 tw-gap-5">
                  <NavLink
                    exact
                    className="tw-p-4 tw-px-6 tw-rounded-md btn-change lg:tw-col-span-2 new-change-column  tw-ring-1 tw-ring-[#23426e] tw-text-center  tw-text-base"
                    activeClassName="tw-bg-[#23426e] tw-text-white "
                    to="/setting"
                  >
                    Change Password
                  </NavLink>
                  <NavLink
                    exact
                    className=" tw-p-4 tw-px-6 tw-rounded-md btn-change lg:tw-col-span-2 new-change-column  tw-ring-1 tw-ring-[#23426e] tw-text-center  tw-text-base"
                    activeClassName="tw-bg-[#23426e] tw-text-white "
                    to="/setting/payout-method"
                  >
                    Payout Method
                  </NavLink>
                </div> */}

                <div className="">
                  <Switch>
                    {/* <Route exact path="/setting" component={ChangePassword} /> */}
                    <Route
                      exact
                      path="/setting/payout-method"
                      component={PayoutMethod}
                    />
                  </Switch>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Setting;
