import React, { useEffect, useState } from "react";
import { Wrapper } from "./Wrapper";
import { ToastContainer } from "react-toastify";
import { payoutService } from "../../_services/payout.service";
import { Tooltip } from "react-tippy";
import { FaIcicles, FaInfoCircle } from "react-icons/fa";
import "react-tippy/dist/tippy.css";

export default function TextInput({
  label,
  name,
  value,
  onChange,
  error,
  toolTipText,
  highlightError,
  ...props
}) {
  return (
    <div className="tw-flex tw-flex-col mt-4">
      <div style={{ display: "flex", gap: 4 }}>
        <label className="tw-text-sm">{label}</label>
        {toolTipText && (
          <Tooltip theme="light" position="right-end" title={toolTipText}>
            <FaInfoCircle size={22} />
          </Tooltip>
        )}
      </div>
      <input
        type="text"
        value={value}
        name={name}
        onChange={onChange}
        className={`tw-p-3 tw-rounded tw-ring-1 ${highlightError?'tw-ring-red-600':'tw-ring-gray-400' } tw-border-0 invalid`}
        // className={`tw-p-3 tw-rounded tw-ring-1 ${highlightError?'tw-ring-red-600':'tw-ring-gray-400' }tw-border-0 invalid`}
        {...props}
      />
      {error && <div className="text text-danger mt-2">{error}</div>}
    </div>
  );
}
