import axios from "axios";
import constant from "../_config/constant";
import { authHeader } from "../_helpers";

const api = axios.create({ baseURL: constant.apiUrl });

const getTransactions = async ({ bookingId }) => {
  const response = await api.get(`/transaction/booking/${bookingId}`, {
    headers: authHeader(),
  });
  return response;
};

const getTransactionDetails = async ({ transactionId }) => {
  const response = await api.get(`/transaction/${transactionId}/detail`, {
    headers: authHeader(),
  });
  return response;
};

const getPayouts = async ({ bookingId }) => {
  const response = await api.get(`/transaction/payout/${bookingId}`, {
    headers: authHeader(),
  });
  return response;
};

const getPayoutDetails = async ({ payoutId }) => {
  const response = await api.get(`/transaction/payout/${payoutId}/detail`, {
    headers: authHeader(),
  });
  return response;
};

const getWalletDetail = async (page) => {
  const response = await api.get(`/wallet?page=${page}&pageSize=10`, {
    headers: authHeader(),
  });
  return response;
};

const getCustomDashboardDetails = async (page, search) => {
  const response = await api.get(
    `/custom/hostCustomDashboardDetails?page=${page}&search=${search}`,
    {
      headers: authHeader(),
    }
  );
  return response;
};

const requestPayout = async () => {
  const response = await api.post(`/transferVirtualAmountToHostAccount`, null, {
    headers: authHeader(),
  });
  return response;
};

const updateStripeAccount = async (data, representative, owners) => {
  const response = await api.post(
    `/update_account_connect`,
    {
      data: data,
      representative,
      owners,
    },
    {
      headers: authHeader(),
    }
  );
  return response;
};

const getStripeConnectDraftData = async (type) => {
  const response = await api.get(
    `/transaction/custom/stripe_connect_draft?type=${type}`,
    {
      headers: authHeader(),
    }
  );
  return response;
};

const saveStripeConnectDraftData = async (type, formdata) => {
  const response = await api.post(
    `/transaction/custom/save_stripe_connect_draft`,
    {
      type,
      formdata,
    },
    {
      headers: authHeader(),
    }
  );
  return response;
};

const getGraphData = async (data) => {
  return await api.post("/custom/host-graph-data", data, {
    headers: authHeader(),
  });
};

export const payoutService = {
  updateStripeAccount,
  getTransactions,
  getTransactionDetails,
  getPayouts,
  getPayoutDetails,
  getWalletDetail,
  requestPayout,
  getStripeConnectDraftData,
  saveStripeConnectDraftData,
  getCustomDashboardDetails,
  getGraphData,
};
