import React, { Component } from "react";
import Navbar from "./../Navbar";
import TopBar from "./../topbar";
import Footer from "./../../layout/footer";
import { NavLink, Switch, Route } from "react-router-dom";

import UpdateProfile from "./UpdateProfile";
import VerifyDetails from "./VerifyDetails";
import BecomeCrewmate from "./Crewmate";

import { getData } from "../../_helpers";
import { ToastContainer, toast } from "react-toastify";
import ChangePassword from './ChangePassword'
import "react-toastify/dist/ReactToastify.css";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <div id="main-wrapper">
        <TopBar title="My Profile" />
        <div className="deznav">
          <div className="deznav-scroll">
            <Navbar />
          </div>
        </div>
        {/* <ToastContainer /> */}
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {/* Same as */}
        <ToastContainer />

        <div className="content-body">
          <div className="container-fluid">
            <div className="row">
              <div className="my-10 flex justify-between items-center">
                <div className="col-lg-6 tw-grid tw-grid-cols-3 md:tw-grid-cols-3 tw-ring-1 tw-ring-[#23426e] tw-rounded-md tw-overflow-hidden">
                  <NavLink
                    exact
                    to="/profile"
                    className="tw-text-sm tw-p-4 tw-text-center"
                    activeClassName="tw-bg-[#23426e] tw-text-white "
                  >
                    Update Profile
                  </NavLink>
                  
                  <NavLink
                    exact
                    to="/profile/b-crewmate"
                    className="tw-text-sm tw-p-4 tw-text-center"
                    activeClassName="tw-bg-[#23426e] tw-text-white "
                  >
                    Guest Registration
                  </NavLink>
                  <NavLink
                    exact
                    to="/profile/change-password"
                    className="tw-text-sm tw-p-4 tw-text-center"
                    activeClassName="tw-bg-[#23426e] tw-text-white "
                  >
                    Change Password
                  </NavLink>
                </div>
              </div>
              <Switch>
                <Route exact component={UpdateProfile} path="/profile" />
                {/* <Route exact component={VerifyDetails} path="/profile/verify" /> */}
                <Route
                  exact
                  component={BecomeCrewmate}
                  path="/profile/b-crewmate"
                />
                <Route
                  exact
                  component={ChangePassword}
                  path="/profile/change-password"
                />
              </Switch>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Profile;
