import constant from "../_config/constant";
import {
  authHeader,
  // logout,
  basicAuthHeader,
} from "../_helpers";
import { handleResponse } from "../_helpers/utils";
export const disputeService = {
  add,
  list,
  details,
  reply,
  acceptAmount,
  involveAdmin,
};

function add(data) {
  var fd = new FormData();
  fd.append("message", data.reason);
  fd.append("bookingId", data.id);
  fd.append("amount", data.amount);
  var newFile = [];
  var newFileOrder = [];
  var oldFIle = [];

  if (data.photos && data.photos.length) {
    data.photos.forEach((file) => {
      if (file.type && file.type == "url") {
        oldFIle.push(file);
      } else {
        newFileOrder.push(file.order);
        newFile.push(file);
      }
    });
  }
  newFile.length &&
    newFile.forEach((file) => {
      fd.append("files", file.current);
    });
  fd.append("oldFIle", JSON.stringify(oldFIle));
  fd.append("newFileOrder", JSON.stringify(newFileOrder));

  const requestOptions = {
    method: "POST",
    headers: { ...authHeader() },
    body: fd,
  };

  return fetch(`${constant.apiUrl}/dispute/add`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      return user;
    });
}

function list(data) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(
    `${constant.apiUrl}/dispute?page=${data.page}&limit=${data.limit}`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function details(id) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(`${constant.apiUrl}/dispute/${id}/details`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function reply(data) {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(data),
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(`${constant.apiUrl}/dispute/reply`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
function involveAdmin(id) {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify({
      disputeId: id,
    }),
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(`${constant.apiUrl}/dispute/enableAdmin`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function acceptAmount(data) {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(data),
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(`${constant.apiUrl}/dispute/acceptAmount`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

// function handleResponse(response) {
//   return response.text().then((text) => {
//     const data = text && JSON.parse(text);

//     if (data.statusCode == 401 || data.statusCode == 403) {
//       logout();
//       window.location.reload();
//     }
//     if (data.statusCode != 200) {
//       const error = (data && data.userMessage) || response.statusText;
//       return Promise.reject(error);
//     }
//     return data;
//   });
// }
