import React, { useState } from "react";

import "react-toastify/dist/ReactToastify.css";

var Modal = require("react-bootstrap-modal");

export const Terms = ({
  onNext,
  onPrev,
  formData,
  setFormData,
  className,
  save,
  updating,
}) => {
  const [stepForm, setStepForm] = useState({
    cancelationPolicy: formData.cancelationPolicy,
  });
  const handleStep = (tag, value) => {
    setStepForm({ ...stepForm, [tag]: value });
  };
  const handleNextStep = (e) => {
    e.preventDefault();
    // setFormData((el) => {
    //   return { ...el, ...stepForm };
    // });
    save(stepForm);
    onNext();
  };

  return (
    <form onSubmit={handleNextStep}>
      <div className={className}>
        <div className=" my-3 tw-min-h-[60vh]">
          <h4 className="mb-5 tw-flex tw-justify-start tw-items-center">
            <span className="tw-bg-[#23426e] tw-mr-2 tw-flex tw-justify-center tw-items-center  tw-h-10 tw-w-10 tw-text-white  tw-rounded-full">
              9
            </span>{" "}
            Terms
          </h4>
          <div className="row col-lg-6 mb-2">
            <div className="mb-3">
              <label className="form-label">Select Cancellation Policy</label>
              <select
                onChange={(e) =>
                  handleStep("cancelationPolicy", e.target.value)
                }
                value={stepForm["cancelationPolicy"]}
                className="form-control wide"
                required
              >
                <option value="">Select</option>
                <option value="Relaxed">Relaxed</option>
                <option value="Moderate">Moderate</option>
                <option value="Strict">Strict</option>
              </select>
              {stepForm["cancelationPolicy"] === "Relaxed" && (
                <p className="tw-text-sm tw-text-gray-400 tw-my-2">
                  ⓘ Guests can receive a full refund up until 24 hours before
                  check-in
                </p>
              )}
              {stepForm["cancelationPolicy"] === "Moderate" && (
                <p className="tw-text-sm tw-text-gray-400 tw-my-2">
                  ⓘ Guests can receive a full refund up until 5 days before
                  check-in
                </p>
              )}
              {stepForm["cancelationPolicy"] === "Strict" && (
                <p className="tw-text-sm tw-text-gray-400 tw-my-2">
                  ⓘ No refunds
                </p>
              )}
            </div>
          </div>
        </div>
        <div>
          <button
            onClick={onPrev}
            type="button"
            className="btn btn-secondary mx-2  tw-mb-2"
          >
            Prev
          </button>

          <button
            type="submit"
            disabled={updating}
            className="btn btn-secondary tw-mb-2"
          >
            {updating ? "Updating ..." : "Save & Next"}
          </button>
        </div>
      </div>
    </form>
  );
};
