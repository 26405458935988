import React, { useEffect, useState, useRef } from "react";
import constant from "../../_config/constant";
import airlineEmails from "../../_config/airlineEmail.json";
import { userService } from "../../_services";
import { toast } from "react-toastify";
import { logout, getData, saveData } from "../../_helpers";
import PhoneInput from "react-phone-input-2";
import { AiOutlineMail, AiOutlinePlus } from "react-icons/ai";
import "react-phone-input-2/lib/style.css";
import { FcGoogle } from "react-icons/fc";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { BsFacebook } from "react-icons/bs";
import GoogleLogin from "react-google-login";
import CustomModal from "./../ReactModal";
import OtpInput from "react-otp-input";
import { PhoneVerificationModal } from "./PhoneVerificationModal";
import { EmailVerificationModal } from "./EmailVerificationModal";
import { MdPermContactCalendar } from "react-icons/md";
import Persona from "persona";


 export function PersonaVerifyModal(props) {
    return (
      <CustomModal>
        <div className=" tw-w-[95%] lg:tw-w-[85%] tw-mb-4 md:tw-mb-0 tw-mx-auto ">
          <span
            onClick={props.togglePersonaModal}
            className="tw-rounded-full tw-cursor-pointer tw-flex tw-justify-center tw-items-center tw-bg-white tw-p-2  tw-ml-auto tw-text-gray-700 tw-h-12 tw-w-12"
          >
            <AiOutlinePlus size={22} className="tw-transform tw-rotate-45" />
          </span>
        </div>
        <div className="tw-bg-white tw-w-[95%] lg:tw-w-[75%] tw-mx-auto tw-p-2 md:tw-p-10 tw-rounded-3xl tw-border-2 ">
          <Persona.Inquiry
            referenceId={props.id}
            templateId={constant.persona_template_id}
            environment={constant.persona_mode}
            onLoad={() => {}} // onEvent={(e, meta) => {
            // }}
            onComplete={({ inquiryId, status, fields, ...e }) => {
              // Inquiry completed. Optionally tell your server about it.
              if (status === "completed") {
                props.updateIdVerificationStatus();
                props.setPersonaModal(false);
              }
            }}
          />
        </div>
      </CustomModal>
    );
  }