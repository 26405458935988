import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import { IoMdClose } from "react-icons/io";
import { Tooltip } from "react-tippy";
import { propertyService, payoutService, report } from "../../../_services";
import Countdown from "react-countdown";
// import { addHours, toDate } from "date-fns";
// import moment from "moment";
import * as moment from "moment-timezone";
import CustomModal from "./../../../components/ReactModal";
import { nanoid } from "nanoid";
import { AiOutlinePlus } from "react-icons/ai";

const COLD = "Cold bed";

function NoticePeriodModal({
  checkIn,
  onProceed,
  onCancle,
  requesting,
  bookingDetails,
}) {
  const [date, setDate] = useState(
    new Date(moment(new Date()).add(30, "days")).toISOString().split("T")[0]
  );

  const getDateAfterThirtyDays = () => {
    let finalDate = new Date(moment(new Date()).add(30, "days"))
      .toISOString()
      .split("T")[0];
    const paymentHistoryStatuses = ["Paid"];
    if (
      paymentHistoryStatuses.includes(
        bookingDetails.latestBookingHistory.status
      )
    ) {
      const currentMonthEndDate = moment()
        .endOf("month")
        .format("YYYY-MM-DD");
      if (
        moment(currentMonthEndDate).isSame(
          moment(moment().format("YYYY-MM-DD"))
        )
      ) {
        const NextMonthEndDate = moment(
          bookingDetails.latestBookingHistory.checkInDate
        )
          .endOf("month")
          .add(1, "days")
          .format("YYYY-MM-DD");
        finalDate = NextMonthEndDate;
      }
    } else if (bookingDetails.latestBookingHistory.status === "Hold") {
      const timeDifference =
        new Date(
          moment(bookingDetails.latestBookingHistory.checkInDate)
        ).getTime() - new Date(moment.utc()).getTime();
      const hourDifference = timeDifference / (1000 * 60 * 60);
      if (hourDifference <= Number((24.2).toFixed(2))) {
        const NextMonthEndDate = moment(
          bookingDetails.latestBookingHistory.checkInDate
        )
          .endOf("month")
          .add(1, "days")
          .format("YYYY-MM-DD");
        finalDate = NextMonthEndDate;
      }
    }
    return finalDate;
  };
  useEffect(() => {
    setDate(getDateAfterThirtyDays());
  }, []);
  return (
    <div className="tw-p-4 tw-bg-white tw-rounded-md tw-max-w-[420px] tw-mx-auto">
      {/* {`${moment(date).format("MMMM Do YYYY")}`} */}
      <p className="tw-text-sm my-4  tw-flex tw-items-center">
        Your checkout date will be on
        <input
          className="tw-text-sm tw-border-0 tw-inline-block tw-border-b tw-flex-shrink-0 tw-p-0 tw-pl-2"
          value={date}
          min={
            getDateAfterThirtyDays()
            // new Date(moment(new Date()).add(30, "days"))
            //   .toISOString()
            //   .split("T")[0]
          }
          defaultValue={new Date()}
          type="date"
          onChange={(e) => setDate(e.target.value)}
        />
      </p>
      <div className="tw-flex tw-justify-end tw-items-center tw-space-x-4 ">
        <button className="btn btn-sm btn-primary tw-mr-2" onClick={onCancle}>
          Cancel
        </button>
        <button
          className="btn btn-sm btn-secondary tw-mr-2"
          onClick={() => onProceed(date)}
        >
          {requesting ? "Please wait..." : "Done"}
        </button>
      </div>
    </div>
  );
}

const ReportModal = ({ id, toggleReportModal }) => {
  const [message, setMessage] = useState("");
  const [photos, setPhotos] = useState([]);

  const [requesting, setRequesting] = useState(false);

  const getImage = (file) => {
    const url = URL.createObjectURL(file.current);
    return url;
  };

  const handleDelete = (id) => {
    const arr = photos.filter((curr) => curr.id !== id);
    setPhotos([...arr]);
  };

  const handleReportSubmission = async () => {
    try {
      setRequesting(true);
      if (!message) {
        return toast.error("Please provide some details about the report.");
      }
      const { data } = await report({
        message,
        bookingId: id,
        photos,
      });
      if (data.status) {
        toast.success(data.message);
        setTimeout(toggleReportModal, 1000);
      } else toast.error(data.message);
    } catch (error) {
      toast.error("Something went wrong!");
    } finally {
      setRequesting(false);
    }
  };
  return (
    <div className="tw-bg-white tw-rounded-lg tw-max-h-[100vh] md:tw-max-h-[95vh] modal-content">
      <div className="tw-p-4">
        <div className="modal-header tw-sticky tw-top-0 tw-right-0 tw-left-0 tw-bg-white tw-z-10  ">
          <h5 className="modal-title">Report </h5>
          <button
            type="button"
            className="tw-bg-transparent tw-border-0"
            onClick={toggleReportModal}
          >
            <span className="tw-cursor-pointer">
              <AiOutlinePlus size={24} className="tw-transform tw-rotate-45" />
            </span>
          </button>
        </div>
        <p className="tw-text-xs tw-font-medium tw-my-4">
          <span className="tw-text-red-500 ">
            EMERGENCY ASSISTANCE IS NOT AVAILABLE THROUGH CREWMATES. In an
            emergency, dial 911 or your local emergency number immediately.
          </span>
          <br />
          Crewmates reports are for informational purposes only to track
          incidents or suspected fraudulent activity
        </p>
        <div className="  tw-my-8 ">
          <label className="name tw-block  tw-mb-2 tw-font-medium   ">
            Add Photos
          </label>
          <div className="tw-w-full">
            <div className=" tw-mb-2">
              <div className="tw-grid tw-grid-cols-3 md:tw-grid-cols-5 tw-gap-2 tw-items-start tw-flex-wrap tw-mb-4">
                {photos.map((curr, i) => (
                  <div
                    key={i}
                    id={curr.id}
                    className="tw-w-full  tw-aspect-square tw-flex-shrink-0 tw-grow-0 tw-relative   tw-object-cover tw-rounded-md tw-overflow-hidden"
                  >
                    <span
                      onClick={() => handleDelete(curr.id)}
                      className="tw-absolute tw-z-4 tw-left-4 tw-top-4 tw-h-8 tw-w-8 tw-flex tw-items-center tw-justify-center tw-cursor-pointer tw-rounded-full tw-bg-gray-500 tw-bg-opacity-50"
                    >
                      <AiOutlinePlus
                        size={22}
                        className="tw-transform tw-rotate-45 tw-text-white"
                      />
                    </span>
                    <img
                      className="tw-w-full tw-h-full tw-object-cover "
                      src={curr.src}
                    />
                  </div>
                ))}{" "}
              </div>
              <div className="tw-col-lg-6 tw-mb-4">
                <div className="tw-mb-3">
                  <div className="tw-flex tw-items-center">
                    <label
                      htmlFor="add_photos"
                      className="btn btn-primary btn-sm"
                    >
                      Choose
                    </label>
                    <p className="tw-text-sm tw-ml-4">
                      {!photos.length
                        ? "No file choosen"
                        : `${photos.length} File choosen`}
                    </p>
                  </div>
                  <input
                    max={5}
                    id="add_photos"
                    type="file"
                    multiple
                    className="form-control tw-hidden"
                    accept="image/*"
                    onChange={(e) => {
                      const curr = Array.from(e.target.files).map((file, i) => {
                        return {
                          current: file,
                          id: nanoid(),
                          src: getImage({ current: file }),
                          order: photos.length + i + 1,
                        };
                      });

                      const arr = [...photos, ...curr];
                      if (arr.length > 8) {
                        return toast.error(
                          "Maximum number of files allowed is 8."
                        );
                      }
                      setPhotos(arr);
                    }}
                  />
                </div>
              </div>
            </div>

            <span className="tw-block tw-mb-2 tw-font-medium  ">
              Write Message
            </span>
            <textarea
              type="text"
              id="name"
              rows={8}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className="tw-w-full tw-min-h-[80px] form-control tw-block tw-mb-2 tw-p-2 "
            ></textarea>
          </div>
          <button
            onClick={handleReportSubmission}
            className="btn btn-primary btn-sm"
          >
            {requesting ? "Please wait..." : "Submit Report"}
          </button>
        </div>
      </div>
    </div>
  );
};

const Receipt = ({ onClose, transactionId, data = {} }) => {
  const [fetching, setFetching] = useState(true);
  const [details, setDetails] = useState({ ...data });
  let data1 = { ...data };
  const [monthBookingPrice, setMonthBookingPrice] = useState(0);

  useEffect(() => {
    const fetchTransactionDetails = async () => {
      try {
        setFetching(true);
        const { data } = await payoutService.getTransactionDetails({
          transactionId,
        });
        const transactionDetails = data.data;
        setDetails(transactionDetails);

        if (transactionDetails.bookingId) {
          let totalDays =
            moment(transactionDetails.endDate.split("T")[0]).diff(
              moment(transactionDetails.startDate.split("T")[0]),
              "days"
            ) + 1;
          let numberOfDayInBookingMonth = moment(
            transactionDetails.bookingId.checkInDate.split("T")[0]
          ).daysInMonth();

          console.log({
            numberOfDayInBookingMonth,
            bookingPrice: transactionDetails.bookingId.bookingPrice,
          });

          let perDayPrice =
            transactionDetails.bookingId.bookingPrice /
            numberOfDayInBookingMonth;
          console.log(perDayPrice, totalDays);
          // setMonthBookingPrice(perDayPrice * totalDays);
          setMonthBookingPrice(
            transactionDetails.bookingId.totalPrice -
              transactionDetails.bookingId.cleaningFee
          );
        }
      } catch (error) {
        toast.error("Something went wrong!");
      } finally {
        setFetching(false);
      }
    };
    if (transactionId && !Object.keys(data).length) fetchTransactionDetails();
  }, [transactionId]);
  // console.log("details in host ------------->>>>>>>>>>>>>>>>>>>>>>", details);
  console.log(
    "monthBookingPrice in host ---------------->>>>>>>>>",
    monthBookingPrice
  ); // added on 17/11/2022
  // console.log("data===========>>>>>>>>>>>>>>>>>>>>>>>",data);
  var discountOfLineholder = 0;
  // console.log("details.bookingId.discount===>>>",details.bookingId.bookingPrice
  // );
  // details.bookingId.map((data,id)=>{
  //   console.log("data------>>>>>>>>>>>>>>>", data);
  // })
  // console.log("details.bookingId[discount]------------------------------>>", details.bookingId);

  if (Object.keys(details).length > 0) {
    if (Object.keys(details.bookingId).length > 0) {
      if (details.bookingId.discount) {
        discountOfLineholder = details.bookingId.discount;
      }
      // console.log("details.bookingId[discount]----eafgha-------------------------->>", details.bookingId.discount);
    }
    // else {
    //   console.log("no booking id");
    // }
  }
  // else {
  //   console.log("no details");
  // }
  console.log(
    "discountOfLineholder======================>>>",
    discountOfLineholder
  );
  // let tempDiscountOfLineholder = monthBookingPrice.toString();
  // if (tempDiscountOfLineholder.includes('.')) {
  //   let tempPrice = tempDiscountOfLineholder.slice(0, (tempDiscountOfLineholder.indexOf(".")) + 3);
  //   let tempPrice2 = parseFloat(tempPrice);
  //   discountOfLineholder = tempPrice2 + discountOfLineholder
  // }
  // else {
  //   discountOfLineholder = monthBookingPrice + discountOfLineholder;
  // }

  // let proratedBedPrice = 0 ;
  // if (discountOfLineholder > 0){
  //   discountOfLineholder = details.bookingId.discount
  // }
  // if (monthBookingPrice > 0 ){
  //   proratedBedPrice = monthBookingPrice + discountOfLineholder;
  // }

  const getReservationDates = () => {
    const datesForNormalBooking = `${moment(
      details.startDate.split("T")[0]
    ).format("MMMM Do, YYYY")}
     - ${moment(details.endDate.split("T")[0]).format("MMMM Do, YYYY")}`;
    if (details.bookingId.isMonthlyCheckout === 2) {
      if (details.startDate && details.endDate) {
        const startDate = moment(details.startDate.split("T")[0]);
        const endDate = moment(details.endDate.split("T")[0]);
        const isSameMonth = startDate.month() === endDate.month();
        return `${startDate.format("MMMM Do, YYYY")} - ${endDate.format(
          "MMMM Do, YYYY"
        )}`;
      } else {
        return datesForNormalBooking;
      }
    } else {
      return datesForNormalBooking;
    }
  };

  const shouldAutopayShow = () => {
    if (details) {
      const startDate = moment(details.startDate);
      const bookingCheckInDate = moment(details.bookingId.checkInDate);
      if (startDate.month() === bookingCheckInDate.month()) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  return (
    <>
      {fetching ? (
        <div className="tw-rounded-md tw-p-8 tw-grid tw-place-content-center tw-bg-white">
          Loading...
        </div>
      ) : (
        <div className="tw-bg-white p-4 tw-rounded-md tw-relative">
          <span
            onClick={onClose}
            className="tw-absolute tw-right-6 tw-top-6 tw-cursor-pointer"
          >
            <IoMdClose size={32} />
          </span>

          <h2>Your Receipt from Crewmates</h2>
          <p>
            Receipt ID: {details.receiptId}{" "}
            {details.transactionDate &&
              `- ${moment(details.transactionDate)
                .tz(details.bookingId.timezone)
                .format("MMMM DD, YYYY")}`}
          </p>

          <h3 className="mt-2">Reservation Details</h3>

          <p className="my-2">
            {details.bookingId && getReservationDates()}
            {/* {details.bookingId && (
              <>
                {moment(details.startDate.split("T")[0]).format(
                  "MMMM Do, YYYY"
                )}
                {` - ${moment(details.endDate.split("T")[0]).format(
                  "MMMM Do, YYYY"
                )}`}
              </>
            )} */}
            <br />
            {details.bookingId.bookingType === COLD
              ? shouldAutopayShow()
                ? "Cold bed Autopay"
                : "Cold bed"
              : "Hot bed"}{" "}
            <br />
            Room {details.bookingId && details.bookingId.roomName}, Bed{" "}
            {details.bookingId && details.bookingId.bedName} <br />
            Security Deposit :{" "}
            {`${details.bookingId &&
              details.bookingId.securityDeposite.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}`}{" "}
            <br />
            Confirmation code :{" "}
            {details.bookingId && details.bookingId.confirmationCode}
          </p>
          <br />

          {!details.createdFromRenew && (
            <>
              {details.bookingId &&
              details.bookingId.propertyId.cancelationPolicy ? (
                <h4 className="text-xl font-medium">Cancellation Policy</h4>
              ) : null}

              {details.bookingId &&
              details.bookingId.propertyId.cancelationPolicy === "Relaxed" ? (
                <p className="text-sm text-gray-400 my-2">
                  <span className="text-gray-500">Relaxed: </span> Guests can
                  receive a full refund up until 24 hours before check-in
                </p>
              ) : null}

              {details.bookingId &&
              details.bookingId.propertyId.cancelationPolicy === "Moderate" ? (
                <p className="text-sm text-gray-400 my-2">
                  <span className="text-gray-500">Moderate: </span> Guests can
                  receive a full refund up until 5 days before check-in
                </p>
              ) : null}

              {details.bookingId &&
              details.bookingId.propertyId.cancelationPolicy === "Strict" ? (
                <p className="text-sm text-gray-400 my-2">
                  <span className="text-gray-500">Strict: </span> No refunds
                </p>
              ) : null}
            </>
          )}

          {/* <p className="text-sm text-gray-400 my-2-tw-text-right">
            <span className="tw-text-right">Security Deposit: </span>
            {details.bookingId &&
              details.bookingId.securityDeposite.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
          </p> */}
          <br />
          <h4>Price Breakdown</h4>
          <div className="tw-grid tw-grid-cols-2   ">
            {details.bookingId.bookingType === COLD ? (
              <>
                {details.bookingId.isMonthlyCheckout == 2 ? (
                  <p className="tw-text-gray-500">Prorated Cold Bed</p>
                ) : (
                  <p className="tw-text-gray-500">Cold Bed</p>
                )}
                <p className="tw-text-right tw-text-gray-500">
                  {details.monthBookingPrice &&
                    `$${parseFloat(details.monthBookingPrice)
                      .toFixed(2)
                      .toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}`}
                </p>
              </>
            ) : (
              <>
                <p className="tw-text-gray-500">
                  {details.bookingId.numberOfDay}{" "}
                  {Number(details.bookingId.numberOfDay) > 1
                    ? "Nights"
                    : "Night"}
                </p>
                <p className="tw-text-right tw-text-gray-500">
                  {details.bookingId &&
                    `$${parseFloat(
                      details.bookingId.bookingPrice *
                        details.bookingId.numberOfDay
                    )
                      .toFixed(2)
                      .toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}`}
                </p>
              </>
            )}
            {details.extraGuestCharge > 0 && (
              <>
                <p className="tw-text-gray-500">Extra Guest Charge</p>
                <p className="tw-text-right tw-text-gray-500">
                  {details.bookingId &&
                    `$${parseFloat(details.extraGuestCharge)
                      .toFixed(2)
                      .toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}`}
                </p>
              </>
            )}
            <p className="tw-text-gray-500">Cleaning Fee</p>
            <p className="tw-text-right tw-text-gray-500">
              {details.bookingId &&
                `$${parseFloat(details.cleaningFee)
                  .toFixed(2)
                  .toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}`}
            </p>
            {details.bookingId &&
            details.discountOfLineholder &&
            details.discountOfLineholder > 0 ? (
              <React.Fragment>
                <p className="tw-text-gray-500">Lineholder Discount</p>
                <p className="tw-text-right tw-text-gray-500">
                  {`$${parseFloat(details.discountOfLineholder)
                    .toFixed(2)
                    .toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}`}
                </p>
              </React.Fragment>
            ) : null}
            {/* <p>Security Deposit</p>
            <p className="tw-text-right">
              {details.bookingId &&
                details.bookingId.securityDeposite.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
            </p> */}

            <p className="tw-text-gray-500">Total</p>
            <p className="tw-text-right tw-text-gray-500">
              {details.bookingId &&
                `$${parseFloat(details.totalPrice)
                  .toFixed(2)
                  .toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}`}
            </p>
          </div>
          <hr className="tw-col-span-full" />
          <div className="tw-grid tw-grid-cols-2  d-flex align-items-center justify-content-between">
            <div className="flex-col flex">
              <h4 className="text-xl font-medium ">Earnings</h4>
            </div>
            <h4 className="tw-text-right text-xl font-medium text-black">
              {details.bookingId &&
                details.bookingHistory &&
                `$${parseFloat(details.bookingHistory.hostEarning)
                  .toFixed(2)
                  .toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}`}
            </h4>
          </div>
          {/* <hr className="tw-col-span-full" />
          <div className="tw-grid tw-grid-cols-2 tw-text-gray-800   ">
            <p className="tw-font-medium font-medium">Amount Paid</p>
            <p className="tw-font-medium tw-text-right font-medium">
              {details.bookingId &&
                `$${parseFloat(details.totalPrice)
                  .toFixed(2)
                  .toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}`}
            </p>
          </div> */}
        </div>
      )}
    </>
  );
};

const RefundReceipt = ({ details, onClose }) => {
  const getReservationDates = () => {
    if (details.bookingId.isMonthlyCheckout === 2) {
      if (details.checkInDate && details.endDate) {
        const startDate = moment(details.checkInDate.split("T")[0]);
        const endDate = moment(details.endDate.split("T")[0]);
        const isSameMonth = startDate.month() === endDate.month();
        return `${startDate.format("MMMM Do, YYYY")} - ${endDate.format(
          "MMMM Do, YYYY"
        )}`;
      } else {
        const startDate = moment(details.bookingId.checkInDate.split("T")[0]);
        const endDate = moment(details.bookingId.checkOutDate.split("T")[0]);
        return `${startDate.format("MMMM Do, YYYY")} - ${endDate.format(
          "MMMM Do, YYYY"
        )}`;
      }
    } else {
      return `${moment(details.bookingId.checkInDate.split("T")[0]).format(
        "MMMM Do, YYYY"
      )}
       - ${moment(details.bookingId.checkOutDate.split("T")[0]).format(
         "MMMM Do, YYYY"
       )}`;
    }
  };

  return (
    <div className="tw-w-[95%] tw-text-sm lg:w-[65%]  tw-mx-auto">
      <div className="tw-bg-white tw-p-4 tw-rounded-md tw-relative ">
        <span
          onClick={onClose}
          className="tw-absolute tw-right-6 tw-top-6 tw-cursor-pointer"
        >
          <IoMdClose size={32} />
        </span>

        <h2 className="tw-text-3xl tw-font-medium">
          Your Receipt from Crewmates
        </h2>
        <p className="tw-text-gray-500">
          Receipt ID: {details.receiptId}{" "}
          {details.bookingId.updatedAt &&
            `- ${moment(details.updatedAt)
              .tz(details.bookingId.timezone)
              .format("MMMM DD, YYYY")}`}
        </p>
        <h3 className="tw-text-2xl tw-font-medium tw-mt-2">
          Reservation Details
        </h3>

        <p className="tw-my-4">
          {details.bookingId &&
            details.bookingId.checkInDate &&
            getReservationDates()}
          {/* {details.bookingId && details.bookingId.checkInDate && (
            <>
              {moment(details.bookingId.checkInDate.split("T")[0]).format(
                "MMMM Do, YYYY"
              )}
              {` - ${moment(
                details.bookingId.checkOutDate.split("T")[0]
              ).format("MMMM Do, YYYY")}`}
            </>
          )} */}
          <br />
          Room {details.bookingId && details.bookingId.roomName}, Bed{" "}
          {details.bookingId && details.bookingId.bedName} <br />
          Security Deposit :{" "}
          {`${details.bookingId &&
            details.bookingId.securityDeposite.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}`}{" "}
          <br />
          Confirmation code :{" "}
          {details.bookingId && details.bookingId.confirmationCode}
        </p>
        {/* <h4 className="tw-text-xl tw-font-medium">Cancellation Policy</h4>
        {details.bookingId &&
        details.bookingId.cancelationPolicy[0] === "Relaxed" ? (
          <p className="tw-text-sm tw-text-gray-500 tw-my-2">
            <span className="tw-text-gray-500">Relaxed: </span> Guests can
            receive a full refund up until 24 hours before check-in
          </p>
        ) : null}
        {details.bookingId &&
        details.bookingId.cancelationPolicy[0] === "Moderate" ? (
          <p className="tw-text-sm tw-text-gray-500 tw-my-2">
            <span className="tw-text-gray-500">Moderate: </span> Guests can
            receive a full refund up until 5 days before check-in
          </p>
        ) : null}
        {details.bookingId &&
        details.bookingId.cancelationPolicy[0] === "Strict" ? (
          <p className="tw-text-sm tw-text-gray-500 tw-my-2">
            <span className="tw-text-gray-500">Strict: </span> No refunds
          </p>
        ) : null} */}

        <h4 className="tw-text-xl tw-font-medium">Refund Details</h4>
        <div className="tw-grid tw-grid-cols-2 tw-my-4 tw-text-gray-500   ">
          <p>Amount Refunded</p>
          <p className="tw-text-right">
            {details.refundAmount &&
              `$${parseFloat(details.refundAmount)
                .toFixed(2)
                .toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}`}
          </p>

          <hr className="tw-col-span-full tw-my-2" />
          <p className="tw-font-medium tw-text-gray-800">Total</p>
          <p className="tw-font-medium tw-text-right tw-text-gray-800">
            {details.refundAmount &&
              `$${parseFloat(details.refundAmount)
                .toFixed(2)
                .toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}`}
          </p>
        </div>
        <hr className="tw-col-span-full" />
        <div className="tw-grid tw-grid-cols-2 mt-3 d-flex align-items-center justify-content-between">
          <div className="flex-col flex">
            <h4 className="text-xl font-medium mb-3">Payment</h4>
            <p className="tw-text-gray-500">{details.cardDetail}</p>
            <p className="tw-text-gray-500">
              {moment(details.createdAt)
                .tz(details.bookingId.timezone)
                .format(
                  // "MMMM DD, YYYY h:mm:ss zz"
                  "MMMM Do, YYYY h:mm:ss zz"
                )}{" "}
              {/* EST */}
            </p>
          </div>
          <p className="tw-text-right tw-text-gray-500">
            {details.refundAmount &&
              `$${parseFloat(details.refundAmount)
                .toFixed(2)
                .toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}`}
          </p>
        </div>
        <hr className="tw-col-span-full" />
        <div className="tw-grid tw-grid-cols-2 tw-text-gray-800   ">
          <p className="tw-font-medium font-medium">Amount Paid</p>
          <p className="tw-font-medium tw-text-right font-medium">
            {details.refundAmount &&
              `$${parseFloat(details.refundAmount)
                .toFixed(2)
                .toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}`}
          </p>
        </div>
      </div>
    </div>
  );
};

function ReservationDetails({ propertyId, details, setRefreshkey }) {
  const [isRequesting, setisRequesting] = useState(false);
  const [reportModal, setReportModal] = useState(false);
  const [noticePeriodModal, setNoticePeriodModal] = useState(false);
  const [noticeeUpdateRequest, setNoticeUpdateRequest] = useState(false);
  const [transactionList, setTransactionList] = useState([]);
  const [transactionId, setTransactionId] = useState(null);
  const [refundDetails, setRefundDetails] = useState({
    reufndData: [],
    individualRefund: {},
  });
  const [showRefundReceipt, setShowRefundReceipt] = useState(false);

  const handleBooking = async (status) => {
    try {
      setisRequesting(true);
      const res = await propertyService.updateBookingStatus({
        id: details._id,
        status,
      });

      if (res.status) {
        toast.success(res.message);
        setRefreshkey((e) => e + 1);
      } else toast.error(res.message);
    } catch (error) {
    } finally {
      setisRequesting(false);
    }
  };

  const toggleReportModal = () => {
    setReportModal((e) => !e);
  };

  const toggleNoticePeriodModal = () =>
    setNoticePeriodModal(!noticePeriodModal);

  async function handleUpdateBooking(date) {
    try {
      setNoticeUpdateRequest((e) => !e);
      const { data } = await propertyService.updateBookingNoticePeriod({
        bookingId: details._id,
        checkOutDate: date,
        userId: details.userId.id,
        isAppliedByHost: true,
      });
      if (data.status) {
        toast.success(data.message);
      } else toast.error(data.message || "Something went wrong");
    } catch (error) {
    } finally {
      setRefreshkey((e) => e + 1);
      setNoticeUpdateRequest((e) => !e);
    }
  }

  const countdownRender = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <p> Reservation Expired</p>;
    } else {
      // Render a countdown
      return (
        <span>
          Expires in {`${hours} hr `}
          {`${minutes} min `}
          {`${seconds} Sec`}
        </span>
      );
    }
  };

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const { data } = await payoutService.getTransactions({
          bookingId: details._id,
        });

        setTransactionList(data.data);
      } catch (error) {
        toast.error("Something went wrong!");
      } finally {
      }
    };
    if (details._id) fetchTransactions();
  }, [details]);

  useEffect(() => {
    const fetchRefundDetails = async () => {
      try {
        const { data } = await propertyService.bookingRefundStatus({
          bookingId: details._id,
        });

        if (data.length) {
          setRefundDetails((state) => ({
            ...state,
            reufndData: data,
          }));
        }
      } catch (error) {
        console.log(error);
        toast.error("Something went wrong!");
      } finally {
      }
    };
    if (details._id) fetchRefundDetails();
  }, [details]);

  const toggleRefundReceipt = (refundInfo) => () => {
    setShowRefundReceipt((e) => !e);
    if (refundInfo) {
      setRefundDetails((state) => ({
        ...state,
        individualRefund: refundInfo,
      }));
    }
  };

  const getRefundMonthOrDate = (refundData) => {
    const date = refundData.createdAt
      ? refundData.createdAt.split("T")[0]
      : refundData.createdAt;
    if (details.isMonthlyCheckout === 2) {
      return refundData.dateForIdentifyMonth
        ? moment(refundData.dateForIdentifyMonth).format("MMMM")
        : moment(date).format("MMMM");
    } else {
      return details.bookingType === COLD
        ? moment(date).format("MMMM")
        : moment(date).format("MMMM Do, YYYY");
    }
  };

  const showMonthsBesideReceipt = (transactionData) => {
    if (details.isMonthlyCheckout === 2) {
      return transactionData.dateForIdentifyMonth
        ? moment(transactionData.dateForIdentifyMonth).format("MMMM")
        : moment(transactionData.startDate.split("T")[0]).format("MMMM");
    } else {
      return moment(transactionData.startDate.split("T")[0]).format("MMMM");
    }
  };
  const getWholeBookingPrice = () => {
    if (transactionList.length > 0) {
      let sumOfAllTransactionAmounts = 0;
      for (const tr of transactionList) {
        sumOfAllTransactionAmounts += parseFloat(tr.totalPrice);
      }
      return sumOfAllTransactionAmounts;
    } else {
      if (details.upcommingBookingHistoryId) {
        return details.upcommingBookingHistoryId.totalPrice;
      } else {
        return details.totalPrice;
      }
    }
  };

  const getNoticeText = () => {
    // (Notice Applied by hostname/Crewmates)
    switch (details.noticeBy) {
      case "Host":
        return `(Notice Applied by ${details.hostId.name})`;
      case "Guest":
        return `(Notice Applied by ${details.userId.name})`;
      case "Admin":
        return `(Notice Applied by Crewmates)`;
      default:
        return "";
    }
  };

  const showStausMessagesWithNotice = () => {
    if (details.isNotice) {
      switch (details.status) {
        case "Accepted":
          return `Accepted ${getNoticeText()}`;
        case "Cancel_Scheduled":
          return `Cancel Scheduled ${getNoticeText()}`;
        default:
          return details.status;
      }
    } else {
      switch (details.status) {
        case "Accepted":
          return `Accepted`;
        case "Cancel_Scheduled":
          return `Cancel Scheduled`;
        default:
          return details.status;
      }
    }
  };

  return (
    <>
      {reportModal && (
        <CustomModal>
          <ReportModal toggleReportModal={toggleReportModal} id={details._id} />
        </CustomModal>
      )}
      {noticePeriodModal && (
        <CustomModal>
          <NoticePeriodModal
            checkIn={details.checkInDate}
            onCancle={toggleNoticePeriodModal}
            onProceed={handleUpdateBooking}
            requesting={noticeeUpdateRequest}
            id={details._id}
            bookingDetails={details}
          />
        </CustomModal>
      )}
      {showRefundReceipt && (
        <CustomModal className="w-full  " onClose={toggleRefundReceipt(null)}>
          <RefundReceipt
            details={refundDetails.individualRefund}
            onClose={toggleRefundReceipt(null)}
          />
        </CustomModal>
      )}
      {transactionId && (
        <CustomModal>
          <Receipt
            onClose={() => setTransactionId(null)}
            transactionId={transactionId}
          />
        </CustomModal>
      )}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {details.status === "Pending" && (
        <p className="tw-text-sm tw-text-primary tw-mt-4 tw-font-medium ">
          <Countdown
            renderer={countdownRender}
            // date={new Date(details.createdAt)}
            // date={addHours(new Date(details.createdAt), 24)}
            date={moment(details.createdAt).add(24, "hour")}
          />
        </p>
      )}
      <div className="tw-grid tw-grid-cols-2 spacing-dtail lg:tw-grid-cols-3 tw-text-sm py-4">
        <div>
          <label className="tw-text-gray-800 tw-mb-1 tw-font-medium">
            Reservation Id
          </label>
          <p className="tw-text-700">{details.bookingId || "--"}</p>
        </div>
        <div>
          <label className="tw-text-gray-800 tw-mb-1 tw-font-medium">
            Booking Type
          </label>
          <p className="tw-text-700">
            {details.propertyId && details.propertyId.bookingType}
          </p>
        </div>
        <div>
          <label className="tw-text-gray-800 tw-mb-1 tw-font-medium">
            Confirmation code
          </label>
          <p className="tw-text-700 tw-uppercase">
            {details.confirmationCode || "TEHWY"}
          </p>
        </div>
        <div>
          <label className="tw-text-gray-800 tw-mb-1 tw-font-medium">
            Listing name
          </label>
          <p className="tw-text-700">
            {details.propertyId && details.propertyId.listingName}
          </p>
        </div>
        <div>
          <label className="tw-text-gray-800 tw-mb-1 tw-font-medium">
            Guest name
          </label>
          <p className="tw-text-700">
            {" "}
            {details.userId && details.userId.name}
          </p>
        </div>
        <div>
          <label className="tw-text-gray-800 tw-mb-1 tw-font-medium">
            Selected Room
          </label>
          <p className="tw-text-700"> {details.roomName}</p>
        </div>
        <div>
          <label className="tw-text-gray-800 tw-mb-1 tw-font-medium">
            Selected Bed
          </label>
          <p className="tw-text-700">
            {" "}
            {details.bedName ? details.bedName : "--"}
          </p>
        </div>
        <div>
          <label className="tw-text-gray-800 tw-mb-1 tw-font-medium">
            Price
          </label>
          <p className="tw-text-700">
            {`$${details.totalPrice &&
              parseFloat(getWholeBookingPrice())
                .toFixed(2)
                .toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}`}
          </p>
          {/* <p className="tw-text-700">
            {`$${details.totalPrice &&
              parseFloat(details.totalPrice)
                .toFixed(2)
                .toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}`}
          </p> */}
        </div>
        <div>
          <label className="tw-text-gray-800 tw-mb-1 tw-font-medium">
            Check In
          </label>
          <p className="tw-text-700">
            {" "}
            {moment(details.checkInDate.split("T")[0]).format("MMMM Do, YYYY")}
          </p>
        </div>
        {details.checkOutDate && (
          <div>
            <label className="tw-text-gray-800 tw-mb-1 tw-font-medium">
              Check Out
            </label>
            <p className="tw-text-700">
              {" "}
              {moment(details.checkOutDate.split("T")[0]).format(
                "MMMM Do, YYYY"
              )}
            </p>
          </div>
        )}
        <div>
          <label className="tw-text-gray-800 tw-mb-1 tw-font-medium">
            Created At
          </label>
          <p className="tw-text-700">
            {moment(
              details.createdAt != undefined
                ? details.createdAt.split("T")[0]
                : details.createdAt
            ).format("MMMM Do, YYYY")}
          </p>
        </div>
        <div>
          <label className="tw-text-gray-800 tw-mb-1 tw-font-medium">
            Updated At
          </label>
          <p className="tw-text-700">
            {moment(
              details.updatedAt != undefined
                ? details.updatedAt.split("T")[0]
                : details.updatedAt
            ).format("MMMM Do, YYYY")}
          </p>
        </div>
        {details.status && (
          <div>
            <label className="tw-text-gray-800 tw-mb-1 tw-font-medium">
              Status
            </label>
            <p className="tw-text-700">
              {" "}
              <span className="tw-p-2 tw-py-1 tw-text-xs tw-rounded tw-bg-primary tw-text-white">
                {/* {details.status} */}
                {showStausMessagesWithNotice()}
              </span>
            </p>
          </div>
        )}
      </div>

      {refundDetails.reufndData.length > 0 && (
        <div className="">
          <h4>Refund Status</h4>
          <div className="tw-grid md:tw-grid-cols-2 lg:tw-grid-cols-3">
            {/* <div className="tw-p-2 tw-font-medium tw-text-sm tw-text-gray-700">
              Created At
            </div> */}
            <div className="tw-p-2 tw-font-medium tw-text-sm tw-text-gray-700">
              {details.bookingType === COLD ? "Month" : "Created At"}
            </div>
            <div className="tw-p-2 tw-font-medium tw-text-sm tw-text-gray-700">
              Amount
            </div>
            <div className="tw-p-2 tw-font-medium tw-text-sm tw-text-gray-700">
              Action
            </div>
          </div>
          {refundDetails.reufndData &&
            refundDetails.reufndData.map((refund, index) => {
              return (
                <div
                  className="tw-grid md:tw-grid-cols-2 lg:tw-grid-cols-3"
                  key={`refund-${index}`}
                >
                  <div className={`tw-p-2`}>
                    <p className="tw-text-700">
                      {getRefundMonthOrDate(refund)}
                    </p>
                  </div>
                  <div className="tw-p-2 tw-pr-4">
                    <p className="tw-text-700">
                      {refund.refundAmount &&
                        parseFloat(refund.refundAmount)
                          .toFixed(2)
                          .toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })}
                    </p>
                  </div>
                  <div
                    className="tw-p-2 tw-pr-4 tw-cursor-pointer tw-text-primary"
                    onClick={toggleRefundReceipt(refund)}
                  >
                    View Receipt
                  </div>
                </div>
              );
            })}
          {/* ))} */}
        </div>
      )}

      {/* Div start to show the Payment plan which includes month,amount and action column in it */}
      {/* Comment added on 10-28-2022 */}
      {/* {details.bookingType === COLD && ( */}
      <div className="">
        <h4>Payment Plan</h4>

        <div className="tw-grid md:tw-grid-cols-2 lg:tw-grid-cols-3">
          <div className="tw-p-2 tw-font-medium tw-text-sm tw-text-gray-700">
            {details.bookingType === COLD ? "Month" : "Booking"}
          </div>
          <div className="tw-p-2 tw-font-medium tw-text-sm tw-text-gray-700">
            Amount
          </div>

          <div className="tw-p-2 tw-font-medium tw-text-sm tw-text-gray-700">
            Action
          </div>
        </div>
        {transactionList.map((data, ind) => (
          <div
            key={data.id}
            className="tw-grid md:tw-grid-cols-2 lg:tw-grid-cols-3"
          >
            <div
              className={`tw-p-2 ${details.bookingType === COLD && "tw-pr-4"}`}
            >
              {details.bookingType === COLD
                ? // ? moment(data.startDate.split("T")[0]).format("MMMM")
                  showMonthsBesideReceipt(data)
                : `${moment(details.checkInDate.split("T")[0]).format(
                    "MMMM Do, YYYY"
                  )} - ${moment(details.checkOutDate.split("T")[0]).format(
                    "MMMM Do, YYYY"
                  )}`}
            </div>
            <div className="tw-p-2 tw-pr-4">
              {data.totalPrice &&
                parseFloat(data.totalPrice)
                  .toFixed(2)
                  .toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
            </div>
            <div
              className="tw-p-2 tw-pr-4 tw-cursor-pointer tw-text-primary"
              onClick={() => {
                setTransactionId(data.id);
              }}
            >
              View Receipt
            </div>
          </div>
        ))}

        {!Boolean(transactionList.length) && (
          <div className="tw-p-8 tw-grid tw-place-content-center">
            No Transactions found
          </div>
        )}
      </div>
      {/* )} */}

      {/* Div ends to show the Payment plan */}

      <div className="space-top-btn">
        <button
          onClick={() => toggleReportModal()}
          className="btn btn-sm btn-secondary tw-mr-2"
        >
          Report
        </button>
        {details.status === "Pending" && (
          <>
            <button
              disabled={isRequesting}
              onClick={() => handleBooking("Accepted")}
              className="btn btn-sm btn-secondary tw-mr-2"
            >
              Accept
            </button>
            <button
              onClick={() => handleBooking("Rejected")}
              disabled={isRequesting}
              className="btn btn-sm btn-primary"
            >
              Reject
            </button>
          </>
        )}
        {details.bookingType === COLD &&
          new Date(details.checkInDate) - new Date() <= 0 &&
          details.status === "Accepted" &&
          details.isMonthlyCheckout === 2 &&
          !details.isNotice && (
            <Tooltip
              arrow
              theme="light"
              html={
                <span className="tw-text-xs tw-bg-white tw-z-10 tw-shadow-sm tw-p-1 tw-px-2 tw-text-slate-600">
                  Give 30 days notice period for leaving the property
                </span>
              }
              position="top"
              trigger="mouseenter"
            >
              <button
                onClick={toggleNoticePeriodModal}
                className="btn btn-sm btn-primary"
              >
                {false ? "Please wait..." : " Apply Notice"}
              </button>
            </Tooltip>
          )}
      </div>
    </>
  );
}

export default ReservationDetails;
