import { DateRange } from 'react-date-range'
import React, { useEffect, useState, useRef, useMemo } from 'react'
import useQuery from '../../hooks/useQuery'
import { propertyService } from '../../_services'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import moment from 'moment'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
import {
    checkInitialDate, getBookedDatesForHotbed, getHotbedInitialVisitDate, getHotbedMaxDate, getManualBlockedDatesForHotbed,
    isDateInRangeBlocked, splitIntoRanges
} from '../../_helpers/helpers'

import RoomAndBedSelection from './RoomAndBedSelection'
import BlockUnblockButton from './BlockUnblockButton'
import { CRASHPAD, ENTIRE_PLACE, HOT_BED } from '../../_helpers/constants'
import { FixedDate } from '../../_helpers/DateUTCHelpers'

export default function HotBedContainer({ properyDetails, fetchPropertyDetails, selectedRoom, selectedBed, setSelectedBed, handleRoomSelection }) {
    const query = useQuery()
    const today = new Date()
    const bookingType = HOT_BED
    const [isBlocking, setIsBlockingOrUnblocking] = useState(true)
    const [hotbedMaxDate, setHotbedMaxDate] = useState(new Date(today.getFullYear(), today.getMonth() + 2, 0))


    const [visitDate, setVisitDate] = useState([
        {
            startDate: checkInitialDate(moment(new Date()).toDate()),
            endDate: checkInitialDate(moment(moment(new Date()).add(1, 'day')).toDate()),
            key: 'selection',
        },
    ])


    const bedsContainer = useRef()




    const scrollLeftHandlerForBed = () => {
        bedsContainer.current.scrollTo({
            left: bedsContainer.current.scrollLeft - 88,
            behavior: 'smooth',
        })
    }

    const scrollRightHandlerForBed = () => {
        bedsContainer.current.scrollTo({
            left: bedsContainer.current.scrollLeft + 88,
            behavior: 'smooth',
        })
    }

    const confirmBlockUnblock = () => {
        if (properyDetails && properyDetails.rentalType === CRASHPAD) {
            const startDate = moment(visitDate[0].startDate).startOf('day');
            const endDate = moment(visitDate[0].endDate).endOf('day');

            // const startDateInString = startDate.format('YYYY-MM-DD');
            // const endDateInString = endDate.format('YYYY-MM-DD')

            // if (startDateInString === endDateInString) {
            //     return toast.error('At least 1 night required to block. ')
            // }

            const bookedDates = selectedBed.hotbedBookedDates.length > 0 ? selectedBed.hotbedBookedDates.map(date => moment(date).startOf('day')) : [];

            const isDateAfterOrEqual = bookedDates.some(
                bookedDate => startDate.isSameOrAfter(bookedDate, 'day') || endDate.isSameOrAfter(bookedDate, 'day')
            );

            const isDateOverlapping = bookedDates.some(bookedDate => bookedDate.isBetween(startDate, endDate, 'day', '[)'));

            if (isDateOverlapping && bookingType === 'Hot bed') {
                toast.error(isDateAfterOrEqual ? 'You cannot block dates that are equal to or after any booked dates!' :
                    'You cannot block dates that overlap with or are the same as any booked dates!');
                return;
            }

            const bedDetails = {
                bookingType,
                propertyId: properyDetails._id,
                listingId: properyDetails.listingId,
                hostId: properyDetails.addedBy,
                selectedRoom: selectedRoom.roomDetails._id,
                selectedBed: selectedBed._id,
                startDate: startDate.format('YYYY-MM-DD'),
                endDate: endDate.format('YYYY-MM-DD'),
                propertyType: properyDetails.rentalType,
                isBlocking,

            };

            propertyService.blockUnblockedBeds(bedDetails)
                .then(res => {
                    if (res.statusCode === 200) {
                        toast.success(isBlocking ? 'Blocked Successfully!' : 'Unblocked Successfully!');
                        fetchPropertyDetails(properyDetails._id, { bookingType }, false);
                    }
                })
                .catch(() => {
                    toast.error('Something went wrong while fetching property details');
                });
        }

        if (properyDetails && properyDetails.rentalType === ENTIRE_PLACE && selectedRoom) {
            const startDate = moment(visitDate[0].startDate).startOf('day');
            const endDate = moment(visitDate[0].endDate).endOf('day');

            // const startDateInString = startDate.format('YYYY-MM-DD');
            // const endDateInString = endDate.format('YYYY-MM-DD')

            // if (startDateInString === endDateInString) {
            //     return toast.error('At least 1 night required to block. ')
            // }


            const bookedDates = selectedRoom.roomDetails.hotbedBookedDates || []

            const isDateAfterOrEqual = bookedDates.some(
                bookedDate => startDate.isSameOrAfter(bookedDate, 'day') || endDate.isSameOrAfter(bookedDate, 'day')
            );

            const isDateOverlapping = bookedDates.some(bookedDate => bookedDate.isBetween(startDate, endDate, 'day', '[)'));

            if (isDateOverlapping && bookingType === HOT_BED) {
                toast.error(isDateAfterOrEqual ? 'You cannot block dates that are equal to or after any booked dates!' :
                    'You cannot block dates that overlap with or are the same as any booked dates!');
                return;
            }

            const bedDetails = {
                bookingType,
                propertyId: properyDetails._id,
                listingId: properyDetails.listingId,
                hostId: properyDetails.addedBy,
                selectedRoom: selectedRoom.roomDetails._id,
                selectedBed: null,
                startDate: startDate.format('YYYY-MM-DD'),
                endDate: endDate.format('YYYY-MM-DD'),
                propertyType: properyDetails.rentalType,
                isBlocking,
            };

            propertyService.blockUnblockedBeds(bedDetails)
                .then(res => {
                    if (res.statusCode === 200) {
                        toast.success(isBlocking ? 'Blocked Successfully!' : 'Unblocked Successfully!');
                        fetchPropertyDetails(properyDetails._id, { bookingType }, false);
                    }
                })
                .catch(() => {
                    toast.error('Something went wrong while fetching property details');
                });
        }

    };


    useEffect(() => {
        if (properyDetails && properyDetails.rentalType === CRASHPAD && selectedBed) {
            setHotbedMaxDate(getHotbedMaxDate(selectedBed))
            const visitDate = getHotbedInitialVisitDate(selectedBed)
            setVisitDate(visitDate)
        }
        if (properyDetails && properyDetails.rentalType === ENTIRE_PLACE && selectedRoom) {
            setHotbedMaxDate(getHotbedMaxDate(selectedRoom.roomDetails))
            const visitDate = getHotbedInitialVisitDate(selectedRoom.roomDetails)
            setVisitDate(visitDate)
        }

    }, [selectedBed, bookingType, selectedRoom, properyDetails])



    const getDayStyle = useMemo(
        () => date => {
            if (properyDetails && properyDetails.rentalType === CRASHPAD && selectedBed) {
                const dateStr = moment(date).format('YYYY-MM-DD')
                const blockedDates = getManualBlockedDatesForHotbed(selectedBed)
                const bookedDates = getBookedDatesForHotbed(selectedBed)

                const selectedStartDateStr = visitDate && moment(visitDate[0].startDate).format('YYYY-MM-DD')
                const selectedEndDateStr = visitDate && moment(visitDate[0].endDate).format('YYYY-MM-DD')

                const individualDate = FixedDate(dateStr)
                const selectedStartDate = FixedDate(selectedStartDateStr)
                const selectedEndDate = FixedDate(selectedEndDateStr)
                const isWithinSelectedRange = bookingType !== 'Cold bed' && individualDate >= selectedStartDate && individualDate <= selectedEndDate

                const sortedBlockedDates = blockedDates.map(d => moment(d).format('YYYY-MM-DD')).sort()
                const sortedBookedDates = bookedDates.map(d => moment(d).format('YYYY-MM-DD')).sort()

                if (isWithinSelectedRange) {
                    return {
                        zIndex: '-1',
                        color: 'white',
                    }
                } else {

                    const blockRanges = sortedBlockedDates.length > 0 ? splitIntoRanges(sortedBlockedDates) : []
                    const bookedRanges = sortedBookedDates.length > 0 ? splitIntoRanges(sortedBookedDates) : []

                    for (const bookedRange of bookedRanges) {
                        if (bookedRange.includes(dateStr)) {
                            return { width: '100%', background: '#f8f8f8', color: 'black', zIndex: '0' }

                        }
                    }

                    for (const range of blockRanges) {
                        if (range.includes(dateStr)) {
                            const isSingle = range.length === 1;
                            const isStart = dateStr === range[0];
                            const isEnd = dateStr === range[range.length - 1];

                            return {
                                width: '100%', background: '#f48889', color: 'white', zIndex: 1,
                                borderRadius: isSingle ? '18px' : isStart ? '18px 0px 0px 18px' : isEnd ? '0px 18px 18px 0px' : '0px',
                            };
                        }
                    }
                }
            } else if (properyDetails && properyDetails.rentalType === ENTIRE_PLACE && selectedRoom) {
                const dateStr = moment(date).format('YYYY-MM-DD')
                const blockedDates = getManualBlockedDatesForHotbed(selectedRoom.roomDetails); // isDisableBlockedDate(selectedBed) || []
                const bookedDates = getBookedDatesForHotbed(selectedRoom.roomDetails) // isDisabledBookedDates(selectedBed) || []

                const selectedStartDateStr = visitDate && moment(visitDate[0].startDate).format('YYYY-MM-DD')
                const selectedEndDateStr = visitDate && moment(visitDate[0].endDate).format('YYYY-MM-DD')

                const individualDate = FixedDate(dateStr)
                const selectedStartDate = FixedDate(selectedStartDateStr)
                const selectedEndDate = FixedDate(selectedEndDateStr)
                const isWithinSelectedRange = bookingType !== 'Cold bed' && individualDate >= selectedStartDate && individualDate <= selectedEndDate

                const sortedBlockedDates = blockedDates.map(d => moment(d).format('YYYY-MM-DD')).sort()
                const sortedBookedDates = bookedDates.map(d => moment(d).format('YYYY-MM-DD')).sort()

                if (isWithinSelectedRange) {
                    return {
                        zIndex: '-1',
                        color: 'white',
                    }
                } else {

                    const blockRanges = sortedBlockedDates.length > 0 ? splitIntoRanges(sortedBlockedDates) : []
                    const bookedRanges = sortedBookedDates.length > 0 ? splitIntoRanges(sortedBookedDates) : []

                    for (const bookedRange of bookedRanges) {
                        if (bookedRange.includes(dateStr)) {
                            return { width: '100%', background: '#f8f8f8', color: 'black', zIndex: '0' }

                        }
                    }

                    for (const range of blockRanges) {
                        if (range.includes(dateStr)) {
                            const isSingle = range.length === 1;
                            const isStart = dateStr === range[0];
                            const isEnd = dateStr === range[range.length - 1];

                            return {
                                width: '100%', background: '#f48889', color: 'white', zIndex: '0',
                                borderRadius: isSingle ? '18px' : isStart ? '18px 0px 0px 18px' : isEnd ? '0px 18px 18px 0px' : '0px',
                            };
                        }
                    }
                }
            }



        },
        [bookingType, visitDate, selectedBed, selectedRoom]
    )

    const updateToUnblock = date => {

        if (properyDetails && properyDetails.rentalType === CRASHPAD && selectedBed) {
            const blockedDates = getManualBlockedDatesForHotbed(selectedBed) || []
            const bookedDates = getBookedDatesForHotbed(selectedBed) || []
            const autoBlockedDates = bookedDates

            const dateStr = moment(date).format('YYYY-MM-DD')
            const selectedStartDateStr = visitDate && moment(visitDate[0].startDate).format('YYYY-MM-DD')
            const selectedEndDateStr = visitDate && moment(visitDate[0].endDate).format('YYYY-MM-DD')

            const individualDate = FixedDate(dateStr)
            const selectedStartDate = FixedDate(selectedStartDateStr)
            const selectedEndDate = FixedDate(selectedEndDateStr)

            const sortedBookedDates = bookedDates.map(d => moment(d).format('YYYY-MM-DD')).sort()
            const sortedAutoBlockedDates = autoBlockedDates.map(d => moment(d).format('YYYY-MM-DD')).sort()

            if (blockedDates && blockedDates.includes(dateStr)) {
                if (individualDate >= selectedStartDate && individualDate <= selectedEndDate) {
                    return true;
                }
                return true;
            }

            if (sortedBookedDates.includes(dateStr) || sortedAutoBlockedDates.includes(dateStr)) {
                return false;
            }
        }

        if (properyDetails && properyDetails.rentalType === ENTIRE_PLACE && selectedRoom) {
            const blockedDates = getManualBlockedDatesForHotbed(selectedRoom.roomDetails) || []
            const bookedDates = getBookedDatesForHotbed(selectedRoom.roomDetails) || []
            const autoBlockedDates = bookedDates//isDisabledAutoBlockedDates(selectedBed) || []

            const dateStr = moment(date).format('YYYY-MM-DD')
            const selectedStartDateStr = visitDate && moment(visitDate[0].startDate).format('YYYY-MM-DD')
            const selectedEndDateStr = visitDate && moment(visitDate[0].endDate).format('YYYY-MM-DD')

            const individualDate = FixedDate(dateStr)
            const selectedStartDate = FixedDate(selectedStartDateStr)
            const selectedEndDate = FixedDate(selectedEndDateStr)

            const sortedBookedDates = bookedDates.map(d => moment(d).format('YYYY-MM-DD')).sort()
            const sortedAutoBlockedDates = autoBlockedDates.map(d => moment(d).format('YYYY-MM-DD')).sort()

            if (blockedDates && blockedDates.includes(dateStr)) {
                if (individualDate >= selectedStartDate && individualDate <= selectedEndDate) {
                    return true;
                }
                return true;
            }

            if (sortedBookedDates.includes(dateStr) || sortedAutoBlockedDates.includes(dateStr)) {
                return false;
            }
        }

        return false;

    }



    const handleOnChangeDates = e => {

        if (properyDetails && properyDetails.rentalType === CRASHPAD && selectedBed) {
            const blockedDates = getManualBlockedDatesForHotbed(selectedBed)

            if (!e.selection || !e.selection.startDate || !e.selection.endDate) {
                return
            }
            const bothDatesBlocked = isDateInRangeBlocked(e.selection.startDate, e.selection.endDate, blockedDates)
            setIsBlockingOrUnblocking(!bothDatesBlocked)

            setVisitDate([
                {
                    startDate: new Date(e.selection.startDate),
                    endDate: new Date(e.selection.endDate),
                    key: 'selection',
                },
            ])
        }
        if (properyDetails && properyDetails.rentalType === ENTIRE_PLACE && selectedRoom) {
            const blockedDates = getManualBlockedDatesForHotbed(selectedRoom.roomDetails)

            if (!e.selection || !e.selection.startDate || !e.selection.endDate) {
                return
            }
            const bothDatesBlocked = isDateInRangeBlocked(e.selection.startDate, e.selection.endDate, blockedDates)
            setIsBlockingOrUnblocking(!bothDatesBlocked)

            setVisitDate([
                {
                    startDate: new Date(e.selection.startDate),
                    endDate: new Date(e.selection.endDate),
                    key: 'selection',
                },
            ])
        }


    }


    function getDisabledDates() {

        if (properyDetails && properyDetails.rentalType === CRASHPAD && selectedBed) {
            const list = getBookedDatesForHotbed(selectedBed) || []

            const listToDate = list.map(item => FixedDate(item))

            return listToDate;

        }
        if (properyDetails && properyDetails.rentalType === ENTIRE_PLACE && selectedRoom) {

            const list = getBookedDatesForHotbed(selectedRoom.roomDetails) || []

            const listToDate = list.map(item => FixedDate(item))

            return listToDate;
        }

        return []
    }



    return (
        <>
            <div className="tw-my-4">
                <div className="tw-mx-auto tw-w-max">
                    <DateRange
                        rangeColors={['#23426E']}
                        minDate={new Date()}
                        maxDate={hotbedMaxDate}
                        className="tw-w-full"
                        editableDateInputs={false}
                        onChange={e => {
                            const { selection } = e
                            const startDate = new Date(selection.startDate)
                            const today = new Date()
                            if (startDate < today) {
                                e.startDate = today
                            }
                            handleOnChangeDates(e)
                        }}
                        moveRangeOnFirstSelection={false}
                        ranges={visitDate}
                        disabledDates={getDisabledDates()}
                        dayContentRenderer={date => {
                            return (
                                <>
                                    <div style={{ ...getDayStyle(date) }}>{date.getDate()}</div>
                                    <div
                                        className="temp-date"
                                        style={{
                                            position: 'absolute',
                                            color: updateToUnblock(date) ? 'black' : 'black',
                                        }}
                                    >
                                        {date.getDate()}
                                    </div>
                                </>
                            )
                        }}
                    />
                </div>
            </div>

            <RoomAndBedSelection
                bedsContainer={bedsContainer}
                handleRoomSelection={handleRoomSelection}
                properyDetails={properyDetails}
                scrollLeftHandlerForBed={scrollLeftHandlerForBed}
                scrollRightHandlerForBed={scrollRightHandlerForBed}
                selectedRoom={selectedRoom}
                selectedBed={selectedBed}
                setSelectedBed={setSelectedBed}
            />
            {
                properyDetails && <BlockUnblockButton properyDetails={properyDetails} selectedRoom={selectedRoom}
                    confirmBlockUnblock={confirmBlockUnblock} isBlocking={isBlocking} selectedBed={selectedBed} />
            }

        </>
    )
}
