import axios from "axios";
import constant from "../_config/constant";
import { authHeader } from "../_helpers";

const api = axios.create({ baseURL: constant.apiUrl });

export const getNotification = async (page) => {
  const response = await api.get(`/notification/host?page=${page}`, {
    headers: authHeader(),
  });
  return response;
};
