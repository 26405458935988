import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { Wrapper } from "./Wrapper";
import { paymentService } from "../../_services/payment.service";
import AllPayments from "./AllPayments";
let firstIdInitialized = false;
function CustomPaymentsDashboard() {
  const [balance_transaction, setBalance_transaction] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [starting_after, setStarting_after] = useState(null);
  const [endingBefore, setEndingBefore] = useState(null);
  const [activeTab, setActiveTab] = useState(1);
  const [paginationDirection, setPaginationDirection] = useState(null);
  const [firstId, setFirstId] = useState(null);
  const [refresh, setRefresh] = useState(new Date());

  const [lastId, setLastId] = useState(null);

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
    setFirstId(null);
    setLastId(null);
  };

  useEffect(() => {
    fetchTransactions();
  }, [activeTab, starting_after, endingBefore, refresh]);

  const fetchTransactions = async () => {
    setIsLoading(true);
    setBalance_transaction([]);
    let type = "payment";
    if (activeTab === 1) {
      type = "payment";
    } else if (activeTab === 2) {
      type = "dispute";
    } else if (activeTab === 3) {
      type = "all";
    }

    const getcustomBalanceTransactions = await paymentService.getcustomBalanceTransactions(
      {
        type,
        starting_after,
        ending_before: endingBefore,
        paginationDirection,
      }
    );
    console.log({ getcustomBalanceTransactions });

    if (
      getcustomBalanceTransactions &&
      getcustomBalanceTransactions.data &&
      getcustomBalanceTransactions.data.data
    ) {
      setBalance_transaction(getcustomBalanceTransactions.data.data);
      const all = getcustomBalanceTransactions.data.data;

      if (all.length > 0) {
        setFirstId(all[0].id);
        setLastId(all[all.length - 1].id);
      } else {
        setFirstId(null);
        setLastId(null);
      }
    }

    setIsLoading(false);
  };
  const handleNext = () => {
    if (lastId) {
      setStarting_after(lastId);
    }
    setPaginationDirection("Next");
    setRefresh(new Date());
  };

  const handlePrevious = () => {
    setPaginationDirection("Prev");
    if (firstId) {
      setEndingBefore(firstId);
    }
    setRefresh(new Date());
  };

  return (
    <div>
      <ToastContainer />
      <Wrapper title={"Payments"}>
        <div>
          <ul className="nav nav-tabs">
            <li className="nav-item">
              <button
                className={`nav-link ${activeTab === 1 ? "active" : ""}`}
                onClick={() => handleTabClick(1)}
              >
                All Payments
              </button>
            </li>
            <li className="nav-item">
              <button
                className={`nav-link ${activeTab === 2 ? "active" : ""}`}
                onClick={() => handleTabClick(2)}
              >
                Disputes
              </button>
            </li>
            <li className="nav-item">
              <button
                className={`nav-link ${activeTab === 3 ? "active" : ""}`}
                onClick={() => handleTabClick(3)}
              >
                All Transactions
              </button>
            </li>
          </ul>
          <div className="tab-content">
            <div
              className={`tab-pane fade ${
                activeTab === 1 ? "show active" : ""
              }`}
            >
              <AllPayments list={balance_transaction} />
              {isLoading && "Loading...."}
              {!isLoading &&
                balance_transaction.length === 0 &&
                "No record found"}
            </div>
            <div
              className={`tab-pane fade ${
                activeTab === 2 ? "show active" : ""
              }`}
            >
              <AllPayments list={balance_transaction} />
              {isLoading && "Loading...."}
              {!isLoading &&
                balance_transaction.length === 0 &&
                "No record found"}
            </div>
            <div
              className={`tab-pane fade ${
                activeTab === 3 ? "show active" : ""
              }`}
            >
              <AllPayments list={balance_transaction} />
              {isLoading && "Loading...."}
              {!isLoading &&
                balance_transaction.length === 0 &&
                "No record found"}
            </div>

            <ul className="pagination justify-content-center">
              <li className="page-item">
                <button onClick={handlePrevious} className="page-link">
                  Previous
                </button>
              </li>
              <li className="page-item">
                <button onClick={handleNext} className={`page-link`}>
                  Next
                </button>
              </li>
            </ul>
          </div>
        </div>
      </Wrapper>
    </div>
  );
}

export default CustomPaymentsDashboard;
