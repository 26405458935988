import React, { useEffect, useRef } from "react";
import Navbar from "./../../components/Navbar";
import TopBar from "./../../components/topbar";
import constant from "./../../_config/constant";
import { NavLink, Route, useParams, useHistory } from "react-router-dom";
import CustomModal from "./../../components/ReactModal";
import { AiOutlinePlus } from "react-icons/ai";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { disputeService } from "./../../_services";
import { useState } from "react";
// import moment from "moment";
import * as moment from 'moment-timezone';

import ReceiptModal from "../ReceiptModal";
import { IoMdClose } from "react-icons/io";

function DisputeDetails() {
  const reasonRef = useRef();
  const [reason, setReason] = useState("");
  const [refreshKey, setRefreshkey] = useState(0);
  const [editDisputeModal, setEditDisputeModal] = useState(false);
  const [isRequestingSettlement, setIsRequestingSettlement] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [acceptingDisputeApi, setAcceptingDisputeApi] = useState(false);
  const [rejectingDisputeApi, setRejectingDisputeApi] = useState(false);
  const [involvingAdmin, setinvolvingAdmin] = useState(false);
  const [disputeData, setDisputeData] = useState({
    amount: 0,
    bookingId: "",
    conversation: [],
    createdAt: "",
    hostId: "",
    images: [],
    isPaid: false,
    message: "",
    securityDeposite: 0,
    status: "",
    updatedAt: "",
    userId: "",
    __v: 0,
    _id: "",
  });
  const [lastUserMessageId, setLastUserMessageId] = useState(null);
  const params = useParams();
  const history = useHistory();
  const [disputeId, setDisputeId] = useState(null);

  useEffect(() => {
    setFetching(true);
    const fetchDetails = () => {
      disputeService
        .details(params.id)
        .then((res) => {
          setDisputeData(res.data);
          let convo = res.data.conversation;
          for (let i = convo.length - 1; i >= 0; i--) {
            if (convo[i].type === "Guest") {
              setLastUserMessageId(convo[i].id);
              break;
            }
          }

          setFetching(false);
        })
        .catch((err) => {
          toast.error("Something went wrong!!");
          history.push("/dispute/list");
          setFetching(false);
        });
    };
    fetchDetails();
  }, [params, refreshKey]);

  var data = localStorage.getItem(constant.DATA_KEY);
  const decoded = data ? JSON.parse(data) : {};

  function toggleEditDisputeModal() {
    setEditDisputeModal((e) => !e);
  }

  const handleDisputeSettlement = (e) => {
    e.preventDefault();
    setIsRequestingSettlement(true);

    if (!e.target.message.value.length && !e.target.amount.value.length) {
      return toast.warn("Invalid settlement request");
    }
    disputeService
      .reply({
        message: e.target.message.value,
        amount: parseFloat(e.target.amount.value),
        type: "Host",
        disputeId: disputeData._id,
        status: "Settle",
      })
      .then((res) => {
        toggleEditDisputeModal();
        setRefreshkey((e) => e + 1);
        setIsRequestingSettlement(false);
      })
      .catch((err) => {
        toast.error("Something went wrong");
        setIsRequestingSettlement(false);
      });
  };

  const acceptDisputeAmount = ({ disputeId, conversationId }) => {
    setAcceptingDisputeApi(true);
    disputeService
      .acceptAmount({
        disputeId: disputeId,
        conversationId: conversationId,
      })
      .then((res) => {
        setRefreshkey((e) => e + 1);
        setAcceptingDisputeApi(false);
      })
      .catch((err) => {
        toast.error("Something went wrong");
        setAcceptingDisputeApi(false);
      });
  };

  const rejectDispute = () => {
    setRejectingDisputeApi(true);
    disputeService
      .reply({
        type: "Host",
        disputeId: disputeData._id,
        status: "Rejected",
      })
      .then((res) => {
        setRefreshkey((e) => e + 1);
      })
      .catch((err) => {
        toast.error("Something went wrong");
        setRejectingDisputeApi(false);
      });
  };

  const renderAcceptDisputeButton = () => {
    return disputeData.conversation.map((curr, id) => {
      if (
        lastUserMessageId === curr.id &&
        disputeData.status === "Active" &&
        disputeData.amount !== parseFloat(curr.amount)
      ) {
        return (
          <button
            onClick={() => {
              acceptDisputeAmount({
                disputeId: disputeData._id,
                conversationId: curr.id,
              });
            }}
            className="btn btn-sm btn-secondary"
          >
            {acceptingDisputeApi
              ? "Please wait..."
              : `Accept 
                  ${parseFloat(curr.amount).toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}`}
          </button>
        );
      } else {
        return null;
      }
    });
  };

  const involveAdmin = async () => {
    setinvolvingAdmin((e) => !e);
    const res = await disputeService.involveAdmin(disputeData._id);

    setinvolvingAdmin((e) => !e);
    setRefreshkey((e) => e + 1);
  };

  /* View dispute receipt modal code added for host*/
  /* Change added on 11-01-2022 */
  const ViewDisputeReceipt = ({ onClose, disputeId }) => {
    console.log(
      "Inside view dispute receipt function call in host website------------------------------",
      disputeData
    );
    return (
      <>
        <div className="tw-bg-white p-4 tw-rounded-md tw-relative">
          <span
            onClick={onClose}
            className="tw-right-6 tw-top-6 tw-cursor-pointer align-right"
          >
            <IoMdClose size={32} />
          </span>

          <h2 className="text-3xl font-medium">Dispute receipt</h2>
          <h3 className="text-2xl font-medium my-4">Your Dispute Receipt from Crewmates</h3>
          
          {/* <p>Receipt ID: {disputeData.bookingId}</p>
           */}
          <p className="tw-text-gray-500 tw-mt-2">
          Receipt ID: {disputeData.receiptId?disputeData.receiptId:disputeData.bookingId}{" "}
          {disputeData.updatedAt &&
            `- ${moment(disputeData.updatedAt).tz(disputeData.timezone).format("MMMM DD, YYYY")}`}
        </p>
        <p className="tw-text-gray-500 tw-mt-2">
          Booking ID: {disputeData.originalBookingId}{" "}
        </p>
        <br />
        {/* <br /> */}
        <h3 className="text-2xl font-medium tw-mb-3">Dispute details</h3>
        {/* <br /> */}
          <p className="tw-mb-1">Dispute ID: {disputeData._id}</p>
          <p className="tw-mb-1">Amount Charged: ${parseFloat(disputeData.amount)
                  .toFixed(2)
                  .toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}</p>

          <hr className="tw-col-span-full" />
          <div className="tw-grid tw-grid-cols-2 mt-3 d-flex align-items-center justify-content-between">
            <div className="flex-col flex">
              <h4 className="text-xl font-medium mb-3">Payment</h4>
              <p className="tw-text-gray-500">{disputeData.cardDetail}</p>
              <p className="tw-text-gray-500">
                {moment(disputeData.createdAt).tz(disputeData.timezone).format(
                  "MMMM DD, YYYY h:mm:ss zz"
                )}{" "}
                {/* EST */}
              </p>
            </div>
            <p className="tw-text-right tw-text-gray-500">
              {`$${parseFloat(disputeData.amount)
                  .toFixed(2)
                  .toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}`}
            </p>
          </div>

          <hr className="tw-col-span-full" />

          <div className="tw-grid tw-grid-cols-2 mt-3 d-flex align-items-center justify-content-between">
            
          <p className="tw-font-medium tw-text-black">Amount Paid</p>
          
            <p className="tw-font-medium tw-text-black tw-text-right">
            {`$${disputeData.amount &&
                                parseFloat(disputeData.amount).toFixed(2).toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                })}`}
            </p>
          </div>
          
          {/* <p className="tw-mb-1">Reservation Name: {disputeData.userId.name}</p>
          <p className="tw-mb-1">Dispute Status: {disputeData.status}</p> */}
        </div>
      </>
    );
  };

  return (
    <>
      <div id="main-wrapper">
        <ToastContainer />
        <TopBar user={decoded} showSearch={false} title="Dispute Details" />

        <div className="deznav">
          <div className="deznav-scroll">
            <Navbar />
          </div>
        </div>

        <div className="content-body">
          <div className="container-fluid">
            {editDisputeModal && (
              <CustomModal>
                <div className="modal-content tw-max-h-[100vh] md:tw-max-h-[95vh]   tw-overflow-scroll">
                  <div className="modal-header tw-sticky tw-top-0 tw-right-0 tw-left-0 tw-bg-white tw-z-10  ">
                    <h5 className="modal-title">Manage Settlement</h5>
                    <button
                      type="button"
                      className="tw-bg-transparent tw-border-0"
                      onClick={toggleEditDisputeModal}
                    >
                      <span className="tw-cursor-pointer">
                        <AiOutlinePlus
                          size={24}
                          className="tw-transform tw-rotate-45"
                        />
                      </span>
                    </button>
                  </div>
                  <form onSubmit={handleDisputeSettlement}>
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-lg-12 mb-2">
                          <div className="mb-3">
                            <label className="text-label form-label">
                              Amount
                            </label>
                            <input
                              max={disputeData.securityDeposite}
                              min={0}
                              step="0.01"
                              name="amount"
                              type="number"
                              className="form-control"
                              required
                            />
                          </div>
                          <div className="mb-3">
                            <label className="text-label form-label">
                              Reason
                            </label>
                            <textarea
                              ref={reasonRef}
                              rows={10}
                              type="text"
                              name="message"
                              className="form-control"
                              required
                            ></textarea>
                          </div>
                          <button
                            type="submit"
                            className="btn btn-secondary mb-3 tw-w-max"
                          >
                            {isRequestingSettlement ? "Please wait.." : "Send"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </CustomModal>
            )}
            <div>
              {fetching && (
                <div className="tw-my-10 tw-flex tw-items-center tw-justify-center">
                  <div className="bg-white tw-w-max rounded shadow-md p-4">
                    <div className="loader"></div>
                  </div>
                </div>
              )}
            </div>
            {!fetching && (
              <div>
                <div className="tw-grid tw-grid-cols-2 dispute-detail spacing-dtail lg:tw-grid-cols-3 tw-text-sm py-4">
                  <div>
                    <label className="tw-text-gray-800 tw-mb-1 tw-font-medium">
                      Dispute Id
                    </label>
                    <p className="tw-text-700">{disputeData._id}</p>
                  </div>
                  <div>
                    <label className="tw-text-gray-800 tw-mb-1 tw-font-medium">
                      Reservation Id
                    </label>
                    <p className="tw-text-700">{disputeData.originalBookingId}</p>
                  </div>
                  <div>
                    <label className="tw-text-gray-800 tw-mb-1 tw-font-medium">
                      User Name
                    </label>
                    <p className="tw-text-700">{disputeData.userId.name}</p>
                  </div>
                  <div>
                    <label className="tw-text-gray-800 tw-mb-1 tw-font-medium">
                      Security Deposit
                    </label>
                    <p className="tw-text-700">
                      {disputeData.securityDeposite.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}
                    </p>
                  </div>
                  <div>
                    <label className="tw-text-gray-800 tw-mb-1 tw-font-medium">
                      Payment Status
                    </label>
                    <p className="tw-text-700">
                      <span className="tw-p-2 tw-py-1 tw-text-xs tw-rounded tw-bg-primary tw-text-white">
                        {disputeData.isPaid ? "Paid" : "Not Paid"}
                      </span>
                    </p>
                  </div>
                  <div>
                    <label className="tw-text-gray-800 tw-mb-1 tw-font-medium">
                      Status
                    </label>
                    <p className="tw-text-700">
                      <span className="tw-p-2 tw-py-1 tw-text-xs tw-rounded tw-bg-primary tw-text-white">
                        {disputeData.status}
                      </span>
                    </p>
                  </div>
                  <div>
                    <label className="tw-text-gray-800 tw-mb-1 tw-font-medium">
                      Admin Involved
                    </label>
                    <p className="tw-text-700">
                      <span className="tw-p-2 tw-py-1 tw-text-xs tw-rounded tw-bg-primary tw-text-white">
                        {disputeData.isAdminInvolved ? "Yes" : "No"}
                      </span>
                    </p>
                  </div>

                  {/* Show disput receipt for host code added */}
                  {/* Change added on 09-11-2022 */}
                  {disputeData.status === "Closed" && <div>
                    <label className="tw-text-gray-800 tw-mb-1 tw-font-medium">
                      Action
                    </label>
                    <p
                      className="tw-text-700"
                      onClick={() => {
                        setDisputeId(disputeData._id);
                      }}
                    >
                      <span className="tw-pr-4 tw-cursor-pointer tw-text-primary">
                        View Dispute Receipt
                      </span>
                    </p>
                  </div>}
                </div>
                <div>
                  <div className="tw-text-xs">
                    <p className="tw-text-700 tw-bg-gray-100 tw-p-2">
                      <span className="tw-font-medium tw-block tw-text-gray-700 ">
                        You{" "}
                        <span className="tw-text-gray-500 tw-font-normal ">
                          {/* {moment(disputeData.createdAt).fromNow()} */}
                        </span>
                      </span>

                      <span>
                        <span className="tw-text-gray-600 ">Amount:</span>{" "}
                        {parseFloat(
                          disputeData.securityDeposite
                        ).toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })}
                      </span>
                      <span className="tw-block">
                        <span className="tw-text-gray-600 ">Reason:</span>{" "}
                        {disputeData.message}
                      </span>
                    </p>

                    {disputeData.conversation.map((curr, id) => {
                      return (
                        <div
                          key={id}
                          className="tw-text-700 tw-bg-gray-100 tw-p-2 tw-mb-2"
                        >
                          <span className="tw-font-medium tw-block tw-text-gray-700 ">
                            {curr.type === "Host"
                              ? "You"
                              : curr.type === "Guest"
                              ? disputeData.userId.name
                              : "Admin"}{" "}
                          </span>
                          {curr.type !== "Admin" && (
                            <span>
                              <span className="tw-text-gray-600 ">Amount:</span>{" "}
                              {parseFloat(curr.amount).toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              })}
                            </span>
                          )}
                          <span className="tw-block">
                            <span className="tw-text-gray-600 ">
                              {curr.type === "Admin" ? "Message:" : "Reason:"}
                            </span>{" "}
                            {curr.message}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </div>

                {disputeData.status === "Active" && (
                  <div className="flex tw-space-x-2 tw-mb-2">
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={toggleEditDisputeModal}
                    >
                      Request Again
                    </button>

                    {renderAcceptDisputeButton()}

                    <button
                      onClick={rejectDispute}
                      className="btn btn-sm tw-bg-red-600 tw-text-white"
                    >
                      {rejectingDisputeApi
                        ? "Please wait..."
                        : "Cancel Dispute"}
                    </button>
                    {!disputeData.isAdminInvolved && (
                      <button
                        onClick={involveAdmin}
                        className="btn btn-sm tw-bg-green-600 tw-text-white"
                      >
                        {involvingAdmin ? "Please wait..." : "Involve Admin"}
                      </button>
                    )}
                  </div>
                )}
                {disputeData.status === "Rejected" && (
                  <p className="text-sm tw-font-medium tw-text-gray-700">
                    *Dispute has been Cancelled at{" "}
                    {disputeData.amount.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                  </p>
                )}
                {disputeData.status === "Accepted" && (
                  <p className="text-sm tw-font-medium tw-text-gray-700">
                    *Dispute has been Accepted at{" "}
                    {disputeData.amount.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                  </p>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Show disput receipt for host code added */}
      {/* Change added on 09-11-2022 */}
      <div className="pb-4">
        {disputeId && (
          <ReceiptModal className="w-full  " onClose={() => setDisputeId(null)}>
            {disputeData ? (
              <ViewDisputeReceipt
                disputeId={disputeId}
                onClose={() => setDisputeId(null)}
              />
            ) : null}
          </ReceiptModal>
        )}
      </div>
    </>
  );
}

export default DisputeDetails;
