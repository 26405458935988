import React, { useEffect, useState, useRef } from "react";
import CustomModal from "./../ReactModal";
import { AiOutlineMail } from "react-icons/ai";
import { IoMdMail, IoAirplaneSharp, IoIosAirplane } from "react-icons/io";
import { IoIosClose as CloseIcon } from "react-icons/io";
import { MdPermContactCalendar, MdPhoneIphone } from "react-icons/md";
import { AiOutlinePlus } from "react-icons/ai";
import { FiCheck } from "react-icons/fi";
import { FcGoogle } from "react-icons/fc";
import { BsFacebook } from "react-icons/bs";
import { AiFillApple } from "react-icons/ai";
import OtpInput from "react-otp-input";
import { userService } from "../../_services";
import { toast } from "react-toastify";
import Persona from "persona";
import constant from "../../_config/constant";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

const OTPInput = React.forwardRef((props, ref) => {
  const [value, setValue] = useState(value);
  return (
    <React.Fragment>
      <OtpInput
        value={value}
        onChange={(e) => {
          setValue(e);
        }}
        containerStyle="tw-flex tw-items-center tw-justify-center tw-space-x-4 tw-my-10"
        inputStyle="tw-h-10  tw-w-10 tw-border-0 tw-ring-1 tw-ring-gray-600 tw-bg-white tw-rounded tw-p-0"
        separator={<span>{"  "}</span>}
        numInputs={4}
      />
      <input
        type="text"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        className="tw-hidden"
        ref={ref}
      />
    </React.Fragment>
  );
});

function SocialCard({
  sending,
  name,
  icon,
  label,
  verified,
  Popup,
  modalId,
  send,
  openModal,
  toggleModal,
  modalState,
  phoneVerification,
  togglePhoneModal,
}) {
  return (
    <div className="tw-shadow-md tw-rounded-md tw-flex tw-items-center tw-justify-center tw-p-4 md:tw-p-10 tw-flex-col">
      <span className="h-12 w-12 flex-center ring-1 ring-primary-blue rounded-full p-3 ">
        {icon}
      </span>
      <p className="tw-text-sm tw-text-gray-700 tw-text-center tw-my-4">
        {label}
      </p>

      {Popup && !toggleModal && <Popup id={modalId} />}

      {modalState && <Popup id={modalId} />}

      {verified ? (
        <button className="tw-border-0 tw-flex tw-items-center tw-ring-1 tw-ring-green-600 tw-justify-start tw-mb-2 tw-rounded-full tw-bg-white tw-text-green-600 tw-text-xs md:tw-text-sm tw-pr-2 ">
          <span className="tw-ring-1 tw-ring-green-600 tw-bg-green-600 tw-h-8 tw-w-8 tw-mr-2 tw-rounded-full  tw-flex tw-items-center tw-justify-center">
            <FiCheck size={18} className="tw-text-white" />
          </span>
          Verified
        </button>
      ) : (
        <React.Fragment>
          <button className="tw-border-0 tw-flex tw-items-center tw-ring-1 tw-ring-red-600 tw-justify-start tw-mb-2 tw-rounded-full tw-bg-white tw-text-red-600 tw-text-xs md:tw-text-sm tw-pr-2 ">
            <span className="tw-ring-1 tw-ring-red-600 tw-bg-red-600 tw-h-8 tw-w-8 tw-mr-2 tw-rounded-full  tw-flex tw-items-center tw-justify-center">
              <AiOutlinePlus
                className="tw-text-white tw-transform tw-rotate-45"
                size={22}
              />
            </span>
            Not Verified
          </button>
          {/* {!send && !openModal && !toggleModal && (
            <button
              data-bs-toggle="modal"
              data-bs-target={`.${modalId}`}
              className="tw-p-2 tw-mb-2 tw-rounded-full tw-bg-primary tw-text-white tw-text-sm  tw-px-4 tw-border-0  "
            >
              {name}
            </button>
          )} */}
          {toggleModal && (
            <button
              onClick={openModal}
              className="tw-p-2 tw-mb-2 tw-rounded-full tw-bg-primary tw-text-white tw-text-xs md:tw-text-sm  tw-px-4 tw-border-0  "
            >
              {name}
            </button>
          )}
          {/* {!send && togglePhoneModal && (
            <button
              onClick={togglePhoneModal}
              // data-bs-toggle="modal"
              // data-bs-target={`.${modalId}`}
              className="tw-p-2 tw-mb-2 tw-rounded-full tw-bg-primary tw-text-white tw-text-sm  tw-px-4 tw-border-0  "
            >
              {name}
            </button>
          )} */}

          {send && (
            <button
              onClick={send}
              className="tw-p-2 tw-mb-2 tw-rounded-full tw-bg-primary tw-text-white  tw-text-xs md:tw-text-sm  tw-px-4 tw-border-0  "
            >
              {sending ? "Sending.." : name}
            </button>
          )}
        </React.Fragment>
      )}
    </div>
  );
}

function VerifyDetails() {
  const emailRef = useRef();
  const airlineRef = useRef();
  const otpRef = useRef();
  const emailOtpRef = useRef();
  const [mobileOTP, setMobileOTP] = useState("");
  const [host, setHost] = useState({});
  const [flag, setFlag] = useState(false);
  const [flag2, setFlag2] = useState(false);
  const [phoneVerification, setPhoneVerificationStatus] = useState(false);
  const [emailVerification, setEmailVerificationStatus] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);
  const [user, setUser] = useState({
    facebookAuthentication: false,
    googleAuthentication: false,
    email: "",
  });
  const [emailModal, setEmailModal] = useState(false);
  const [personaModal, setPersonaModal] = useState(false);
  const [phoneModal, setPhoneModal] = useState(false);

  const togglePersonaModal = () => {
    setPersonaModal(!personaModal);
  };

  const togglePhoneModal = () => {
    setPhoneModal(!phoneModal);
  };

  const toggleEmailModal = () => {
    setEmailModal(!emailModal);
  };

  const sendPersonalEmailVerificationLink = () => {
    if (!host.personalEmail) {
      toast.info("Please update personal email in profile section.");
      return;
    }
    setFlag(true);
    userService
      .sendMailVerificationLink({
        emailType: "personal",
        emailId: host.personalEmail,
      })
      .then(
        (objS) => {
          if (objS.status) {
            toast.success(objS.message);
          } else {
            toast.error(objS.message);
          }
          setFlag(false);
          setPersonaModal(false);
        },
        (error) => {
          setFlag(false);
          toast.error(error.message);
          setPersonaModal(false);
        }
      );
  };

  const sendAirlineEmailVerificationLink = () => {
    if (!host.airlineEmail) {
      toast.info("Please update airline email in profile section.");
      return;
    }
    setFlag2(true);
    userService
      .sendMailVerificationLink({
        emailType: "airline",
        emailId: host.airlineEmail,
      })
      .then(
        (objS) => {
          if (objS.status) {
            toast.success(objS.message);
          } else {
            toast.error(objS.message);
          }
          setFlag2(false);
        },
        (error) => {
          setFlag2(false);
        }
      );
  };

  const updateIdVerificationStatus = () => {
    userService.updateIdVerificationStatus().then((objS) => {
      if (objS.status) {
        toast.success(objS.message);
        setRefreshKey((oldKey) => oldKey + 1);
      } else {
        toast.error(objS.message);
      }
    });
  };

  const resendMobileOtp = () => {
    userService.sendVerifyPhoneOtp().then((objS) => {
      if (objS.status) {
        toast.success(objS.message, { zIndex: 9999 });
      } else {
        toast.error(objS.message, { zIndex: 9999 });
      }
    });
  };

  const resendEmailOtp = async () => {
    try {
      const res = await userService.sendVerifyEmailOtp();
      if (res.status) {
        toast.success("OTP sent successfully!", { zIndex: 9999 });
      } else toast.error(res.message);
    } catch {
      toast.error("Something went wrong!", { zIndex: 9999 });
    }
  };

  const openModal = () => {
    window.$("#mobile-verification").modal("show");
    // window.$(".modal-backdrop").remove();
    togglePhoneModal();
    resendMobileOtp();
  };

  const verifyOtp = (otp) => {
    if (otp.length < 3) return;
    setPhoneVerificationStatus(true);
    userService.verifyPhone({ otp }).then((objS) => {
      if (objS.status) {
        toast.success(objS.message);
        togglePhoneModal();
        // window.$("#addRoomModal").modal("hide");
        // window.$(".modal-backdrop").remove();
        setPhoneVerificationStatus(false);
        setRefreshKey((oldKey) => oldKey + 1);
      } else {
        toast.error(objS.message);
        setPhoneVerificationStatus(false);
        return;
      }
    });
  };

  const verifyEmailOtp = async (otp) => {
    try {
      setEmailVerificationStatus(true);
      const res = await userService.verifyEmail({ otp });

      setEmailVerificationStatus(false);
      if (res.status) {
        setRefreshKey((oldKey) => oldKey + 1);
        toggleEmailModal();
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      toast.error("Something went wrong!");
      setEmailVerificationStatus(false);
    }
  };

  async function handleSocialAccountConnection(data) {
    if (user.email === data.email) {
      const res = await userService.updateSocialAuthenticationStatus({
        socialType: data.authType,
        socialId: data.id,
      });

      if (res.status) {
        toast.success(res.message);
        const obj = { ...user };
        obj[data.dataKey] = 1;
        localStorage.setItem(constant.DATA_KEY, JSON.stringify(obj));
        setUser({ ...user, [data.dataKey]: 1 });
      } else toast.error(res.message);
    } else {
      toast.error(
        "Social ID cannot be connected with any other email or mobile!"
      );
      return;
    }
  }

  useEffect(() => {
    userService.getDetail({}).then((objS) => {
      if (objS.status) {
        setHost(objS.data);
      }
    });
  }, [refreshKey]);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem(constant.DATA_KEY));
    setUser(user);
  }, []);

  return (
    <div className="tw-my-10">
      <div className="tw-flex tw-items-center tw-p-4 tw-mb-4 tw-bg-gray-100 tw-rounded">
        <BsFacebook size={32} className="tw-text-blue-500" />
        <p className="tw-text-sm tw-m-0 tw-text-primary-blue tw-font-medium tw-mx-4">
          Facebook
        </p>
        <p className="tw-text-gray-400 tw-m-0 tw-text-sm tw-mr-4 tw-ml-auto">
          {user.facebookAuthentication ? (
            <span className="text-secondary">Connected</span>
          ) : (
            "Not Connected"
          )}
        </p>
        {!user.facebookAuthentication && (
          <FacebookLogin
            appId={constant.FACEBOOK_APPID}
            callback={(e) => {
              if (e.email) {
                handleSocialAccountConnection({
                  email: e.email,
                  firstName: e.name.split(" ")[0],
                  lastName: e.name.split(" ")[1],
                  authType: "Facebook",
                  id: e.id,
                  dataKey: "facebookAuthentication",
                });
              } else toast.error("Something went wrong!");
            }}
            fields="name,email,picture,gender,birthday"
            render={(renderProps) => (
              <button
                onClick={renderProps.onClick}
                className="tw-border-0 tw-p-2 tw-px-2 tw-rounded-md tw-text-white tw-bg-[#23426e] tw-text-xs"
              >
                Connect Now
              </button>
            )}
          />
        )}
      </div>
      <div className="tw-flex tw-items-center tw-p-4 tw-mb-4 tw-bg-gray-100 tw-rounded">
        <FcGoogle size={32} className="text-blue-500" />
        <p className="tw-text-sm tw-m-0 tw-text-primary-blue tw-font-medium tw-mx-4">
          Google
        </p>
        <p className="tw-text-gray-400 tw-m-0 tw-text-sm tw-mr-4 tw-ml-auto">
          {user.googleAuthentication ? (
            <span className="text-secondary">Connected</span>
          ) : (
            "Not Connected"
          )}
        </p>
        {!user.googleAuthentication && (
          <GoogleLogin
            clientId={constant.GOOGLE_CLIENTID}
            onSuccess={(e) => {
              if (e.profileObj.email) {
                handleSocialAccountConnection({
                  email: e.profileObj.email,
                  firstName: e.profileObj.givenName,
                  lastName: e.profileObj.familyName,
                  id: e.profileObj.googleId,
                  authType: "Google",
                  dataKey: "googleAuthentication",
                });
              } else toast.error("Something went wrong!");
            }}
            onFailure={(e) => {}}
            render={(renderProps) => (
              <button
                onClick={renderProps.onClick}
                className="tw-border-0 tw-p-2 tw-px-2 tw-rounded-md tw-text-white tw-bg-[#23426e] tw-text-xs"
              >
                Connect Now
              </button>
            )}
          />
        )}
      </div>
      {/* <div className="tw-flex tw-items-center tw-p-4 tw-mb-4 tw-bg-gray-100 tw-rounded">
        <AiFillApple size={32} />

        <p className="tw-text-sm tw-m-0 tw-text-primary-blue tw-font-medium tw-mx-4">
          Apple
        </p>
        <p className="tw-text-gray-400 tw-m-0 tw-text-sm tw-mr-4 tw-ml-auto">
          Not Connected
        </p>
        <button className="tw-border-0 tw-p-2 tw-px-2 tw-rounded-md tw-text-white tw-bg-[#23426e] tw-text-xs">
          Connect Now
        </button>
      </div> */}

      <div className="tw-grid tw-grid-cols-2 lg:tw-grid-cols-4 tw-gap-4 md:tw-gap-x-10 tw-my-10">
        <SocialCard
          name="Send Otp"
          icon={<IoMdMail size={30} className="tw-text-gray-800" />}
          label="Personal Email Verification"
          modalId="email-verification"
          verified={host ? host.personalEmailVerification : false}
          openModal={() => {
            toggleEmailModal();
            resendEmailOtp();
          }}
          toggleModal={toggleEmailModal}
          modalState={emailModal}
          Popup={function({ id }) {
            return (
              <React.Fragment>
                <CustomModal>
                  <div className="tw-w-[95%] mb-4 md:mb-0 md:tw-w-[85%] tw-mx-auto ">
                    <span
                      onClick={toggleEmailModal}
                      className="tw-rounded-full tw-cursor-pointer tw-flex tw-justify-center tw-items-center tw-bg-white tw-p-2 tw-ml-auto tw-text-gray-700 tw-h-12 tw-w-12"
                    >
                      <AiOutlinePlus
                        size={22}
                        className="tw-transform tw-rotate-45"
                      />
                    </span>
                  </div>
                  <div className="tw-bg-white tw-w-[95%] md:tw-w-[75%] tw-mx-auto tw-p-10 tw-rounded-3xl tw-border-2 ">
                    <div>
                      <h3 className="tw-text-lg tw-text-center tw-font-medium tw-text-gray-700">
                        Enter OTP
                      </h3>
                      <p className="tw-text-gray-700 tw-text-center tw-text-sm">
                        Enter the OTP received on your email
                      </p>
                    </div>
                    <OTPInput ref={emailOtpRef} />
                    <div className="tw-text-sm tw-text-center">
                      <button
                        onClick={() => {
                          verifyEmailOtp(emailOtpRef.current.value);
                        }}
                        disabled={emailVerification}
                        // data-bs-dismiss="modal"
                        className="tw-w-max tw-text-center tw-mx-auto tw-col-span-full tw-text-sm  tw-p-2 tw-px-4 tw-rounded tw-bg-primary tw-border-0 tw-text-white "
                      >
                        {emailVerification ? "Verfing.." : "Verify"}
                      </button>
                    </div>
                    <p
                      onClick={resendEmailOtp}
                      className="tw-text-sm tw-text-center tw-my-2 tw-text-primary tw-cursor-pointer"
                    >
                      Resend OTP
                    </p>
                  </div>
                </CustomModal>
              </React.Fragment>
              // <React.Fragment>
              //   <div
              //     className={`modal fade ${id}`}
              //     tabindex="-1"
              //     role="dialog"
              //     aria-hidden="true"
              //     id="mobile-verification"
              //   >
              //     <div class="modal-dialog modal-lg">
              //       <div class="modal-content">
              //         <div class="modal-body tw-flex tw-items-center tw-justify-center tw-flex-col">
              //           <div>
              //             <h3 className="tw-text-lg tw-text-center tw-font-medium tw-text-gray-700">
              //               Enter OTP
              //             </h3>
              //             <p className="tw-text-gray-700 tw-text-center tw-text-sm">
              //               Enter the OTP received on your mobile number
              //             </p>
              //           </div>
              //           <OTPInput ref={otpRef} />

              //           <button
              //             onClick={() => {
              //               verifyOtp(otpRef.current.value);
              //             }}
              //             disabled={phoneVerification}
              //             // data-bs-dismiss="modal"
              //             className="tw-w-max  tw-col-span-full tw-text-sm tw-mx-auto tw-p-2 tw-px-4 tw-rounded tw-bg-primary tw-border-0 tw-text-white "
              //           >
              //             {phoneVerification ? "Verfing.." : "Verify"}
              //           </button>
              //           <p
              //             onClick={resendMobileOtp}
              //             className="tw-text-sm tw-text-center tw-my-2 tw-text-primary tw-cursor-pointer"
              //           >
              //             Resend OTP
              //           </p>
              //         </div>
              //       </div>
              //     </div>
              //   </div>
              // </React.Fragment>
            );
          }}
        />
        <SocialCard
          name="Send Link"
          sending={flag2}
          send={sendAirlineEmailVerificationLink}
          icon={
            <IoIosAirplane
              size={30}
              className="tw-text-gray-800 tw-transform --tw-rotate-45"
            />
          }
          verified={host ? host.airlineEmailVerification : false}
          label="Airline Email Verification"
          modalId="airline-email"
          Popup={function({ id }) {
            return (
              <React.Fragment>
                {/* <div
                  className={`modal fade ${id}`}
                  tabindex="-1"
                  role="dialog"
                  aria-hidden="true"
                >
                  <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                      <div class="modal-body tw-flex tw-items-center tw-justify-center tw-flex-col">
                        <div>
                          <h3 className="tw-text-lg tw-text-center tw-font-medium tw-text-gray-700">
                            Enter Your Airline Email
                          </h3>
                          <p className="tw-text-gray-700 tw-text-center tw-text-sm">
                            Please check your email for verification link
                          </p>
                        </div>

                        <TextInput
                          ref={airlineRef}
                          type="email"
                          field="email"
                          placeholder="johndue@nn.com"
                          size={80}
                        />
                        <button
                          data-bs-dismiss="modal"
                          className="tw-w-max tw-mx-auto tw-col-span-full tw-text-sm tw-mx-auto tw-p-2 tw-px-4 tw-rounded tw-bg-primary tw-border-0 tw-text-white "
                        >
                          Send Link
                        </button>
                      </div>
                    </div>
                  </div>
                </div> */}
              </React.Fragment>
            );
          }}
        />
        <SocialCard
          name="Send Otp"
          icon={<MdPhoneIphone size={30} className="tw-text-gray-800" />}
          label="Mobile Number Verification"
          modalId="mobile-verification"
          verified={host ? host.phoneVerification : false}
          openModal={openModal}
          toggleModal={togglePhoneModal}
          modalState={phoneModal}
          Popup={function({ id }) {
            return (
              <React.Fragment>
                <CustomModal>
                  <div className="tw-w-[95%] mb-4 md:mb-0 md:tw-w-[85%] tw-mx-auto ">
                    <span
                      onClick={togglePhoneModal}
                      className="tw-rounded-full tw-cursor-pointer tw-flex tw-justify-center tw-items-center tw-bg-white tw-p-2 t tw-ml-auto tw-text-gray-700 tw-h-12 tw-w-12"
                    >
                      <AiOutlinePlus
                        size={22}
                        className="tw-transform tw-rotate-45"
                      />
                    </span>
                  </div>
                  <div className="tw-bg-white tw-w-[95%] md:tw-w-[75%] tw-mx-auto tw-p-10 tw-rounded-3xl tw-border-2 ">
                    <div>
                      <h3 className="tw-text-lg tw-text-center tw-font-medium tw-text-gray-700">
                        Enter OTP
                      </h3>
                      <p className="tw-text-gray-700 tw-text-center tw-text-sm">
                        Enter the OTP received on your mobile number
                      </p>
                    </div>
                    <OTPInput ref={otpRef} />
                    <div className="tw-text-sm tw-text-center">
                      <button
                        onClick={() => {
                          verifyOtp(otpRef.current.value);
                        }}
                        disabled={phoneVerification}
                        // data-bs-dismiss="modal"
                        className="tw-w-max tw-text-center tw-mx-auto tw-col-span-full tw-text-sm tw-mx-auto tw-p-2 tw-px-4 tw-rounded tw-bg-primary tw-border-0 tw-text-white "
                      >
                        {phoneVerification ? "Verfing.." : "Verify"}
                      </button>
                    </div>
                    <p
                      onClick={resendMobileOtp}
                      className="tw-text-sm tw-text-center tw-my-2 tw-text-primary tw-cursor-pointer"
                    >
                      Resend OTP
                    </p>
                  </div>
                </CustomModal>
              </React.Fragment>
              // <React.Fragment>
              //   <div
              //     className={`modal fade ${id}`}
              //     tabindex="-1"
              //     role="dialog"
              //     aria-hidden="true"
              //     id="mobile-verification"
              //   >
              //     <div class="modal-dialog modal-lg">
              //       <div class="modal-content">
              //         <div class="modal-body tw-flex tw-items-center tw-justify-center tw-flex-col">
              //           <div>
              //             <h3 className="tw-text-lg tw-text-center tw-font-medium tw-text-gray-700">
              //               Enter OTP
              //             </h3>
              //             <p className="tw-text-gray-700 tw-text-center tw-text-sm">
              //               Enter the OTP received on your mobile number
              //             </p>
              //           </div>
              //           <OTPInput ref={otpRef} />

              //           <button
              //             onClick={() => {
              //               verifyOtp(otpRef.current.value);
              //             }}
              //             disabled={phoneVerification}
              //             // data-bs-dismiss="modal"
              //             className="tw-w-max tw-mx-auto tw-col-span-full tw-text-sm tw-mx-auto tw-p-2 tw-px-4 tw-rounded tw-bg-primary tw-border-0 tw-text-white "
              //           >
              //             {phoneVerification ? "Verfing.." : "Verify"}
              //           </button>
              //           <p
              //             onClick={resendMobileOtp}
              //             className="tw-text-sm tw-text-center tw-my-2 tw-text-primary tw-cursor-pointer"
              //           >
              //             Resend OTP
              //           </p>
              //         </div>
              //       </div>
              //     </div>
              //   </div>
              // </React.Fragment>
            );
          }}
        />
        <SocialCard
          name="Verify Now"
          icon={
            <MdPermContactCalendar size={30} className="tw-text-gray-800" />
          }
          label="Selfie & Id Verification"
          verified={host ? host.idVerificationStatus : false}
          // verified={false}
          modalId="id-verification"
          openModal={togglePersonaModal}
          toggleModal={togglePersonaModal}
          modalState={personaModal}
          Popup={function({ id }) {
            return (
              <React.Fragment>
                <CustomModal>
                  <div className=" tw-w-[95%] lg:tw-w-[85%] tw-mb-4 md:tw-mb-0 tw-mx-auto ">
                    <span
                      onClick={togglePersonaModal}
                      className="tw-rounded-full tw-cursor-pointer tw-flex tw-justify-center tw-items-center tw-bg-white tw-p-2  tw-ml-auto tw-text-gray-700 tw-h-12 tw-w-12"
                    >
                      <AiOutlinePlus
                        size={22}
                        className="tw-transform tw-rotate-45"
                      />
                    </span>
                  </div>
                  <div className="tw-bg-white tw-w-[95%] lg:tw-w-[75%] tw-mx-auto tw-p-2 md:tw-p-10 tw-rounded-3xl tw-border-2 ">
                    <Persona.Inquiry
                      referenceId={host.id}
                      templateId={constant.persona_template_id}
                      environment={constant.persona_mode}
                      onLoad={() => {}}
                      // onEvent={(e, meta) => {

                      // }}
                      onComplete={({ inquiryId, status, fields, ...e }) => {
                        // Inquiry completed. Optionally tell your server about it.

                        if (status === "completed") {
                          updateIdVerificationStatus();
                          setPersonaModal(false);
                        }
                      }}
                    />
                  </div>
                </CustomModal>
              </React.Fragment>
            );
          }}
        />
      </div>
    </div>
  );
}

export default VerifyDetails;
