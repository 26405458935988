import React, { useEffect } from "react";
import Navbar from "./../../../components/Navbar";
import TopBar from "./../../../components/topbar";
import constant from "./../../../_config/constant";
import { NavLink, Route, Switch, useParams } from "react-router-dom";
import BookingDetails from "./bookingDetails";
import PropertyDetails from "./PropertyDetails";
import PayoutSchedule from "./payoutSchedule";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { propertyService } from "./../../../_services";
import { useState } from "react";

const COLD = "Cold bed";

function ReservationDetails() {
  const [refreshKey, setRefreshkey] = useState(0);
  const [bookingdetails, setBookingDetails] = useState({
    id: "sskos",
    propertyId: 93,
    totalPrice: 466,
    checkInDate: "",
    checkOutDate: "",
  });
  const [fetching, setFetching] = useState(false);
  const params = useParams();

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        setFetching(true);
        const res = await propertyService.bookingDetail(params.id);

        setBookingDetails(res.data);
      } catch (error) {
        toast.error("Something went wrong!");
      } finally {
        setFetching(false);
      }
    };
    fetchDetails();
  }, [params.id, refreshKey]);

  var data = localStorage.getItem(constant.DATA_KEY);
  const decoded = data ? JSON.parse(data) : {};
  return (
    <div id="main-wrapper">
      <ToastContainer />
      <TopBar user={decoded} showSearch={false} title="Reservation Details" />

      <div className="deznav">
        <div className="deznav-scroll">
          <Navbar />
        </div>
      </div>

      <div className="content-body">
        <div className="container-fluid">
          <div className="tw-flex tw-gap-2  tw-items-center tw-text-sm tw-border-0 tw-border-b  tw-border-gray-200 tw-border-solid ">
            <NavLink
              exact
              to={`/reservation/${bookingdetails._id}`}
              className="tw-rounded-t-sm"
              activeClassName=" tw-bg-primary tw-text-white  "
            >
              <span className="tw-p-2 tw-cursor-pointer tw-block">
                Booking Details
              </span>
            </NavLink>
            <NavLink
              exact
              to={`/reservation/${bookingdetails._id}/property`}
              className="tw-rounded-t-sm"
              activeClassName=" tw-bg-primary tw-text-white "
            >
              <span className="tw-p-2 tw-cursor-pointer  tw-block">
                Property Details
              </span>
            </NavLink>
            {/* <NavLink
              exact
              to={`/reservation/${bookingdetails._id}/payout-schedule`}
              className="tw-rounded-t-sm"
              activeClassName=" tw-bg-primary tw-text-white "
            >
              <span className="tw-p-2 tw-cursor-pointer  tw-block">
                Payout Schedule
              </span>
            </NavLink> */}
          </div>
          <div>
            {fetching && (
              <div className="tw-my-10 tw-flex tw-items-center tw-justify-center">
                <div className="bg-white tw-w-max rounded shadow-md p-4">
                  <div className="loader"></div>
                </div>
              </div>
            )}

            {!fetching && (
              <Switch>
                <Route
                  exact
                  path="/reservation/:id"
                  component={() => (
                    <BookingDetails
                      setRefreshkey={setRefreshkey}
                      propertyId={params.id}
                      details={bookingdetails}
                    />
                  )}
                />
                <Route
                  exact
                  path="/reservation/:id/property"
                  component={() => (
                    <PropertyDetails
                      host={bookingdetails.hostId}
                      propertyId={params.id}
                      details={bookingdetails.propertyId}
                    />
                  )}
                />
                <Route
                  exact
                  path="/reservation/:id/payout-schedule"
                  component={() => (
                    <PayoutSchedule
                      host={bookingdetails.hostId}
                      propertyId={params.id}
                      details={bookingdetails}
                    />
                  )}
                />
              </Switch>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReservationDetails;
