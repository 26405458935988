import React, { useState } from "react";

import "react-toastify/dist/ReactToastify.css";

var Modal = require("react-bootstrap-modal");

export const SafetyFeatures = ({
  onNext,
  onPrev,
  formData,
  setFormData,
  className,
  safetyFeatureList,
  save,
  updating,
}) => {
  window.scrollTo({ top: 0, behavior: "smooth" });

  const [safety, setSafety] = useState(formData["safety"] || []);
  const handleStep = (val) => {
    const ind = safety.indexOf(val);
    const updatedAminities = [...safety];
    if (ind > -1) {
      updatedAminities.splice(ind, 1);
    } else {
      updatedAminities.push(val);
    }
    setSafety(updatedAminities);
  };

  const handleNextStep = () => {
    // setFormData((el) => {
    //   return { ...el, safety: [...safety] };
    // });
    if (safety.length) {
      save({ safety });
      onNext();
    }
  };
  return (
    <div className={className}>
      <div className=" tw-min-h-[60vh]">
        <h4 className="mb-2 tw-flex tw-justify-start tw-items-center">
          <span className="tw-bg-[#23426e] tw-mr-2 tw-flex tw-justify-center tw-items-center  tw-h-10 tw-w-10 tw-text-white  tw-rounded-full">
            6
          </span>{" "}
          Safety Features
        </h4>
        <div className=" row my-3">
          {safetyFeatureList.map((data) => (
            <div
              key={data.id}
              className="form-check form-check-inline col-lg-3"
            >
              <label className="form-check-label">
                <input
                  type="checkbox"
                  onChange={(e) => handleStep(e.target.value)}
                  checked={safety.includes(data.id)}
                  className="form-check-input tw-h-5 tw-w-5"
                  value={data.id}
                />
                {data.name}
              </label>
            </div>
          ))}
        </div>
      </div>
      <div>
        <button
          onClick={onPrev}
          type="button"
          className="btn btn-secondary mx-2  tw-mb-2"
        >
          Prev
        </button>

        <button
          disabled={updating}
          onClick={handleNextStep}
          className="btn btn-secondary tw-mb-2"
        >
          {updating ? "Updating ..." : "Save & Next"}
        </button>
      </div>
    </div>
  );
};
