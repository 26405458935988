import React from 'react'
import moment from 'moment'
import { processMediaUrl } from '../../_helpers/utils'

import ClockImage from '../../images/clock.svg'

function ConversationCard({ messageInstance, members }) {
    const member = members[messageInstance.author]
    switch (messageInstance.type) {
        case 'received': {
            return (
                <div
                    style={{
                        margin: '1rem',
                        marginInline: '0.5rem',
                        width: 'max-content',
                        maxWidth: '28rem',
                    }}
                >
                    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                        <img
                            alt=""
                            src={member.profile ? processMediaUrl(member.profile) : '/user.png'}
                            style={{
                                height: '2rem',
                                width: '2rem',
                                borderRadius: '9999px',
                                objectFit: 'cover',
                                marginRight: '0.5rem',
                                flexShrink: 0,
                            }}
                        />
                        <div>
                            <p
                                style={{
                                    fontSize: '0.75rem',
                                    backgroundColor: '#e6ebf5',
                                    padding: '1rem',
                                    color: 'black',
                                    borderRadius: '0.75rem',
                                    borderBottomLeftRadius: 0,
                                    position: 'relative',
                                }}
                            >
                                {messageInstance.text}
                                <span
                                    style={{
                                        display: 'flex',
                                        gap: '0.25rem',
                                        justifyContent: 'flex-end',
                                        fontSize: '12px',
                                        marginTop: '0.5rem',
                                        color: 'black',
                                        alignItems: 'center',
                                    }}
                                >
                                    <img src={ClockImage} alt="Time" style={{ width: '0.75rem', height: '0.75rem' }} />
                                    {moment(messageInstance.timestamp).fromNow()}
                                </span>
                                <span
                                    style={{
                                        width: 0,
                                        height: 0,
                                        borderLeft: 0,
                                        borderRight: '0.5rem solid transparent',
                                        borderTop: '0.5rem solid #e6ebf5',
                                        position: 'absolute',
                                        left: 0,
                                        bottom: '-0.5rem',
                                    }}
                                ></span>
                            </p>
                            <span
                                style={{
                                    display: 'block',
                                    marginTop: '0.5rem',
                                    fontSize: '14px',
                                    fontWeight: '500',
                                    color: '#23426e',
                                }}
                            >
                                {members && members[messageInstance.author] && members[messageInstance.author].name}
                            </span>
                        </div>
                    </div>
                </div>
            )
        }
        case 'sent': {
            return (
                <div
                    style={{
                        margin: '1rem',
                        marginInline: '0.5rem',
                        width: 'max-content',
                        maxWidth: '28rem',
                        marginLeft: 'auto',
                    }}
                >
                    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                        <div>
                            <p
                                style={{
                                    fontSize: '0.75rem',
                                    backgroundColor: '#f5f7fb',
                                    padding: '1rem',
                                    color: 'black',
                                    borderRadius: '0.75rem',
                                    borderBottomRightRadius: 0,
                                    position: 'relative',
                                }}
                            >
                                {messageInstance.text}
                                <span
                                    style={{
                                        display: 'flex',
                                        gap: '0.25rem',
                                        justifyContent: 'flex-end',
                                        fontSize: '12px',
                                        marginTop: '0.5rem',
                                        color: '#6b7280',
                                        alignItems: 'center',
                                    }}
                                >
                                    <img src={ClockImage} alt="Time" style={{ width: '0.75rem', height: '0.75rem' }} />
                                    {moment(messageInstance.timestamp).fromNow()}
                                </span>
                                <span
                                    style={{
                                        width: 0,
                                        height: 0,
                                        borderRight: 0,
                                        borderLeft: '0.5rem solid transparent',
                                        borderTop: '0.5rem solid #f5f7fb',
                                        position: 'absolute',
                                        right: 0,
                                        bottom: '-0.5rem',
                                    }}
                                ></span>
                            </p>
                            <span
                                style={{
                                    display: 'block',
                                    marginTop: '0.5rem',
                                    textAlign: 'right',
                                    fontSize: '14px',
                                    fontWeight: '500',
                                    color: '#23426e',
                                }}
                            >
                                {member.name}
                            </span>
                        </div>
                        <img
                            onError={function({ currentTarget }) {
                                currentTarget.src = '/user.png'
                            }}
                            alt=""
                            src={member.profile ? processMediaUrl(member.profile) : '/user.png'}
                            style={{
                                height: '2rem',
                                width: '2rem',
                                borderRadius: '9999px',
                                objectFit: 'cover',
                                marginLeft: '0.5rem',
                                flexShrink: 0,
                            }}
                        />
                    </div>
                </div>
            )
        }

        default: {
            return <p>Specify chat type</p>
        }
    }
}

export default ConversationCard;