import React, { useState } from "react";

import { AiOutlinePlus } from "react-icons/ai";
import "react-toastify/dist/ReactToastify.css";

export const AddBankAccountForm = ({
  saveBankAccount,
  toggleBankAccountModal,
  bankAdding,
}) => {
  const [accountData, setAccountData] = useState({});

  const setValue = (key, value) => {
    setAccountData((e) => {
      const obj = { ...e };
      obj[key] = value;
      return obj;
    });
  };

  return (
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">Add Your Bank Account</h5>
        <button
          type="button"
          className="tw-bg-transparent tw-border-0"
          onClick={toggleBankAccountModal}
        >
          <span className="tw-cursor-pointer">
            <AiOutlinePlus size={24} className="tw-transform tw-rotate-45" />
          </span>
        </button>
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          saveBankAccount(accountData);
        }}
      >
        <div className="modal-body">
          <div className="row">
            <div className="col-lg-6 mb-2">
              <div className="mb-3">
                <label className="text-label form-label">Currency</label>
                <select
                  name="currency"
                  className="form-control wide"
                  onChange={(e) => setValue("currency", e.target.value)}
                  value={accountData["currency"]}
                  required
                >
                  <option value="">Select</option>
                  <option value="usd">USD</option>
                </select>
              </div>
            </div>
            <div className="col-lg-6 mb-2">
              <div className="mb-3">
                <label className="text-label form-label">Country</label>
                <select
                  name="country"
                  className="form-control wide"
                  onChange={(e) => setValue("country", e.target.value)}
                  value={accountData["country"]}
                  required
                >
                  <option value="">Select</option>
                  <option value="US">USA</option>
                </select>
              </div>
            </div>
            <div className="col-lg-6 mb-2">
              <div className="mb-3">
                <label className="text-label form-label">
                  Account Holder Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Account Holder Name"
                  required
                  name="accountHolderType"
                  onChange={(e) =>
                    setValue("accountHolderName", e.target.value)
                  }
                  value={accountData["accountHolderName"]}
                />
              </div>
            </div>
            <div className="col-lg-6 mb-2">
              <div className="mb-3">
                <label className="text-label form-label">
                  Account Holder Type
                </label>
                <select
                  className="form-control wide"
                  required
                  name="accountHolderType"
                  onChange={(e) =>
                    setValue("accountHolderType", e.target.value)
                  }
                  value={accountData["accountHolderType"]}
                >
                  <option value="">Select</option>
                  <option value="individual">Individual</option>
                  <option value="company">Company</option>
                </select>
              </div>
            </div>
            <div className="col-lg-6 mb-2">
              <div className="mb-3">
                <label className="text-label form-label">Routing Number</label>
                <input
                  type="text"
                  name="routingNumber"
                  className="form-control"
                  placeholder="Routing Number"
                  required
                  onChange={(e) => setValue("routingNumber", e.target.value)}
                  value={accountData["routingNumber"]}
                />
              </div>
            </div>
            <div className="col-lg-6 mb-2">
              <div className="mb-3">
                <label className="text-label form-label">Account Number</label>
                <input
                  type="text"
                  name="accountNumber"
                  className="form-control"
                  placeholder="Account Number"
                  required
                  onChange={(e) => setValue("accountNumber", e.target.value)}
                  value={accountData["accountNumber"]}
                />
              </div>
            </div>
            <div className="col-lg-6 mb-2">
              <div className="mb-3">
                <label className="text-label form-label">
                  Confirm Account Number
                </label>
                <input
                  type="text"
                  name="confirmAccountNumber"
                  className="form-control"
                  placeholder="Confirm Account Number"
                  required
                  onChange={(e) =>
                    setValue("confirmAccountNumber", e.target.value)
                  }
                  value={accountData["confirmAccountNumber"]}
                />
              </div>
            </div>
            <button
              disabled={bankAdding}
              type="submit"
              className="btn btn-secondary mb-3"
            >
              {bankAdding ? "Please wait ..." : "Submit"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
