import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "../Navbar";
import TopBar from "../topbar";
import Footer from "../../layout/footer";
import constant from "../../_config/constant";
import Moment from "react-moment";
import Pagination from "react-js-pagination";
import Tippy from "@tippy.js/react";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { ToastContainer, toast } from "react-toastify";
import { paymentService } from "../../_services";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";

export default function RefundList(props) {
  const [requesting, setRequesting] = useState(true);
  const [list, setList] = useState([]);
  const [refreshKey, setRefreshKey] = useState(0);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  var limit = 10;

  var data = localStorage.getItem(constant.DATA_KEY);
  const decoded = data ? JSON.parse(data) : {};

  const pageChange = (page) => {
    setPage(page);
    setRequesting(true);
    setRefreshKey((oldKey) => oldKey + 1);
  };

  useEffect(() => {
    setRequesting(false);
    paymentService.getRefundList({ page, limit: 10 }).then(
      (objS) => {
        console.clear();

        setList(objS.data.docs);
        setTotal(objS.data.total);
        setRequesting(false);
        // window.scrollTo({ top: 0, behavior: "smooth" });
      },
      (error) => {
        setRequesting(false);
      }
    );
  }, [refreshKey]);

  return (
    <div id="main-wrapper">
      <ToastContainer />
      <TopBar user={decoded} showSearch={false} title="Refund List" />

      <div className="deznav">
        <div className="deznav-scroll">
          <Navbar />
        </div>
      </div>

      <div className="content-body">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12">
              <div className="tab-content">
                <div className="tab-pane active show" id="All">
                  <div className="table-responsive">
                    <table
                      className="table card-table  display mb-4 dataTablesCard booking-table room-list-tbl table-responsive-lg "
                      id="guestTable-all"
                    >
                      <thead>
                        <tr>
                          <th>Refund Id</th>
                          <th>Listing Name</th>
                          <th>Host Name</th>
                          <th>Amount</th>
                          <th>Status</th>
                          <th>Created At</th>
                          <th>Updated At</th>
                          {/* <th>Action</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {list.map((curr, id) => {
                          return (
                            <tr key={id}>
                              <td>{curr.refundId}</td>
                              <td>
                                {curr.bookingId &&
                                  curr.bookingId.propertyId.listingName}
                              </td>
                              <td>{curr.user.name}</td>
                              <td>
                                {curr.refundAmount.toLocaleString("en-US", {
                                  style: "currency",
                                  currency: "USD",
                                })}
                              </td>
                              <td>{curr.status}</td>
                              <td>
                                {moment(curr.createdAt).format("MMMM Do, YYYY")}
                              </td>
                              <td>
                                {moment(curr.updatedAt).format("MMMM Do, YYYY")}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            {requesting && (
              <div className="tw-my-10 tw-flex tw-items-center tw-justify-center">
                <div className="bg-white tw-w-max rounded shadow-md p-4">
                  <div className="loader"></div>
                </div>
              </div>
            )}
          </div>
          <Pagination
            activePage={page}
            itemsCountPerPage={limit}
            totalItemsCount={total}
            pageRangeDisplayed={10}
            onChange={pageChange}
          />
        </div>
      </div>

      <Footer />
    </div>
  );
}
