import React, { useState } from "react";

import { AiOutlineInfoCircle } from "react-icons/ai";
import "react-toastify/dist/ReactToastify.css";

var Modal = require("react-bootstrap-modal");

export const PricingAndFee = ({
  onNext,
  onPrev,
  formData,
  setFormData,
  className,
  saveAsDraft,
  draftRequesting,
}) => {
  const [stepForm, setStepForm] = useState({
    perNightPrice: formData.perNightPrice,
    extraChargePerGuest: formData.extraChargePerGuest,
    perMonthPrice: formData.perMonthPrice,
    cleaningFee: formData.cleaningFee,
    securityDeposite: formData.securityDeposite,
    lineholderDiscountType: formData.lineholderDiscountType,
    lineholderDiscount: formData.lineholderDiscount,
  });
  const handleStep = (tag, value) => {
    setStepForm({ ...stepForm, [tag]: value });
    setFormData({ ...formData, ...stepForm, [tag]: value });
  };
  const handleNextStep = (e) => {
    e.preventDefault();

    setFormData({ ...formData, ...stepForm });
    // if (
    //   stepForm.perNightPrice <= 0 ||
    //   stepForm.perMonthPrice <= 0 ||
    //   stepForm.securityDeposite <= 0 ||
    //   stepForm.extraChargePerGuest <= 0
    // ) {
    //   toast.info("Prices cannot be zero or less.");
    //   return;
    // }
    window.scrollTo({ top: 0, behavior: "smooth" });
    onNext();
  };
  const saveAsdraft = (e) => {
    // if (
    //   stepForm.perNightPrice <= 0 ||
    //   stepForm.perMonthPrice <= 0 ||
    //   stepForm.securityDeposite <= 0 ||
    //   stepForm.extraChargePerGuest <= 0
    // ) {
    //   toast.info("Prices cannot be zero or less.");
    //   return;
    // }
    setFormData({ ...formData, ...stepForm });
    saveAsDraft();
  };
  return (
    <form onSubmit={handleNextStep}>
      <div className={className}>
        <div>
          <h4 className="mb-5 tw-flex tw-justify-start tw-items-center">
            <span className="tw-bg-[#23426e] tw-mr-2 tw-flex tw-justify-center tw-items-center  tw-h-10 tw-w-10 tw-text-white  tw-rounded-full">
              8
            </span>{" "}
            Pricing and Fees
          </h4>
          <div className="col-lg-6 mb-2"></div>
          <div className="row ">
            {formData.rentalType === "Entire Place" &&
              (formData.bookingType === "Hot bed" ||
                formData.bookingType === "Flexible") && (
                <div className="col-lg-6 mb-2">
                  <div>
                    <label className="form-label"> Per Night Price</label>
                    <input
                      onChange={(e) => {
                        if (
                          parseInt(e.target.value) >= 1 ||
                          e.target.value == ""
                        ) {
                          handleStep(
                            "perNightPrice",
                            e.target.value
                              ? Math.floor(parseInt(e.target.value))
                              : ""
                          );
                        } else {
                          handleStep("perNightPrice", "");
                        }
                      }}
                      value={stepForm["perNightPrice"]}
                      type="text"
                      name="perNightPrice"
                      className="form-control"
                      placeholder="Per Night Price"
                      required
                    />
                    {formData.rentalType !== "Entire Place" &&
                      formData.bookingType !== "Cold bed" && (
                        <p className="tw-mt-2 tw-flex tw-items-center tw-justify-start">
                          <AiOutlineInfoCircle
                            size={18}
                            className="tw-mx-2 tw-gray-500"
                          />
                          Charged monthly for guests on autopay
                        </p>
                      )}
                  </div>
                </div>
              )}

            {formData.rentalType === "Entire Place" &&
              (formData.bookingType === "Cold bed" ||
                formData.bookingType === "Flexible") && (
                <div className="col-lg-6 mb-2">
                  <div>
                    <label className="form-label"> Per Month Price</label>
                    <input
                      onChange={(e) => {
                        if (
                          parseInt(e.target.value) >= 1 ||
                          e.target.value == ""
                        ) {
                          handleStep(
                            "perMonthPrice",
                            e.target.value
                              ? Math.floor(parseInt(e.target.value))
                              : ""
                          );
                        } else {
                          handleStep("perMonthPrice", "");
                        }
                      }}
                      value={stepForm["perMonthPrice"]}
                      type="text"
                      name="perMonthPrice"
                      className="form-control"
                      placeholder="Per Month Price"
                      required
                    />
                    <p className="tw-mt-2 tw-flex tw-items-center tw-justify-start">
                      ⓘ Prorated if guest checks in after the 1st of the month
                    </p>
                  </div>
                </div>
              )}
            {formData.rentalType === "Entire Place" && (
              <div className="col-lg-6 mb-2">
                <div>
                  <label className="form-label">
                    {" "}
                    Extra Charge Per Guest ($)
                  </label>
                  <input
                    onChange={(e) => {
                      if (
                        parseInt(e.target.value) >= 1 ||
                        e.target.value == ""
                      ) {
                        handleStep(
                          "extraChargePerGuest",
                          e.target.value
                            ? Math.floor(parseInt(e.target.value))
                            : ""
                        );
                      } else {
                        handleStep("extraChargePerGuest", "");
                      }
                    }}
                    value={stepForm["extraChargePerGuest"]}
                    type="number"
                    name="extraChargePerGuest"
                    className="form-control"
                    placeholder="Extra charge per guest"
                    required
                  />
                </div>
              </div>
            )}
            <div className="col-lg-6 mb-2">
              <div>
                <label className="form-label"> Cleaning Fee</label>
                <input
                  onChange={(e) => {
                    if (parseInt(e.target.value) >= 0 || e.target.value == "") {
                      handleStep(
                        "cleaningFee",
                        e.target.value
                          ? Math.floor(parseInt(e.target.value))
                          : ""
                      );
                    } else {
                      handleStep("cleaningFee", "");
                    }
                  }}
                  value={stepForm["cleaningFee"]}
                  type="text"
                  name="cleaningFee"
                  className="form-control"
                  placeholder="Cleaning Fee"
                  required
                />
                {formData.rentalType === "Entire Place" &&
                  formData.bookingType === "Cold bed" && (
                    // <p className="tw-mt-2 tw-flex tw-items-center tw-justify-start">
                    //   <AiOutlineInfoCircle
                    //     size={18}
                    //     className="tw-mx-2 tw-gray-500"
                    //   />
                    //   Charged monthly for guests on autopay or one time for all other bookings
                    // </p>
                    <p className="tw-mt-2 tw-flex tw-items-center tw-justify-start">
                      ⓘ Charged monthly for guests on autopay or one time for
                      all other bookings
                    </p>
                  )}
                {formData.rentalType !== "Entire Place" &&
                  formData.bookingType === "Cold bed" && (
                    <p className="tw-mt-2 tw-flex tw-items-center tw-justify-start">
                      ⓘ Charged monthly for guests on autopay
                    </p>
                  )}

                {formData.bookingType === "Flexible" && (
                  <p className="tw-mt-2 tw-flex tw-items-center tw-justify-start">
                    ⓘ Charged monthly for guests on autopay or one time for all
                    other bookings
                  </p>
                )}
              </div>
            </div>
            <div className="col-lg-6 mb-2">
              <div>
                <label className="form-label">Security Deposit </label>
                <input
                  onChange={(e) => {
                    if (parseInt(e.target.value) >= 1 || e.target.value == "") {
                      handleStep(
                        "securityDeposite",
                        e.target.value
                          ? Math.floor(parseInt(e.target.value))
                          : ""
                      );
                    } else {
                      handleStep("securityDeposite", "");
                    }
                  }}
                  value={stepForm["securityDeposite"]}
                  type="text"
                  name="securityDeposite"
                  className="form-control"
                  placeholder="Security Deposit"
                  required
                />
                <p className="tw-mt-2 tw-flex tw-items-center tw-justify-start">
                  ⓘ Any guest who confirms a reservation at your listing will be
                  responsible for damages up to this amount. No charges or
                  authorizations will be made unless you make a claim within 48
                  hours after check out
                </p>
              </div>
            </div>

            {(formData.bookingType === "Cold bed" ||
              formData.bookingType === "Flexible") && (
              <div className="col-lg-6 mb-2">
                <div>
                  <label className="form-label">Lineholder Discount Type</label>
                  <select
                    onChange={(e) =>
                      handleStep("lineholderDiscountType", e.target.value)
                    }
                    value={stepForm["lineholderDiscountType"]}
                    name="lineholderDiscountType"
                    className="form-control wide"
                    required
                  >
                    <option value="">Select</option>
                    <option value="none">None</option>
                    <option value="Percentage">Percentage</option>
                    <option value="Fixed">Fixed</option>
                  </select>
                </div>
              </div>
            )}
            {(stepForm.lineholderDiscountType === "Percentage" ||
              stepForm.lineholderDiscountType === "Fixed") && (
              <div className="col-lg-6 mb-2">
                <div>
                  <label className="form-label">
                    Lineholder Discount{" "}
                    {stepForm.lineholderDiscountType === "Percentage"
                      ? "(%)"
                      : "($)"}
                  </label>
                  <input
                    onChange={(e) => {
                      if (e.target.value) {
                        if (
                          stepForm.lineholderDiscountType === "Percentage" &&
                          parseInt(e.target.value) >= 0 &&
                          parseInt(e.target.value) <= 99
                        ) {
                          handleStep(
                            "lineholderDiscount",
                            e.target.value
                              ? Math.floor(parseInt(e.target.value))
                              : ""
                          );
                        } else if (
                          stepForm.lineholderDiscountType === "Fixed" &&
                          parseInt(e.target.value) >= 0
                        ) {
                          handleStep(
                            "lineholderDiscount",
                            e.target.value
                              ? Math.floor(parseInt(e.target.value))
                              : ""
                          );
                        } else {
                          handleStep("lineholderDiscount", "");
                        }
                      } else {
                        handleStep("lineholderDiscount", "");
                      }
                    }}
                    value={stepForm["lineholderDiscount"]}
                    type="text"
                    name="lineholderDiscount"
                    className="form-control"
                    placeholder="Discount"
                    required
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="tw-my-4">
          <button
            onClick={onPrev}
            type="button"
            className="btn btn-secondary mx-2  tw-mb-2"
          >
            Prev
          </button>
          <button type="submit" className="btn btn-secondary mx-2 tw-mb-2">
            Next
          </button>
          <button
            type="button"
            disabled={draftRequesting}
            onClick={saveAsdraft}
            className="btn btn-secondary mx-2 tw-mb-2"
          >
            {draftRequesting ? "Adding ..." : "Save as Draft"}
          </button>
        </div>
      </div>
    </form>
  );
};
