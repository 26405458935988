import React from 'react'

export default function BookingTypeOptions({ bookingTypeOptions, onChangeBookingType, bookingType }) {
    return (
        <>
            {bookingTypeOptions.length >= 0 ? (
                <div className="tw-flex tw-items-center tw-justify-between tw-m-4">
                    <p className="tw-text-primary">Booking Type</p>
                    {bookingTypeOptions.length >= 2 && (
                        <div className="tw-w-[1rem]">
                            <select
                                className="booking_type_dropdown tw-cursor-pointer"
                                name="booking_type"
                                required
                                value={bookingType}
                                onChange={e => {
                                    onChangeBookingType(e.target.value)
                                }}
                            >
                                <option value="">Select booking type</option>
                                {bookingTypeOptions.length >= 0 &&
                                    bookingTypeOptions.map((item, index) => (
                                        <option value={item} key={`${item}-${index}`}>
                                            {item}
                                        </option>
                                    ))}
                            </select>
                        </div>
                    )}
                    {bookingTypeOptions.length == 1 && (
                        <div className="tw-w-[1rem]">
                            <div className="booking_type_dropdown tw-cursor-pointer tw-text-black">{bookingTypeOptions[0]}</div>
                        </div>
                    )}
                </div>
            ) : (
                <div className="tw-flex tw-items-center tw-justify-between tw-m-4"></div>
            )}
        </>
    )
}
