import { AppConfig } from "../Config";
import constant from "../_config/constant";

export function processMediaUrl(inputString) {
  const urlRegex = /^(?:https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
  if (urlRegex.test(inputString)) {
    return inputString; // It's already a URL, return as is
  } else {
    const resultUrl = `${AppConfig.s3Endpoint}media/${inputString}`;
    return resultUrl;
  }
}

export function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (data.statusCode == 401 || data.statusCode == 403) {
      localStorage.removeItem(constant.DATA_KEY);
      localStorage.removeItem(constant.TOKEN_KEY);
      window.location.replace("/");
    }
    if (data.statusCode != 200 && data.statusCode != "201") {
      const error = (data && data.userMessage) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}

export const getFormattedDate = (dd) => {
  let date = new Date(dd).getDate()
  let month = new Date(dd).getMonth() + 1
  let year = new Date(dd).getFullYear()

  if (date < 10) date = `0${date}`
  if (month < 10) month = `0${month}`
  return `${year}-${month}-${date}`
}

export const getTommorow = () => {
  const tommorow = new Date()
  tommorow.setDate(new Date().getDate() + 1)

  return getFormattedDate(tommorow)
}