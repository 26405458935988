import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "./../components/Navbar";
import TopBar from "./../components/topbar";
import Footer from "./../layout/footer";
import constant from "../_config/constant";
import Moment from "react-moment";
import Pagination from "react-js-pagination";
import Tippy from "@tippy.js/react";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { getNotification } from "./../_services/index";

export default function NotificationList(props) {
  const [requesting, setRequesting] = useState(true);
  const [list, setList] = useState([]);
  const [refreshKey, setRefreshKey] = useState(0);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);

  var data = localStorage.getItem(constant.DATA_KEY);
  const decoded = data ? JSON.parse(data) : {};

  const pageChange = (page) => {
    setPage(page);
    setRequesting(true);
    setRefreshKey((oldKey) => oldKey + 1);
  };

  useEffect(() => {
    const fetch = async () => {
      try {
        setRequesting(true);
        const res = await getNotification(page);

        setList(res.data.data.docs);
        setTotal(res.data.data.total);
        setLimit(res.data.data.limit);
      } catch (error) {
        toast.error("Something went wrong!");
      } finally {
        setRequesting(false);
      }
    };

    fetch();
  }, [refreshKey]);

  return (
    <div id="main-wrapper">
      <ToastContainer />
      <TopBar user={decoded} showSearch={false} title="Notifications" />

      <div className="deznav">
        <div className="deznav-scroll">
          <Navbar />
        </div>
      </div>

      <div className="content-body">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12">
              <div className="tab-content">
                <div className="tab-pane active show" id="All">
                  <div className="table-responsive">
                    <table
                      className="table card-table  display mb-4 dataTablesCard booking-table room-list-tbl table-responsive-lg "
                      id="guestTable-all"
                    >
                      <thead>
                        <tr>
                          <th style={{ width: "25%" }}>Property Id</th>
                          <th>Message</th>
                        </tr>
                      </thead>
                      <tbody>
                        {list.map((curr) => (
                          <tr>
                            <td>{curr.propertyId}</td>
                            <td>{curr.message}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            {requesting && (
              <div className="tw-my-10 tw-flex tw-items-center tw-justify-center">
                <div className="bg-white tw-w-max rounded shadow-md p-4">
                  <div className="loader"></div>
                </div>
              </div>
            )}
          </div>
          <Pagination
            activePage={page}
            itemsCountPerPage={limit}
            totalItemsCount={total}
            pageRangeDisplayed={10}
            onChange={pageChange}
          />
        </div>
      </div>

      <Footer />
    </div>
  );
}
