import React from 'react'

export default function PropertyOption({ handlePropertyListing, propertyList }) {
    return (
        <div className="tw-flex tw-items-center tw-justify-between tw-m-4">
            <p className="tw-text-primary">Properties</p>
            <div className="tw-w-[50%]">
                <select
                    className="booking_type_dropdown tw-w-full tw-cursor-pointer"
                    name="property_list"
                    required
                    onChange={e => handlePropertyListing(e.target.value)}
                >
                    <option value="">Select Property</option>
                    {propertyList.length > 0 &&
                        propertyList.map((item, index) => (
                            <option value={item.id} key={`${item.id}-${index}`}>
                                {item.listingName}
                            </option>
                        ))}
                </select>
            </div>
        </div>
    )
}
