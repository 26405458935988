import React, { useEffect, useState, useRef } from "react";
import constant from "../../_config/constant";
import airlineEmails from "../../_config/airlineEmail.json";
import { userService } from "../../_services";
import { toast } from "react-toastify";
import { logout, getData, saveData } from "../../_helpers";
import PhoneInput from "react-phone-input-2";
import { AiOutlineMail, AiOutlinePlus } from "react-icons/ai";
import "react-phone-input-2/lib/style.css";
import { FcGoogle } from "react-icons/fc";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { BsFacebook } from "react-icons/bs";
import GoogleLogin from "react-google-login";
import CustomModal from "./../ReactModal";
import OtpInput from "react-otp-input";

const OTPInput = React.forwardRef((props, ref) => {
  const [value, setValue] = useState(value);
  return (
    <React.Fragment>
      <OtpInput
        value={value}
        onChange={(e) => {
          setValue(e);
        }}
        containerStyle="tw-flex tw-items-center tw-justify-center tw-space-x-4 tw-my-10"
        inputStyle="tw-h-10  tw-w-10 tw-border-0 tw-ring-1 tw-ring-gray-600 tw-bg-white tw-rounded tw-p-0"
        separator={<span>{"  "}</span>}
        numInputs={4}
      />
      <input
        type="text"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        className="tw-hidden"
        ref={ref}
      />
    </React.Fragment>
  );
});
export function PhoneVerificationModal(props) {
  const otpRef = useRef();
  return (
    <CustomModal>
      <div className="tw-w-[95%] mb-4 md:mb-0 md:tw-w-[85%] tw-mx-auto ">
        <span
          onClick={props.togglePhoneModal}
          className="tw-rounded-full tw-cursor-pointer tw-flex tw-justify-center tw-items-center tw-bg-white tw-p-2 t tw-ml-auto tw-text-gray-700 tw-h-12 tw-w-12"
        >
          <AiOutlinePlus size={22} className="tw-transform tw-rotate-45" />
        </span>
      </div>
      <div className="tw-bg-white tw-w-[95%] md:tw-w-[75%] tw-mx-auto tw-p-10 tw-rounded-3xl tw-border-2 ">
        <div>
          <h3 className="tw-text-lg tw-text-center tw-font-medium tw-text-gray-700">
            Enter OTP
          </h3>
          <p className="tw-text-gray-700 tw-text-center tw-text-sm">
            Enter the OTP received on your mobile number
          </p>
        </div>
        <OTPInput ref={otpRef} />
        <div className="tw-text-sm tw-text-center">
          <button
            onClick={() => {
              props.verifyOtp(otpRef.current.value);
            }}
            disabled={props.phoneVerification} // data-bs-dismiss="modal"
            className="tw-w-max tw-text-center tw-mx-auto tw-col-span-full tw-text-sm tw-mx-auto tw-p-2 tw-px-4 tw-rounded tw-bg-primary tw-border-0 tw-text-white "
          >
            {props.phoneVerification ? "Verfing.." : "Verify"}
          </button>
        </div>
        <p
          onClick={props.resendMobileOtp}
          className="tw-text-sm tw-text-center tw-my-2 tw-text-primary tw-cursor-pointer"
        >
          Resend OTP
        </p>
      </div>
    </CustomModal>
  );
}
