import React, { useState } from 'react'

import { AiOutlinePlus } from 'react-icons/ai'
import { nanoid } from 'nanoid'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { processMediaUrl } from '../../../_helpers/utils'
var Modal = require('react-bootstrap-modal')

export function AddPhotos({ onNext, onPrev, formData, setFormData, className, saveAsDraft, draftRequesting }) {
    // const [photos, setPhotos] = useState(formData["photos"] || []);
    const [photos, setPhotos] = useState(
        formData['images'] && formData['images'].length
            ? formData['images'].map((e, i) => {
                  return {
                      type: 'url',
                      order: i + 1,
                      url: e,
                      id: nanoid(),
                  }
              })
            : []
    )
    const [dragId, setDragId] = useState()
    const [isDragging, setIsDragging] = useState(false)
    const [draggOverId, setDraggOverId] = useState(false)

    const handleDrag = ev => {
        setIsDragging(true)
        setDragId(ev.currentTarget.id)
    }

    const handleDrop = ev => {
        const dragBox = photos.find(photo => photo.id === dragId)
        const dropBox = photos.find(photo => photo.id === ev.currentTarget.id)

        const dragBoxOrder = dragBox.order
        const dropBoxOrder = dropBox.order

        const newPhotoState = photos.map(photo => {
            if (photo.id === dragId) {
                photo.order = dropBoxOrder
            }
            if (photo.id === ev.currentTarget.id) {
                photo.order = dragBoxOrder
            }
            return photo
        })
        setIsDragging(false)
        setDragId(null)
        setPhotos(newPhotoState)
        setDraggOverId(false)
    }
    const getImage = file => {
        let src = new FileReader()
        const url = URL.createObjectURL(file.current)
        return url
    }

    const handleDelete = id => {
        const arr = photos.filter(curr => curr.id !== id)
        setPhotos([...arr])
        setFormData({ ...formData, photos: [...arr] })
    }

    const handleNextStep = () => {
        setFormData({ ...formData, photos: photos })
        window.scrollTo({ top: 0, behavior: 'smooth' })

        if (photos.length > 25) {
            toast.error('You can add upto 25 images while listing the property')
            return
        }
        photos.length && onNext()
    }

    const saveAsdraft = () => {
        setFormData({ ...formData, photos: photos })
        // photos.length && saveAsDraft();
        saveAsDraft()
    }

    return (
        <div className={className}>
            <div className=" ">
                <h4 className="mb-5 tw-flex tw-justify-start tw-items-center">
                    <span className="tw-bg-[#23426e] tw-mr-2 tw-flex tw-justify-center tw-items-center  tw-h-10 tw-w-10 tw-text-white  tw-rounded-full">
                        4
                    </span>{' '}
                    Add Photos
                </h4>
                <div className="row gx-5">
                    <div className="tw-flex tw-items-start tw-flex-wrap tw-mb-4">
                        {photos
                            .sort((a, b) => a.order - b.order)
                            .map((curr, i) => {
                                return (
                                    <div
                                        key={`${i}-${curr.id}`}
                                        id={curr.id}
                                        draggable
                                        onDragOver={ev => {
                                            ev.preventDefault()
                                            setDraggOverId(ev.target.id)
                                        }}
                                        onDragEnd={() => {
                                            setDraggOverId(false)
                                        }}
                                        onDragStart={handleDrag}
                                        onDrop={handleDrop}
                                        className={`tw-w-44 tw-flex-shrink-0 tw-grow-0 tw-relative tw-cursor-move tw-h-44 tw-m-2 tw-object-cover tw-rounded-md tw-overflow-hidden ${
                                            draggOverId === curr.id && isDragging ? 'tw-bg-gray-400' : 'tw-bg-gray-300'
                                        }`}
                                    >
                                        {i === 0 && (
                                            <div
                                                className="tw-text-white tw-p-1"
                                                style={{
                                                    width: '70px',
                                                    textAlign: 'center',
                                                    fontSize: '13px',
                                                    borderRadius: '3px',
                                                    position: 'absolute',
                                                    top: 0,
                                                    backgroundColor:'#23426e'
                                                }}
                                            >
                                                Featured
                                            </div>
                                        )}
                                        <span
                                            onClick={() => handleDelete(curr.id)}
                                            className="tw-block tw-absolute tw-right-4 tw-top-4 tw-h-8 tw-w-8 tw-flex tw-items-center tw-justify-center tw-cursor-pointer tw-rounded-full tw-bg-gray-500 tw-bg-opacity-50"
                                        >
                                            <AiOutlinePlus size={22} className="tw-transform tw-rotate-45 tw-text-white" />
                                        </span>

                                        <img
                                            id={curr.id}
                                            className="tw-w-full tw-h-full tw-object-cover"
                                            src={curr.type === 'url' ? processMediaUrl(curr.url) : getImage(curr)}
                                            style={{
                                                opacity: isDragging && draggOverId === curr.id ? '0' : '1',
                                            }}
                                        />
                                    </div>
                                )
                            })}
                    </div>
                    {Boolean(photos.length) && (
                        <p className="tw-text-sm tw-text-gray-400 tw-my-2">ⓘ Drag photos to rearrange. First photo will be your cover photo</p>
                    )}

                    <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                            <label className="text-label form-label">Add photos</label>
                            <div className="tw-flex tw-items-center">
                                <label htmlFor="add_photos" className="btn btn-secondary btn-sm">
                                    choose
                                </label>
                                <p className="tw-text-base tw-ml-4">{!photos.length ? 'No file choosen' : `${photos.length} File choosen`}</p>
                            </div>
                            <input
                                id="add_photos"
                                type="file"
                                multiple
                                className="form-control tw-hidden"
                                required
                                accept="image/*"
                                onChange={e => {
                                    const curr = Array.from(e.target.files).map((file, i) => {
                                        return {
                                            current: file,
                                            id: nanoid(),
                                            order: photos.length + i + 1,
                                        }
                                    })

                                    setPhotos([...photos, ...curr])
                                    setFormData({ ...formData, photos: [...photos, ...curr] })
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <button onClick={onPrev} type="button" className="btn btn-secondary mx-2  tw-mb-2">
                    Prev
                </button>
                <button onClick={handleNextStep} type="button" className="btn btn-secondary mx-2 tw-mb-2">
                    Next
                </button>
                <button type="button" disabled={draftRequesting} onClick={saveAsdraft} className="btn btn-secondary mx-2 tw-mb-2">
                    {draftRequesting ? 'Adding ...' : 'Save as Draft'}
                </button>
            </div>
        </div>
    )
}
