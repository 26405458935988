import React, { useEffect, useState, useCallback } from 'react'
import constant from './../../_config/constant'
import { GoSearch } from 'react-icons/go'
import ChatCard from './ChatCard'

const ChatList = ({ onSelectChannel, activeChannel, client, viewList, setViewList, refreshChatlist, setRefreshChatlist, onChannelsLoaded, cssClasses, cssChangeClass }) => {
    const [channelUserStatuses, setChannelUserStatuses] = useState({})
    const [pagination, setPagination] = useState({
        nextPageFunc: null,
        prevPageFunc: null,
        hasNextPage: false,
        hasPrevPage: false,
    })
    const [channels, setChannels] = useState({
        list: [],
        filtered: [],
        search: '',
    })
    const [loadingState, setLoadingState] = useState({
        initial: true,
        updating: false,
    })
    const [receivingMessages, setReceivingMessages] = useState(new Set()) // Track channels receiving messages
    const [allChannelsLoaded, setAllChannelsLoaded] = useState(false)
    const data = localStorage.getItem(constant.DATA_KEY)
    const user = data ? JSON.parse(data) : {}

    const loadPage = async pageFunc => {
        if (pageFunc) await pageFunc()
    }

    const fetchUserChannels = useCallback(
        async (isInitial = false) => {
            try {
                if (isInitial) {
                    setLoadingState({ initial: true, updating: false })
                } else {
                    setLoadingState(prev => ({ ...prev, updating: true }))
                }

                const list = client && client.current && (await client.current.getUserChannelDescriptors())

                setPagination({
                    hasNextPage: list.hasNextPage,
                    hasPrevPage: list.hasPrevPage,
                    nextPageFunc: list.hasNextPage ? list.state.nextToken : null,
                    prevPageFunc: list.hasPrevPage ? list.state.prevToken : null,
                })

                if (!list.state.items.length) {
                    console.log('No channels found.') // Debug log
                }

                const channelsData = await Promise.all(
                    list.state.items.map(async curr => {
                        try {
                            const channel = await client.current.getChannelBySid(curr.sid)
                            const messages = await channel.getMessages(1)

                            const receivedMessages = messages.items.filter(msg => msg.author !== user.id)
                            const lastMessage = receivedMessages.length ? receivedMessages[receivedMessages.length - 1] : null

                            const members = await channel.getMembers()
                            const users = {}

                            for (const member of members) {
                                const userId = member.identity
                                const userStatus = await client.current.getUser(userId)
                                users[userId] = userStatus.state

                                if (userId !== user.id.toString()) {
                                    users['displayState'] = userStatus.state.online
                                }
                            }

                            // Channel event listeners
                            channel.on('messageAdded', message => {
                                if (message.author.toString() !== user.id.toString()) {
                                    setReceivingMessages(prev => new Set(prev).add(curr.uniqueName))
                                    setRefreshChatlist(prev => !prev)
                                }
                            })

                            channel.on('updated', details => {
                                setRefreshChatlist(prev => !prev)
                            })

                            channel.on('userUpdated', details => {})
                            return {
                                channelId: curr.uniqueName,
                                name: curr.friendlyName,
                                property: curr.attributes.property,
                                unreadMessages: curr.descriptor.unread_messages_count,
                                lastMessageText: lastMessage ? lastMessage.body : '',
                                lastMessageDate: lastMessage ? lastMessage.dateCreated : null,
                                users,
                                invited: curr.descriptor.status === 'invited' ? true : false,
                            }
                        } catch (error) {
                            // console.error('Error processing channel:', curr.sid, error)

                            // Option 1: Skip the channel and continue
                            // return null;

                            // Option 2: Return a placeholder or default data
                            return {
                                channelId: curr.uniqueName,
                                name: curr.friendlyName || 'Unknown Channel',
                                property: curr.attributes.property || {},
                                unreadMessages: 0,
                                lastMessageText: '',
                                lastMessageDate: null,
                                users: {},
                                invited: curr.descriptor.status === 'invited' ? true : false,
                            }
                        }
                    })
                )
                const userChannels = channelsData
                    .filter(channel => channel.channelId.split('-')[2] === user.id.toString())
                    .sort((a, b) => new Date(b.lastMessageDate) - new Date(a.lastMessageDate))

                setChannels({ list: userChannels, filtered: userChannels, search: '' })
                setLoadingState({ initial: false, updating: false })
                setAllChannelsLoaded(true) // Mark all channels as loaded

                // Notify parent component that channels are loaded
                if (onChannelsLoaded) {
                    onChannelsLoaded(userChannels)
                }

                // Set initial user statuses
                setChannelUserStatuses(
                    userChannels.reduce((acc, channel) => {
                        acc[channel.channelId] = channel.users
                        return acc
                    }, {})
                )
            } catch (error) {
                console.error('Error fetching channels:', error)
                setLoadingState({ initial: false, updating: false })
            }
        },
        [client, user.id, setRefreshChatlist, onChannelsLoaded]
    )

    const updateUnreadCount = useCallback((channelId, count) => {
        setChannels(prev => {
            const updatedList = prev.list.map(channel => (channel.channelId === channelId ? { ...channel, unreadMessages: count } : channel))
            const filteredList = updatedList.filter(
                channel =>
                    channel.property.name.toUpperCase().includes(prev.search.toUpperCase()) ||
                    channel.property.host.toUpperCase().includes(prev.search.toUpperCase()) ||
                    channel.property.reservationId.toUpperCase().includes(prev.search.toUpperCase())
            )
            return { ...prev, list: updatedList, filtered: filteredList }
        })
    }, [])

    useEffect(() => {
        fetchUserChannels(true) // Initial load
    }, [fetchUserChannels])

    useEffect(() => {
        if (!loadingState.initial) {
            fetchUserChannels() // Subsequent updates
        }
    }, [fetchUserChannels, refreshChatlist, loadingState.initial])

    useEffect(() => {
        if (receivingMessages.size > 0) {
            setLoadingState(prev => ({ ...prev, updating: true }))
        } else {
            setLoadingState(prev => ({ ...prev, updating: false }))
        }
    }, [receivingMessages])

    useEffect(() => {
        const clientInstance = client.current
        const handleChannelInvited = async channelDescriptor => {
            try {
                // Automatically join the invited channel
                const channel = await clientInstance.getChannelBySid(channelDescriptor.sid)
                // Fetch the channel details to include additional attributes
                await channel.join()

                // Refresh the chat list to include the newly joined channel
                fetchUserChannels()
            } catch (error) {
                console.error('Error joining invited channel:', error)
            }
        }

        clientInstance.on('channelInvited', handleChannelInvited)

        return () => {
            clientInstance.off('channelInvited', handleChannelInvited)
        }
    }, [client, fetchUserChannels])

    const handleSearchFilter = text => {
        setChannels(prev => ({
            ...prev,
            search: text,
            filtered: prev.list.filter(channel => `${channel.property.name}-${channel.property.user}-${channel.property.reservationId}`.toUpperCase().includes(text.toUpperCase())),
        }))
    }

    return (
        <div className={cssClasses && `remove-chat-list`}
            style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '0.5rem',
                backgroundColor: '#f5f7fb',
                fontSize: '0.875rem',
                maxHeight: '100%',
                overflow: 'hidden',
            }}
        >
            <div style={{ padding: '1rem' }}>
                <h2
                    style={{
                        fontSize: '1.125rem',
                        display: 'flex',
                        alignItems: 'center',
                        fontWeight: 500,
                    }}
                >
                    Chats
                </h2>
            </div>
            <div
                style={{
                    margin: '0.5rem 0',
                    borderRadius: '0.25rem',
                    padding: '0.25rem',
                    backgroundColor: '#ffffff',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <GoSearch style={{ margin: '0.5rem' }} />
                <input
                    type="text"
                    value={channels.search}
                    onChange={e => handleSearchFilter(e.target.value)}
                    style={{
                        backgroundColor: 'transparent',
                        flexGrow: 1,
                        fontSize: '0.875rem',
                        padding: 0,
                        border: 'none',
                        outline: 'none',
                    }}
                    placeholder="Search"
                />
            </div>
            {loadingState.initial && !allChannelsLoaded ? (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div className="loader"></div>
                </div>
            ) : (
                <div style={{ overflowY: 'scroll', flexGrow: 1 }}>
                    {/* Loader for updates */}
                    {loadingState.updating && !allChannelsLoaded && (
                        <div className="dotloader m-2">
                            <div className="dotloading"></div>
                            <div className="dotloading"></div>
                            <div className="dotloading"></div>
                        </div>
                    )}
                    {!channels.list.length && <p style={{ textAlign: 'center', margin: '2.5rem 0' }}>No Conversation Found!</p>}
                    <>
                        {pagination.hasPrevPage && (
                            <p
                                onClick={() => loadPage(pagination.prevPageFunc)}
                                style={{ margin: '0.5rem 0', textAlign: 'center', fontSize: '0.875rem', cursor: 'pointer' }}
                            >
                                Prev
                            </p>
                        )}
                        {(channels.search ? channels.filtered : channels.list).map((curr, ind) => (
                            <ChatCard
                                key={ind}
                                data={curr}
                                activeChannel={activeChannel}
                                onSelectChannel={onSelectChannel}
                                setViewList={setViewList}
                                updateUnreadCount={updateUnreadCount}
                                userStatus={channelUserStatuses}
                                cssChangeClass={cssChangeClass}
                            />
                        ))}
                        {pagination.hasNextPage && (
                            <p
                                onClick={() => loadPage(pagination.nextPageFunc)}
                                style={{ margin: '0.5rem 0', textAlign: 'center', fontSize: '0.875rem', cursor: 'pointer' }}
                            >
                                Next
                            </p>
                        )}
                    </>
                </div>
            )}
        </div>
    )
}

export default ChatList
