import React, { useState } from "react";
import { toast } from "react-toastify";
import { userService } from "../../_services";
import { logout } from "../../_helpers";

function TextInput({ label, value, setValue, ...props }) {
  return (
    <div className="tw-flex tw-flex-col">
      <label className="tw-text-sm">{label}</label>
      <input
        type="text"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        className={"tw-p-3 tw-rounded tw-ring-1 tw-ring-gray-400 tw-border-0 "}
        {...props}
      />
    </div>
  );
}

function ChangePassword(props) {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [status, setStatus] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    if (newPassword.length < 8) {
      toast.info("New password must have at least 8 characters.");
      return;
    }
    if (newPassword != confirmPassword) {
      toast.info("New password and confirm password must be same.");
      return;
    }
    setStatus(true);
    userService
      .changePassword({ oldPassword, newPassword, confirmPassword })
      .then(
        (objS) => {
          if (objS.status) {
            toast.success(objS.message);
            // logout();
            // props.history.push("/properties");
          } else {
            toast.error(objS.message);
          }
          setStatus(false);
        },
        (error) => {
          setStatus(false);
          toast.error(error.message);
        }
      );
  };

  return (
    <form className="col-xl-6  tw-space-y-3 tw-my-4" onSubmit={onSubmit}>
      <TextInput
        type="password"
        value={oldPassword}
        setValue={setOldPassword}
        label="Old Password"
        required
      />
      <TextInput
        type="password"
        value={newPassword}
        setValue={setNewPassword}
        label="New Password"
        required
      />
      <TextInput
        type="password"
        value={confirmPassword}
        setValue={setConfirmPassword}
        label="Confirm New Password"
        required
      />
      <button
        disabled={status}
        type="submit"
        className="tw-rounded-md tw-p-3 tw-px-8 tw-bg-primary tw-border-0 tw-text-white tw-text-sm "
      >
        {status ? "Please wait ..." : "Submit"}
      </button>
    </form>
  );
}

export default ChangePassword;
