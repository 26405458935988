import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function AllPayments({ list }) {
  const history = useHistory();
  return (
    <div className="mt-4">
      <table className="table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Amount</th>
            <th>Available On</th>
            <th>Created</th>
            <th>Currency</th>
            <th>Status</th>
            <th>Type</th>
            <th>View</th>
          </tr>
        </thead>
        <tbody>
          {list.map((payment) => (
            <tr key={payment.id}>
              <td>{payment.id}</td>
              <td>${payment.amount / 100}</td>
              <td>
                {new Date(payment.available_on * 1000).toLocaleDateString()}
              </td>
              <td>{new Date(payment.created * 1000).toLocaleDateString()}</td>
              <td>{payment.currency}</td>
              <td>{payment.status}</td>
              <td>{payment.type}</td>
              <td>
                <a
                  role="button"
                  onClick={() => {
                    history.push(`/custom/payments/${payment.id}`);
                  }}
                >
                  View
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default AllPayments;
