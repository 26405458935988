import React, { useState } from "react";

import "react-toastify/dist/ReactToastify.css";

var Modal = require("react-bootstrap-modal");

export const Amenities = ({
  onNext,
  onPrev,
  formData,
  setFormData,
  className,
  amenityList,
  saveAsDraft,
  draftRequesting,
}) => {
  const [amenities, setAminities] = useState(formData["amenities"] || []);
  const handleStep = (val) => {
    const ind = amenities.indexOf(val);
    const updatedAminities = [...amenities];
    if (ind > -1) {
      updatedAminities.splice(ind, 1);
    } else {
      updatedAminities.push(val);
    }
    setAminities(updatedAminities);
    setFormData({ ...formData, amenities: updatedAminities });
  };

  const handleNextStep = () => {
    setFormData({ ...formData, amenities: [...amenities] });
    if (amenities.length) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      onNext();
    }
  };

  const saveAsdraft = () => {
    setFormData({ ...formData, amenities: [...amenities] });
    // if (amenities.length) {
    saveAsDraft();
    // }
  };

  return (
    <div className={className}>
      <div className="">
        <h4 className="mb-2 tw-flex tw-justify-start tw-items-center">
          <span className="tw-bg-[#23426e] tw-mr-2 tw-flex tw-justify-center tw-items-center  tw-h-10 tw-w-10 tw-text-white  tw-rounded-full">
            5
          </span>
          Amenities
        </h4>
        <div className="my-3 row ">
          {amenityList.map((data) => (
            <div
              key={data.id}
              className="form-check form-check-inline col-lg-3"
            >
              <label className="form-check-label">
                <input
                  onChange={(e) => handleStep(e.target.value)}
                  checked={amenities.includes(data.id)}
                  type="checkbox"
                  className="form-check-input tw-h-5 tw-w-5"
                  value={data.id}
                />
                {data.name}
              </label>
            </div>
          ))}
        </div>
      </div>
      <div>
        <button
          onClick={onPrev}
          type="button"
          className="btn btn-secondary mx-2  tw-mb-2"
        >
          Prev
        </button>
        <button
          onClick={handleNextStep}
          type="button"
          className="btn btn-secondary mx-2 tw-mb-2"
        >
          Next
        </button>
        <button
          type="button"
          disabled={draftRequesting}
          onClick={saveAsdraft}
          className="btn btn-secondary tw-mb-2"
        >
          {draftRequesting ? "Adding ..." : "Save as Draft"}
        </button>
      </div>
    </div>
  );
};
