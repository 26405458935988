import React, { useEffect, useState, useRef } from "react";
import constant from "../../_config/constant";
import airlineEmails from "../../_config/airlineEmail.json";
import { userService } from "../../_services";
import { toast } from "react-toastify";
import { logout, getData, saveData } from "../../_helpers";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

function TextField({
  value,
  setValue,
  label,
  children,
  verified,
  disabled,
  type = "text",
  error = false,
  ...props
}) {
  const [editable, setEditable] = useState(false);

  return (
    <div className="tw-flex tw-flex-col">
      <label className="tw-text-sm">
        {label}
        {verified ? (
          ""
        ) : (
          <span className="tw-text-red-500 tw-ml-2">Unverified</span>
        )}
      </label>
      <div
        className={`tw-flex tw-justify-start tw-items-center tw-rounded tw-shadow-md tw-w-full tw-p-3 ${
          error ? "tw-border-red-500 border " : ""
        }`}
      >
        <input
          disabled={disabled}
          type={type}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          className={
            " tw-rounded tw-bg-transparent tw-border-0 tw-full tw-flex-grow"
          }
          {...props}
        />
        {/* <button
          onClick={() => setEditable(!editable)}
          className="tw-text-sm tw-bg-transparent tw-border-0 tw-mx-2 tw-text-primary-blue"
        >
          {editable ? 'Cancel' : 'Edit'}
        </button> */}
      </div>
    </div>
  );
}

function BecomeCrewmate() {
  const phoneRef = useState();
  const [name, setName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [personalEmail, setPersonalEmail] = useState("");
  const [personalEmailError, setPersonalEmailError] = useState("");
  const [phone, setPhone] = useState("");
  const [mobileCode, setmobileCode] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [airlineEmail, setAirlineEmail] = useState("");
  const [airlineEmailError, setAirlineEmailError] = useState("");
  const [crewType, setCrewType] = useState("");
  const [apiCalled, setApiCalled] = useState(false);
  const [type, setType] = useState("");

  const [dob, setDob] = useState("");
  const [status, setStatus] = useState(false);
  const [fileData, setFileData] = useState("");
  const [profile, setProfile] = useState("/images/profile/profile.png");
  const [refreshKey, setRefreshKey] = useState(0);

  const getFile = (e) => {
    if (e.target.files[0]) {
      setFileData(e.target.files[0]);
      let src = new FileReader();
      const url = URL.createObjectURL(e.target.files[0]);
      setProfile(url);
    }
  };
  const [personalEmailVerification, setPersonalEmailVerification] = useState(
    false
  );
  const [phoneVerification, setPhoneVerification] = useState(false);
  const [airlineEmailVerification, setAirlineEmailVerification] = useState(
    false
  );

  const handleAirlineEmail = (email) => {
    setAirlineEmail(email);
    if (email) {
      const domain = email.split("@")[1];
      const isValid = airlineEmails.data.filter(
        (el) => el.Domain === `@${domain}`
      );
      if (isValid.length === 0) {
        setAirlineEmailError(true);
      } else setAirlineEmailError(false);
    } else {
      setAirlineEmailError(false);
    }
  };

  const handlePersonalEmail = (email) => {
    setPersonalEmail(email);
    const domain = email.split("@")[1];
    const isValid = airlineEmails.data.filter(
      (el) => el.Domain === `@${domain}`
    );
    if (isValid.length !== 0) {
      setPersonalEmailError(true);
    } else setPersonalEmailError(false);
  };

  const handlePhoneNumber = (value, e) => {
    const phoneInput = value.slice(e.dialCode.length);
    setPhone(phoneInput);
    setCountryCode(e.dialCode);
  };

  useEffect(() => {
    userService.getDetail({}).then(
      (objS) => {
        setApiCalled(true);
        if (objS.status) {
          setFirstName(objS.data.name.split(" ")[0]);
          setLastName(objS.data.name.split(" ")[1]);
          setPersonalEmail(objS.data.personalEmail);
          setPhone(objS.data.phone);
          setCountryCode(objS.data.countryCode);
          setAirlineEmail(objS.data.airlineEmail);
          setType(objS.data.type);
          setDob(objS.data.dob);
          setPersonalEmailVerification(objS.data.personalEmailVerification);
          setPhoneVerification(objS.data.phoneVerification);
          setAirlineEmailVerification(objS.data.airlineEmailVerification);
          if (objS.data.avatar) {
            setProfile(constant.mediaUrl + objS.data.avatar);
            var host = getData();
            if (host && host.avatar) {
              host.avatar = objS.data.avatar;
              saveData(host);
            }
          }
          setTimeout(() => {
            if (phoneRef.current) {
              phoneRef.current.tel.value = objS.data.phone;
            }
          }, 1000);
        }
      },
      (error) => {
        setApiCalled(true);
      }
    );
  }, [refreshKey]);

  const isAdult = (date) => {
    let now = new Date(); //getting current date
    let currentY = now.getFullYear(); //extracting year from the date
    let currentM = now.getMonth(); //extracting month from the date

    let dob = new Date(date); //formatting input as date
    let prevY = dob.getFullYear(); //extracting year from input date
    let prevM = dob.getMonth(); //extracting month from input date

    let ageY = currentY - prevY;

    if (ageY < 17) return false;
    else return true;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setStatus(true);
    userService.becomeHost({ crewType }).then(
      (objS) => {
        if (objS.status) {
          toast.success(objS.message);
          setRefreshKey((oldKey) => oldKey + 1);
        } else {
          toast.error(objS.message);
        }
        setStatus(false);
      },
      (error) => {
        setStatus(false);
        toast.error(error.message);
      }
    );
  };

  return (
    <div>
      {apiCalled ? (
        <div className="tw-mt-4">
          {type == "Both" ? (
            <p>
              Your guest account has been successfully registered. Please log
              out of your host account and log in as a guest to book a property.
            </p>
          ) : (
            <div>
              <span className="tw-h-32 tw-w-32 tw-relative tw-rounded-full tw-object-cover tw-p-2 tw-block tw-ring-2 tw-ring-[#23426e]  tw-mx-auto">
                <img
                  className="tw-h-full tw-w-full  tw-rounded-full tw-object-cover"
                  src={profile}
                  alt="dev"
                />
              </span>
              <input
                id="profile_user"
                type="file"
                name="images"
                onChange={getFile}
                className="tw-hidden"
              />
              <form onSubmit={onSubmit}>
                <div className="tw-grid-cols-1 lg:tw-grid-cols-2  tw-gap-x-10  tw-gap-y-8 tw-grid tw-py-10">
                  <TextField
                    disabled={true}
                    verified={true}
                    value={firstName}
                    placeholder="First Name"
                    setValue={setFirstName}
                    label="First Name"
                  />
                  <TextField
                    disabled={true}
                    verified={true}
                    value={lastName}
                    placeholder="Last Name"
                    setValue={setLastName}
                    label="Last Name"
                  />
                  <TextField
                    disabled={true}
                    verified={personalEmailVerification}
                    placeholder="Email"
                    value={personalEmail}
                    error={personalEmailError}
                    setValue={handlePersonalEmail}
                    label="Personal Email"
                    type="email"
                  />
                  <div className="tw-flex tw-flex-col">
                    <span className="tw-text-gray-500 ">
                      Phone Number
                      {!phoneVerification && (
                        <span className="tw-text-red-500 tw-ml-2">
                          Unverified
                        </span>
                      )}
                    </span>

                    <PhoneInput
                      // verified={phoneVerification}
                      country={"us"}
                      onlyCountries={["us"]}
                      disabled={true}
                      autoFormat
                      value={`${countryCode}${phone}`}
                      onChange={handlePhoneNumber}
                      buttonClass="bg-gray-100 border-0"
                      containerClass="tw-w-full"
                      inputClass="tw-border-0 tw-pl-12 tw-focus:ring-0 tw-shadow-md tw-p-3 tw-focus:border-0 tw-flex-grow tw-text-sm tw-w-full tw-outline-none tw-bg-white  tw-rounded "
                    />
                  </div>

                  <TextField
                    verified={
                      airlineEmail && !airlineEmailVerification ? false : true
                    }
                    disabled={true}
                    type="email"
                    error={airlineEmailError}
                    placeholder="Airline email address"
                    value={airlineEmail}
                    setValue={handleAirlineEmail}
                    label="Airline Email (Optional)"
                  />
                  <TextField
                    verified={true}
                    value={dob}
                    disabled={true}
                    placeholder=""
                    type="date"
                    setValue={setDob}
                    label="Date of Birth"
                  />
                  <div className="tw-flex tw-flex-col">
                    <label className="tw-text-sm">Crew Type</label>
                    <div className="tw-flex tw-justify-start tw-items-center tw-rounded tw-shadow-md tw-w-full tw-p-3">
                      <select
                        value={crewType}
                        onChange={(e) => {
                          setCrewType(e.target.value);
                        }}
                        className="tw-rounded tw-bg-transparent tw-border-0 tw-full tw-flex-grow"
                        name="crewType"
                        required
                      >
                        <option value="">Select</option>
                        <option value="Pilot">Pilot</option>
                        <option value="Flight Attendent">
                          Flight Attendent
                        </option>
                        <option value="Other Airline Professional">
                          Other Airline Professional
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <button
                  type="submit"
                  className="tw-w-max tw-rounded-md tw-p-3 tw-px-8 tw-bg-[#23426e] tw-border-0 tw-text-white tw-text-sm"
                  disabled={status}
                >
                  {status ? "Please wait ..." : "Become Crewmate"}
                </button>
              </form>
            </div>
          )}
        </div>
      ) : (
        <p>Loading.</p>
      )}
    </div>
  );
}

export default BecomeCrewmate;
