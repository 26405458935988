import React, { useEffect, useState } from "react";
import { Wrapper } from "./Wrapper";
import { payoutService } from "../../_services";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";

const BookingCard = ({ booking }) => {
  console.log(booking);

  return (
    <div
      className="bg-white   p-4 m-2 rounded-md shadow-md "
      style={{ width: 350 }}
    >
      {/* max-w-xs sets a maximum width of 300px */}
      <h3 className="text-lg font-semibold">
        {booking.booking.propertyId.listingName}
      </h3>
      <p className="text-gray-500">Booking Id: {booking.history.bookingId}</p>
      <p className="text-gray-500">${booking.history.hostEarning} (Approx)</p>
      <p className="text-gray-500">
        {moment(booking.booking.checkInDate).format("MM/DD/yyyy")} -{" "}
        {booking.booking.checkOutDate
          ? moment(booking.booking.checkOutDate).format("MM/DD/yyyy")
          : ""}
      </p>
      <p>
        ${(booking.history.hostEarning / booking.history.totalNight).toFixed(2)}
        /day
      </p>
    </div>
  );
};

function Earnings() {
  const [bookings, setBookings] = useState([]);
  const [totalEarning, setTotalEarning] = useState(0);
  const [availablePayout, setAvailablePayout] = useState(0);
  const [isWalletFetching, setIsWalletFetching] = useState(false);
  const [walletTransactions, setWalletTransactions] = useState([]);
  const [page, setPage] = useState(1);
  const [refreshKey, setRefreshKey] = useState(new Date());
  const [payoutTransactions, setPayoutTransactions] = useState([]);

  useEffect(() => {
    fetchWalletInfo(page);
  }, [page, refreshKey]);

  const fetchWalletInfo = async (page) => {
    setIsWalletFetching(true);
    try {
      const result = await payoutService.getWalletDetail(page);
      setBookings(result.data.bookings);
      setTotalEarning(result.data.totalEarning);
      setAvailablePayout(result.data.availablePayout);
      setWalletTransactions(result.data.walletTransaction);
      setPayoutTransactions(result.data.payoutTransaction);
    } catch (error) {
      console.log(error);
    }
    setIsWalletFetching(false);
  };

  const handleRequestPayout = async () => {
    try {
      const result = await payoutService.requestPayout();
      setRefreshKey(new Date());
      toast.success(result.data.message);
    } catch (error) {
      console.log("Request payout error", error.response);
      if (error.response.data.message) {
        console.log("here we are");
        toast.error(error.response.data.message);
      }
    }
  };

  const handleNextPage = () => {
    setPage(page + 1);
  };

  const handlePrevPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  return (
    <div>
      <ToastContainer />
      <Wrapper>
        {isWalletFetching === false ? (
          <>
            <h1 className="text-xl font-bold mb-4">
              {bookings.length > 0 ? "Active Bookings" : ""}
            </h1>

            {/* Display Booking Cards in a Flex Container */}
            <div className="w-full">
              <div
                className="flex flex-row "
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  margin: "auto",
                }}
              >
                {bookings.map((booking, index) => (
                  <BookingCard key={index} booking={booking} />
                ))}
              </div>
            </div>
            <div className="my-5 w-full">
              <div
                className="bg-white m-auto  p-4 m-2 rounded-md shadow-md "
                style={{ maxWidth: 1024 }}
              >
                <h3 className="text-lg font-semibold text-center">
                  Total Earnings
                </h3>
                <div className="text-lg text-gray-500  text-center">
                  <h3>${totalEarning}</h3>
                </div>

                <h3 className=" font-semibold text-center">
                  Total Wallet Balance
                </h3>
                <div className="text-lg text-gray-500  text-center">
                  <h3>${availablePayout}</h3>
                </div>

                <div className="d-flex justify-content-center mt-2 items-center">
                  <div>
                    <button
                      onClick={handleRequestPayout}
                      className="btn  btn-secondary mx-2 tw-mb-2"
                    >
                      Request Payout (${availablePayout})
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <h1 className="text-lg font-bold mb-4">Transactions</h1>

            <div className="table-responsive">
              <table
                className="table card-table  display mb-4 dataTablesCard booking-table room-list-tbl table-responsive-lg "
                id="guestTable-all"
              >
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Booking Id</th>
                    <th>Guest</th>
                    <th>Date</th>
                    <th>Amount</th>
                    <th>Status</th>
                    {/* <th>Payout Date</th> */}
                  </tr>
                </thead>
                <tbody>
                  {walletTransactions.map((curr, id) => (
                    <tr key={id}>
                      <td>{page * id + 1}</td>

                      <td>{curr.bookingId}</td>
                      <td>{curr.guestName}</td>
                      <td>{moment(curr.createdAt).format("MMMM Do, YYYY")}</td>

                      <td
                        className={` ${
                          curr.transactionType === "credited"
                            ? "text-success"
                            : "text-danger"
                        }`}
                      >
                        {parseFloat(curr.amount).toFixed(2)}
                      </td>
                      <td>
                        {curr.status === "Added to wallet" ? (
                          <span className="text-success mx-2 text-white p-1 rounded px-2 bg bg-success">
                            {curr.status}
                          </span>
                        ) : curr.isPenalty ? (
                          <span className="text-success mx-2 text-white p-1 rounded px-2 bg bg-danger">
                            Penalty Fee
                          </span>
                        ) : (
                          <span className="text-success mx-2 text-white p-1 rounded px-2 bg bg-danger">
                            {curr.status}
                          </span>
                        )}
                      </td>

                      {/* <td className="">
                        {curr.paidDate
                          ? moment(curr.paidDate).format("MMMM Do, YYYY")
                          : "--"}
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* <div className="pagination"> */}
            <ul class="pagination justify-content-center">
              <li class="page-item">
                <button onClick={handlePrevPage} class="page-link">
                  Previous
                </button>
              </li>
              <li class="page-item">
                <button onClick={handleNextPage} class="page-link">
                  Next
                </button>
              </li>
            </ul>
            {/* </div> */}
          </>
        ) : (
          <div>Loading...</div>
        )}
      </Wrapper>
    </div>
  );
}

export default Earnings;
