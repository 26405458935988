import React from 'react';
import ReactDOM from 'react-dom';
// import { BrowserRouter as Router } from "react-router-dom";
import './index.css';
import App from './App';
// import registerServiceWorker from './registerServiceWorker';
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://3ef548fcf195a7cce6faab96ee26e87d@o4507683481649152.ingest.us.sentry.io/4507695192604672",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/host.crewmatesapp\.com/,/^https:\/\/devhost.crewmatesapp\.com/],
  // Profiling
  profilesSampleRate: 1.0, // Profile 100% of the transactions. This value is relative to tracesSampleRate
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
ReactDOM.render(
        <App />,
    document.getElementById('root')
);
// registerServiceWorker();
