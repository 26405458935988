import React from 'react'
import ContainerTwo from './ContainerTwo'
import Navbar from './../../components/Navbar'
import TopBar from './../../components/topbar'
import Footer from './../../layout/footer'
import { ToastContainer, toast } from 'react-toastify'

export default function BookingAvailiblitylayout({ children }) {
    const user = localStorage.usertoken
    return (
        <div id="main-wrapper">
            <TopBar user={user} title="Booking Calendar" />
            <div className="deznav">
                <div className="deznav-scroll">
                    <Navbar />
                </div>
            </div>

            <ToastContainer />
            <div className="content-body">
                <div className="container-fluid">
                    <div className="container-booking-availability tw-flex tw-flex-row tw-gap-x-10">
                        <div className="container1 tw-flex tw-flex-col tw-w-full">
                            <div
                                className="cs-card"
                                style={{
                                    padding: '12px',
                                }}
                            >
                                {children}

                            </div>
                        </div>
                        <ContainerTwo />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
