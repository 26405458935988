import React from 'react';

function Footer(props) {

    return (
        <React.Fragment>
            <div className="footer">
                <div className="copyright">
                    <p>
                    © {new Date().getFullYear()} Crewmates, LLC. All Rights Reserved.
                        {/* Copyright © Designed &amp; Developed by
                        <a href="https://www.smartdatainc.com/" target="_blank"></a>
                        2021 */}
                    </p>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Footer;
