import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { Wrapper } from "./Wrapper";
import { paymentService } from "../../_services/payment.service";

function CustomBalancesDashboard() {
  const [isLoading, setIsLoading] = useState(true);
  const [balances, setBalances] = useState(null);
  useEffect(() => {
    retriveCustomBalances();
    getIntentHistories();
  }, []);

  async function retriveCustomBalances() {
    setIsLoading(true);
    const result = await paymentService.retriveCustomBalances();
    setIsLoading(false);
    setBalances(result.data);
    console.log(result.data, "balance");
  }

  const getIntentHistories = async () => {
    const result = await paymentService.fetchIntentHistoryList();
    console.log(result.data);
  };

  return (
    <div>
      <ToastContainer />
      <Wrapper title={"Balances"}>
        {balances && <BalanceDisplay balance={balances} />}
      </Wrapper>
    </div>
  );
}

const BalanceDisplay = ({ balance }) => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-4">
          <div className="card mb-4">
            <div className="card-body" style={{ fontSize: "1.2rem" }}>
              <h5 className="card-title">Available Balance</h5>
              <p className="card-text">
                <strong>Amount:</strong>{" "}
                {balance.available[0].currency === "usd" ? "$" : null}
                {balance.available[0].amount / 100}{" "}
                {balance.available[0].currency}
              </p>
              <p className="card-text">
                <strong>Source Types:</strong>{" "}
                {Object.keys(balance.available[0].source_types).join(", ")}
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card mb-4">
            <div className="card-body" style={{ fontSize: "1.2rem" }}>
              <h5 className="card-title">Instant Available Balance</h5>
              <p className="card-text">
                <strong>Amount:</strong>{" "}
                {balance.instant_available[0].currency === "usd" ? "$" : null}
                {balance.instant_available[0].amount / 100}{" "}
                {balance.instant_available[0].currency}
              </p>
              <p className="card-text">
                <strong>Source Types:</strong>{" "}
                {Object.keys(balance.instant_available[0].source_types).join(
                  ", "
                )}
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card mb-4">
            <div className="card-body" style={{ fontSize: "1.2rem" }}>
              <h5 className="card-title">Pending Balance</h5>
              <p className="card-text">
                <strong>Amount:</strong>{" "}
                {balance.pending[0].currency === "usd" ? "$" : null}
                {balance.pending[0].amount / 100} {balance.pending[0].currency}
              </p>
              <p className="card-text">
                <strong>Source Types:</strong>{" "}
                {Object.keys(balance.pending[0].source_types).join(", ")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomBalancesDashboard;
