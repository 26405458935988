import axios from "axios";
import constant from "../_config/constant";
import {
  authHeader,
  // logout
} from "../_helpers";
import { handleResponse } from "../_helpers/utils";

const api = axios.create({ baseURL: constant.apiUrl });

const getTransactionList = async (query) => {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(
    `${constant.apiUrl}/transaction/list?${new URLSearchParams(
      query
    ).toString()}`,
    requestOptions
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    });
};

const getcustomBalanceTransactions = async ({
  type,
  starting_after,
  ending_before,
  paginationDirection,
}) => {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  let url = `${constant.apiUrl}/transaction/custom/balance_transaction?type=${type}`;

  if (paginationDirection === "Next") {
    url = `${constant.apiUrl}/transaction/custom/balance_transaction?type=${type}&starting_after=${starting_after}`;
  } else if (paginationDirection === "Prev") {
    url = `${constant.apiUrl}/transaction/custom/balance_transaction?type=${type}&ending_before=${ending_before}`;
  }

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
};

const getRefundList = async (query) => {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(
    `${constant.apiUrl}/refund/list?${new URLSearchParams(query).toString()}`,
    requestOptions
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    });
};

// function handleResponse(response) {
//   return response.text().then((text) => {
//     const data = text && JSON.parse(text);

//     if (data.statusCode == 401 || data.statusCode == 403) {
//       logout();
//       window.location.reload();
//     }
//     if (data.statusCode != 200) {
//       const error = (data && data.userMessage) || response.statusText;
//       return Promise.reject(error);
//     }
//     return data;
//   });
// }

const retriveCustomBalances = async () => {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  let url = `${constant.apiUrl}/transaction/custom/balance_retrive`;

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
};

const fetchIntentHistoryList = async () => {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  let url = `${constant.apiUrl}/transaction/custom/intent_history_list`;

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
};

const fetchTransactionById = async (transactionId) => {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  let url = `${constant.apiUrl}/transaction/custom/transaction_detail?transactionId=${transactionId}`;

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
};

export const paymentService = {
  getTransactionList,
  getRefundList,
  getcustomBalanceTransactions,
  retriveCustomBalances,
  fetchIntentHistoryList,
  fetchTransactionById,
};
