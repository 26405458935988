import React, { useEffect, useState } from 'react'
import PersonalDetail from './PersonalDetails'
import TextInput from './TextInput'
import { payoutService } from '../../_services/payout.service'
import { ToastContainer, toast } from 'react-toastify'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import {
    isAgeValid,
    isValidEmail,
    isValidUSPhoneNumber,
    validateSSNLast4,
    validateUSPostalCode,
    validateWebsiteAddress,
} from '../../_helpers/helpers'

function Individual({ stripeDetails, setUpdateStripeDetails }) {
    const [formError, setFormError] = useState(null)
    const [loading, setLoading] = useState(false)
    const history = useHistory()

    const [allStripeVerificationErrorsFields, setAllStripeVerificationErrorsFields] = useState([])

    const company = stripeDetails.company
    const companyAddress = company && company.address
    const individual = stripeDetails.individual
    const individualAddress = individual && individual.address

    useEffect(() => {
        getStripeConnectDraft()
    }, [])

    useEffect(() => {
        if (stripeDetails.requirements.errors.length > 0) {
            let getAllStripeFieldsInWhichErrorIsThere = [...stripeDetails.requirements.currently_due, ...stripeDetails.requirements.eventually_due]

            getAllStripeFieldsInWhichErrorIsThere.forEach(items => {
                items.split('.').forEach(subItems => {
                    getAllStripeFieldsInWhichErrorIsThere.push(subItems)
                })
            })
            setAllStripeVerificationErrorsFields(getAllStripeFieldsInWhichErrorIsThere)
        }
    }, [stripeDetails])

    const getStripeConnectDraft = async () => {
        try {
            const result = await payoutService.getStripeConnectDraftData('individual')

            if (result.data && result.data.data && result.data.data.formdata) {
                setFormData(prev => ({
                    ...result.data.data.formdata,
                    individual: {
                        ...result.data.data.formdata.individual,
                        ssn_last_4: '',
                        id_number: '',
                    },
                }))
            }
        } catch (error) {}
    }

    const [formData, setFormData] = useState({
        business_type: 'individual',
        capabilities: {
            card_payments: {
                requested: true,
            },
            transfers: {
                requested: true,
            },
        },
        business_profile: {
            mcc: '7011',
            url: stripeDetails.business_profile.url ? stripeDetails.business_profile.url : '',
        },

        individual: {
            first_name: individual && stripeDetails.individual.first_name ? stripeDetails.individual.first_name : '',
            last_name: individual && stripeDetails.individual.last_name ? stripeDetails.individual.last_name : '',
            dob: {
                day: individual && stripeDetails.individual.dob.day ? stripeDetails.individual.dob.day : '',
                month: individual && stripeDetails.individual.dob.month ? stripeDetails.individual.dob.month : '',
                year: individual && stripeDetails.individual.dob.year ? stripeDetails.individual.dob.year : '',
            },
            address: {
                line1: individualAddress && stripeDetails.individual.address.line1 ? stripeDetails.individual.address.line1 : '',
                postal_code: individualAddress && stripeDetails.individual.address.postal_code ? stripeDetails.individual.address.postal_code : '',
                city: individualAddress && stripeDetails.individual.address.city ? stripeDetails.individual.address.city : '',
                state: individualAddress && stripeDetails.individual.address.state ? stripeDetails.individual.address.state : '',
            },
            email: individual && stripeDetails.individual.email ? stripeDetails.individual.email : '',
            phone: individual && stripeDetails.individual.phone ? stripeDetails.individual.phone.slice(2) : '',
            ssn_last_4: '',
            id_number: '',
        },
        settings: {
            payments: {
                statement_descriptor: individual && stripeDetails.individual.first_name ? stripeDetails.individual.first_name : '',
            },
        },
    })

    const handlePersonDetailChange = e => {
        if (e.target.name === 'dob') {
            const dateObj = e.target.value.split('-')
            const d = {
                year: dateObj[0].toString() || '',
                month: dateObj[1].toString() || '',
                day: dateObj[2].toString() || '',
            }

            setFormData({
                ...formData,
                individual: {
                    ...formData.individual,
                    dob: d,
                },
            })
        } else {
            setFormData({
                ...formData,
                individual: {
                    ...formData.individual,
                    [e.target.name]: e.target.value,
                },
            })
        }
    }

    const handleIndividualAddressChange = e => {
        setFormData({
            ...formData,
            individual: {
                ...formData.individual,
                address: {
                    ...formData.individual.address,
                    [e.target.name]: e.target.value,
                },
            },
        })
    }
    const handleBusinessUrl = e => {
        setFormData({
            ...formData,
            business_profile: {
                ...formData.business_profile,
                url: e.target.value,
            },
        })
    }

    const validateForm = () => {
        setFormError(null)
        let error = {}
        if (formData.business_type === 'individual') {
            if (formData.individual.first_name.trim().length === 0) {
                error.first_name = 'First name is required'
            }
            if (formData.individual.last_name.trim().length === 0) {
                error.last_name = 'Last name is required'
            }
            if (formData.individual.dob.month.length === 0 || formData.individual.dob.year.length === 0 || formData.individual.dob.day.length === 0) {
                error.dob = 'Date of birth is required'
            } else if (!isAgeValid(formData.individual.dob.day, formData.individual.dob.month, formData.individual.dob.year)) {
                error.dob = 'Date of birth should be between 13 to 120 years'
            }

            if (!isValidEmail(formData.individual.email)) {
                error.email = 'Valid email is required'
            }

            if (!isValidUSPhoneNumber(formData.individual.phone)) {
                error.phone = 'Valid phone number is required'
            }

            if (!validateSSNLast4(formData.individual.ssn_last_4)) {
                error.ssn_last_4 = 'Valid last 4 digit of SSN is required'
            }

            if (!validateWebsiteAddress(formData.business_profile.url)) {
                error.business_profile_url = 'Please enter valid website'
            }

            const invalidDomains = {
                'facebook.com': 'Please enter valid URL that links to your business. Example: www.facebook.com/crewmatesapp',
                'x.com': 'Please enter valid URL that links to your business. Example: www.facebook.com/crewmatesapp',
                'twitter.com': 'Please enter valid URL that links to your business. Example: www.facebook.com/crewmatesapp',
                'instagram.com': 'Please enter valid URL that links to your business. Example: www.facebook.com/crewmatesapp',
                'linkedin.com': 'Please enter valid URL that links to your business. Example: www.facebook.com/crewmatesapp',
                'crewmatesapp.com': 'Please enter valid URL that links to your business. Example: www.facebook.com/crewmatesapp',
                'host.crewmatesapp.com': 'Please enter valid URL that links to your business. Example: www.facebook.com/crewmatesapp',
                'crewmatescrashpads.com': 'Please enter valid URL that links to your business. Example: www.facebook.com/crewmatesapp',
            }

            const urlPatterns = Object.keys(invalidDomains).map(domain => new RegExp(`^(http:\/\/|https:\/\/)?(www\.)?${domain}\/?$`, 'i'))
            const subdomainPattern = /^(http:\/\/|https:\/\/)?[a-z0-9]+(\.[a-z0-9]+)+\./i

            let siteUrl = formData.business_profile.url.trim().toLowerCase() || ''

            if (!subdomainPattern.test(siteUrl) && !siteUrl.startsWith('www.')) {
                error.business_profile_url = error.business_profile_url
                    ? error.business_profile_url
                    : 'Please add www. to the beginning of the website'
            } else {
                for (const [index, pattern] of urlPatterns.entries()) {
                    if (pattern.test(siteUrl)) {
                        error.business_profile_url = invalidDomains[Object.keys(invalidDomains)[index]]
                        break
                    }
                }
            }

            if (formData.individual.address.line1.trim().length === 0) {
                error.line1 = 'Line 1 address is required'
            }
            if (formData.individual.address.city.trim().length === 0) {
                error.city = 'City is required'
            }

            if (formData.individual.address.postal_code.trim().length === 0) {
                error.postal_code = 'Postal code is required'
            } else if (!validateUSPostalCode(formData.individual.address.postal_code)) {
                error.postal_code = 'Postal code is invalid'
            }

            if (formData.individual.address.state.trim().length === 0) {
                error.state = 'State is required'
            }

            if (formData.individual.id_number.trim().length === 0) {
                error.id_number = 'Id number is required'
            }

            if (Object.entries(error).length === 0) {
                return true
            } else {
                setFormError(error)
                return false
            }
        }
    }

    const handleSubmit = async e => {
        e.preventDefault()
        let formDataForSaveInDb = JSON.parse(JSON.stringify(formData))

        if (!validateForm()) {
            toast.error('Highlighted fields are required.')
        } else {
            setLoading(true)
            try {
                await payoutService.saveStripeConnectDraftData('individual', formDataForSaveInDb)
                const result = await payoutService.updateStripeAccount(formData)
                setUpdateStripeDetails(new Date())

                if (result.data.status === false) {
                    if (result.data.message) {
                        const param = result.data.message.param
                        if (result.data.message.raw.message == 'US ID numbers must be 9 characters long') {
                            setLoading(false)
                            toast.info('SSN must be 9 numbers with no dashes')
                        } else {
                            setLoading(false)
                            toast.info(result.data.message.raw.message)
                        }

                        let error = {}
                        setFormError(error)
                    }
                } else {
                    setLoading(false)
                    toast.info('Your Information has been submitted for review')
                    history.goBack()
                }
            } catch (error) {
                setLoading(false)
                toast.error('Failed to update')
            }
        }
    }

    return (
        <form className="" onSubmit={handleSubmit}>
            <ToastContainer />
            <PersonalDetail
                onChange={handlePersonDetailChange}
                person={formData.individual}
                error={formError}
                formDataKey={'individual'}
                verificationErrorFieldsList={allStripeVerificationErrorsFields}
            />
            <TextInput
                toolTipText={
                    'No website? You can share a URL from a Facebook page or group, Instagram page, X profile, LinkedIn profile, or any other link that provides information about your business.'
                }
                name={'url'}
                label={'Your business website*'}
                onChange={handleBusinessUrl}
                value={formData.business_profile.url}
                placeHolder={"https://www.facebook.com/crewmatesapp"}
                highlightError={['business_profile.url', 'url'].some(value => allStripeVerificationErrorsFields.includes(value))}
                error={formError && formError.business_profile_url}
            />

            {/* <AddressForm
        onChange={handleIndividualAddressChange}
        address={formData.individual.address}
      /> */}
            <TextInput
                type="text"
                value={formData.individual.address.line1}
                onChange={handleIndividualAddressChange}
                label="Address line 1*"
                name={'line1'}
                highlightError={['individual.address.line1'].some(value => allStripeVerificationErrorsFields.includes(value))}
                error={formError && formError.line1}
            />

            <TextInput
                type="text"
                value={formData.individual.address.postal_code}
                onChange={handleIndividualAddressChange}
                label="Postal code*"
                name={'postal_code'}
                highlightError={['individual.address.postal_code'].some(value => allStripeVerificationErrorsFields.includes(value))}
                error={formError && formError.postal_code}
            />
            <TextInput
                type="text"
                value={formData.individual.address.city}
                onChange={handleIndividualAddressChange}
                label="City*"
                name={'city'}
                highlightError={['individual.address.city'].some(value => allStripeVerificationErrorsFields.includes(value))}
                error={formError && formError.city}
            />

            <TextInput
                type="text"
                value={formData.individual.address.state}
                onChange={handleIndividualAddressChange}
                label="State*"
                name={'state'}
                highlightError={['individual.address.state'].some(value => allStripeVerificationErrorsFields.includes(value))}
                error={formError && formError.state}
            />

            <div className="mt-4">
                <button
                    disabled={loading}
                    type="submit"
                    className="tw-rounded-md  tw-p-3 tw-px-8 tw-bg-primary tw-border-0 tw-text-white tw-text-sm "
                >
                    {loading ? 'Please wait...' : 'Submit'}
                </button>
            </div>
        </form>
    )
}

export default Individual
