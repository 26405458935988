import React, { useState } from "react";

import "react-toastify/dist/ReactToastify.css";

var Modal = require("react-bootstrap-modal");

export const Terms = ({
  onNext,
  onPrev,
  formData,
  setFormData,
  className,
  requesting,
  saveAsDraft,
  draftRequesting,
}) => {
  // const [stepForm, setStepForm] = useState({ ...formData });
  const [stepForm, setStepForm] = useState({
    cancelationPolicy: formData.cancelationPolicy,
  });
  const handleStep = (tag, value) => {
    setStepForm({ ...stepForm, [tag]: value });
    setFormData({ ...formData, ...stepForm, [tag]: value });
  };
  const handleNextStep = (e) => {
    e.preventDefault();
    setFormData({ ...formData, ...stepForm });
    window.scrollTo({ top: 0, behavior: "smooth" });
    onNext();
  };
  const saveAsdraft = () => {
    setFormData({ ...formData, ...stepForm });
    saveAsDraft();
  };
  return (
    <form onSubmit={handleNextStep}>
      <div className={className}>
        <div className=" my-3 ">
          <h4 className="mb-5 tw-flex tw-justify-start tw-items-center">
            <span className="tw-bg-[#23426e] tw-mr-2 tw-flex tw-justify-center tw-items-center  tw-h-10 tw-w-10 tw-text-white  tw-rounded-full">
              9
            </span>{" "}
            Terms
          </h4>
          <div className="row">
            <div className=" col-lg-6 mb-2">
              <div className="mb-3">
                <label className="form-label">Select Cancellation Policy</label>
                <select
                  onChange={(e) =>
                    handleStep("cancelationPolicy", e.target.value)
                  }
                  value={stepForm["cancelationPolicy"]}
                  className="form-control wide"
                  required
                >
                  <option value="">Select</option>
                  <option value="Relaxed">Relaxed</option>
                  <option value="Moderate">Moderate</option>
                  <option value="Strict">Strict</option>
                </select>
              </div>
            </div>
            <div className="col-lg-6 mb-2">
              <p className="tw-text-sm tw-text-gray-400 tw-my-2">
                <span className="tw-text-gray-500">Relaxed: </span> Guests can
                receive a full refund up until 24 hours before check-in
              </p>
              <p className="tw-text-sm tw-text-gray-400 tw-my-2">
                <span className="tw-text-gray-500">Moderate: </span> Guests can
                receive a full refund up until 5 days before check-in
              </p>
              <p className="tw-text-sm tw-text-gray-400 tw-my-2">
                <span className="tw-text-gray-500">Strict: </span> No refunds
              </p>
            </div>
          </div>
        </div>
        <div>
          <button
            onClick={onPrev}
            type="button"
            className="btn btn-secondary mx-2  tw-mb-2"
          >
            Prev
          </button>
          <button type="submit" className="btn btn-secondary mx-2 tw-mb-2">
            {requesting ? "Saving ..." : "Next"}
          </button>
          <button
            type="button"
            disabled={draftRequesting}
            onClick={saveAsdraft}
            className="btn btn-secondary mx-2 tw-mb-2"
          >
            {draftRequesting ? "Adding ..." : "Save as Draft"}
          </button>
        </div>
      </div>
    </form>
  );
};
