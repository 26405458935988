import React, { useEffect } from "react";
import ReactDOM from "react-dom";

const el = document.createElement("div");
const modal = document.getElementById("modal");

function Modal({ children }) {
  useEffect(() => {
    modal.appendChild(el);
    return () => {
      modal.removeChild(el);
    };
  });

  return ReactDOM.createPortal(
    <div className="react-modal tw-bg-opacity-50 tw-fixed tw-top-0 tw-bottom-0 tw-right-0  tw-left-0 tw-flex tw-justify-center tw-items-center tw-min-h-screen">
      <div className=" tw-rounded-2xl tw-w-full lg:tw-w-8/12 tw-mx-auto flex-shrink-0 receipt-view tw-leading-tight">
        {children}
      </div>
    </div>,
    el
  );
}

export default Modal;
