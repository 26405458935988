import React, { useState } from "react";
import OtpInput from "react-otp-input";

import "react-toastify/dist/ReactToastify.css";

var Modal = require("react-bootstrap-modal");

export const OTPInput = React.forwardRef((props, ref) => {
  const [value, setValue] = useState(value);
  return (
    <React.Fragment>
      <OtpInput
        value={value}
        onChange={(e) => {
          setValue(e);
        }}
        containerStyle="tw-flex tw-items-center tw-justify-center tw-space-x-4 tw-my-10"
        inputStyle="tw-h-10  tw-w-10 tw-border-0 tw-ring-1 tw-ring-gray-600 tw-bg-white tw-rounded tw-p-0"
        separator={<span>{"  "}</span>}
        numInputs={4}
      />
      <input
        type="text"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        className="tw-hidden"
        ref={ref}
      />
    </React.Fragment>
  );
});
