import React, { useEffect, useState } from 'react'
import { propertyService } from '../../_services'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
import PropertyOption from './PropertyOption'
import BookingTypeOptions from './BookingTypeOptions'
import HotBedContainer from './HotBedContainer'
import BookingAvailiblitylayout from './BookingAvailiblitylayout'
import ColdBedContainer from './ColdBedContainer'
import { HOT_BED } from '../../_helpers/constants'

const BookingAvailability = () => {
    const [bookingType, setBookingType] = useState()
    const [bookingTypeOptions, setBookingTypeOptions] = useState([])
    const [propertyList, setPropertyList] = useState([])
    const [properyDetails, setPropertyDetails] = useState()
    const [selectedRoom, setSelectedRoom] = useState()
    const [selectedBed, setSelectedBed] = useState(null)

    useEffect(() => {
        propertyService.allActiveProperty().then(
            objs => {
                setPropertyList(objs.data)
            },
            error => {
                toast.error('Something went wrong while fetching properties.')
            }
        )
    }, [])

    const onChangeBookingType = async bookingTypeValue => {
        setBookingType(bookingTypeValue)
        await fetchPropertyDetails(properyDetails._id, { bookingType: bookingTypeValue }, false)
    }

    const fetchPropertyDetails = (propertyId, options, isReset = true) => {
        propertyService
            .getDetail(propertyId, options)
            .then(async res => {
                setPropertyDetails(res.data)
            })
            .catch(() => {
                toast.error('Something went wrong while fetching property details')
            })
    }

    const handlePropertyListing = async value => {
        const selectedProperty = propertyList.find(item => item.id === value)
        const selectedPropertyBookingType = selectedProperty ? selectedProperty.bookingType : null

        if (!selectedPropertyBookingType) return

        const bookingTypeOptions = selectedPropertyBookingType === 'Flexible' ? ['Cold bed', 'Hot bed'] : [selectedPropertyBookingType]
        const bookingType = bookingTypeOptions[0]

        setBookingTypeOptions(bookingTypeOptions)
        setBookingType(bookingType)
        await fetchPropertyDetails(value, { bookingType })
    }


    useEffect(() => {
        if (properyDetails) {

            if (!selectedRoom) {

                setSelectedRoom({
                    index: 0,
                    isAvailable: properyDetails.sleepingArrangements[0].isAvailable,
                    roomDetails: properyDetails.sleepingArrangements[0],
                })
            } else {

                let roomData = null
                properyDetails.sleepingArrangements.filter((room, index) => {

                    if (room._id === selectedRoom.roomDetails._id) {
                        roomData = {
                            index,
                            isAvailable: room.isAvailable,
                            roomDetails: room
                        }
                    }

                });
                if (roomData) {
                    setSelectedRoom(roomData)
                } else {
                    setSelectedRoom({
                        index: 0,
                        isAvailable: properyDetails.sleepingArrangements[0].isAvailable,
                        roomDetails: properyDetails.sleepingArrangements[0],
                    })
                }
            }

            if (properyDetails.rentalType === 'Crashpad') {

                if (!selectedBed) {
                    setSelectedBed(properyDetails.sleepingArrangements[0].beds[0])
                } else {
                    const bed = properyDetails.sleepingArrangements[selectedRoom ? selectedRoom.index : 0].beds.find(b => b._id === selectedBed._id)
                    if (bed) {
                        setSelectedBed(bed)
                    } else {
                        setSelectedBed(setSelectedBed(properyDetails.sleepingArrangements[0].beds[0]))
                    }

                }
            } else {
                setSelectedBed(null) // because entire place doesn't require bed
            }
        }
    }, [properyDetails])

    const handleRoomSelection = (type) => {
        setSelectedRoom((e) => {
            const ind = type === 'next'
                ? Math.min(e.index + 1, properyDetails.sleepingArrangements.length - 1)
                : Math.max(e.index - 1, 0);

            return {
                index: ind,
                isAvailable: properyDetails.sleepingArrangements[ind].isAvailable,
                roomDetails: properyDetails.sleepingArrangements[ind],
            };
        });
        setSelectedBed(null);
    };


    return (
        <BookingAvailiblitylayout>
            <div className="">
                <PropertyOption handlePropertyListing={handlePropertyListing} propertyList={propertyList} />
                <BookingTypeOptions bookingType={bookingType} bookingTypeOptions={bookingTypeOptions} onChangeBookingType={onChangeBookingType} />
                {properyDetails && (
                    <div className="tw-m-4">
                        <div>
                            {bookingType === HOT_BED ? (
                                <HotBedContainer properyDetails={properyDetails}
                                    fetchPropertyDetails={fetchPropertyDetails}
                                    selectedBed={selectedBed}
                                    selectedRoom={selectedRoom}
                                    setSelectedBed={setSelectedBed}
                                    handleRoomSelection={handleRoomSelection}
                                />
                            ) :
                                <ColdBedContainer properyDetails={properyDetails}
                                    fetchPropertyDetails={fetchPropertyDetails}
                                    selectedBed={selectedBed}
                                    selectedRoom={selectedRoom}
                                    setSelectedBed={setSelectedBed}
                                    setSelectedRoom={setSelectedRoom}
                                    handleRoomSelection={handleRoomSelection}
                                />
                            }


                        </div>
                    </div>
                )}
            </div>
        </BookingAvailiblitylayout>
    )
}

export default BookingAvailability
