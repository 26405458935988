import constant from "../_config/constant";
import { handleResponse } from "../_helpers/utils";
import {
  authHeader,
  // logout,
  basicAuthHeader,
} from "../_helpers";

export const bankService = {
  addBank,
  verifyBank,
  deleteBank,
  updateBank,
  bankList,
  makePrimary,
  getStripeDetail,
  getStripeLinkUrl,
  getStripeLoginLinkUrl,
};

function getStripeDetail() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(`${constant.apiUrl}/stripe/account`, requestOptions)
    .then(handleResponse)
    .then((details) => {
      return details;
    });
}

function getStripeLinkUrl() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(`${constant.apiUrl}/stripe/linkAccount`, requestOptions)
    .then(handleResponse)
    .then((details) => {
      return details;
    });
}

function getStripeLoginLinkUrl() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(`${constant.apiUrl}/stripe/loginLink`, requestOptions)
    .then(handleResponse)
    .then((details) => {
      return details;
    });
}

function bankList() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(`${constant.apiUrl}/bank/list`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function addBank(data) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  return fetch(`${constant.apiUrl}/addBank`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
function updateBank(data) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  return fetch(`${constant.apiUrl}/updateBank`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function deleteBank(data) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  return fetch(`${constant.apiUrl}/deleteBank`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function verifyBank(data) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  return fetch(`${constant.apiUrl}/verifyBank`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      return user;
    });
}

function makePrimary(data) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  return fetch(`${constant.apiUrl}/bank/primary`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

// function handleResponse(response) {
//   return response.text().then((text) => {
//     const data = text && JSON.parse(text);
//     if (data.statusCode == 401 || data.statusCode == 403) {
//       logout();
//       window.location.reload();
//     }
//     if (data.statusCode != 200) {
//       const error = (data && data.userMessage) || response.statusText;
//       return Promise.reject(error);
//     }
//     return data;
//   });
// }
