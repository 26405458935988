import React, { useRef, useEffect, useState } from "react";
import Navbar from "./Navbar";
import TopBar from "./topbar";
import Footer from "./../layout/footer";
import useCaptcha from "./../hooks/useCaptcha";
import { submitContactQuery } from "./../_services/index";
import { toast, ToastContainer } from "react-toastify";

function ContactForm({ onSubmit, captcha, className, requesting }) {
  const captchaRef = useRef(null);
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [userCaptcha, setUserCaptcha] = useState("");

  const handleFormSubmission = (e) => {
    e.preventDefault();
    onSubmit({ name, email, mobile, message, userCaptcha });
  };

  useEffect(() => {
    const ctx = captchaRef.current.getContext("2d");
    ctx.clearRect(0, 0, captchaRef.current.width, captchaRef.current.height);
    ctx.font = "24px Roboto";
    ctx.fillStyle = "#08e5ff";
    ctx.fillText(captcha, 0, 40);
    ctx.textAlign = "center";
  }, []);
  return (
    <form className={className} onSubmit={handleFormSubmission}>
      <input
        className="tw-h-12 tw-ring-white tw-ring-1 tw-border-0 tw-col-span-2 md:tw-col-span-1 tw-outline-none tw-text-sm  tw-w-full tw-rounded tw-p-2 tw-px-4 tw-bg-white"
        placeholder="Name"
        value={name}
        required
        onChange={(el) => setName(el.target.value)}
      />
      <input
        className="tw-h-12 tw-ring-white tw-ring-1 tw-border-0 tw-col-span-2 md:tw-col-span-1 tw-outline-none tw-text-sm  tw-w-full tw-rounded tw-p-2 tw-px-4 tw-bg-white"
        placeholder="Mobile"
        value={mobile}
        type="number"
        required
        onChange={(el) => setMobile(el.target.value)}
      />
      <input
        className="tw-h-12 tw-ring-white tw-ring-1 tw-border-0 tw-outline-none tw-text-sm tw-col-span-2  tw-w-full tw-rounded tw-p-2 tw-px-4 tw-bg-white"
        placeholder="Email"
        type="email"
        value={email}
        required
        onChange={(el) => setEmail(el.target.value)}
      />
      <textarea
        className="tw-h-24 tw-ring-white tw-ring-1 tw-border-0 tw-outline-none tw-text-sm tw-col-span-2  tw-w-full tw-rounded tw-p-2 tw-px-4 tw-bg-white"
        placeholder="Message"
        value={message}
        required
        onChange={(el) => setMessage(el.target.value)}
      ></textarea>

      <div className="tw-my-2 tw-col-span-2 tw-p-4 tw-flex tw-items-start tw-justify-between tw-bg-primary-blue-light tw-text-white ">
        {/* <h4 className="text-xl mr-2">{captcha}</h4> */}
        <canvas height={80} width={120} ref={captchaRef}></canvas>
        <div className="">
          <p className="text-sm mb-2">Enter Captcha</p>
          <input
            value={userCaptcha}
            size={50}
            onChange={(el) => setUserCaptcha(el.target.value)}
            className="tw-h-12 tw-ring-white tw-ring-1 tw-border-0 tw-col-span-2 md:tw-col-span-1 tw-outline-none tw-text-sm  tw-w-full tw-rounded tw-p-2 tw-px-4 tw-bg-whitering-2 tw-h-12 tw-col-span-2 md:tw-col-span-1 tw-outline-none tw-text-sm  tw-w-full tw-rounded tw-p-2 tw-px-4 tw-bg-white tw-ring-white tw-h-12 tw-col-span-2 md:tw-col-span-1 tw-outline-none tw-text-sm  tw-w-full tw-rounded tw-p-2 tw-px-4 tw-bg-whiteplaceholder-gray-50 tw-h-12 tw-col-span-2 md:tw-col-span-1 tw-outline-none tw-text-sm  tw-w-full tw-rounded tw-p-2 tw-px-4 tw-bg-whitering-opacity-70 tw-h-12 tw-col-span-2 md:tw-col-span-1 tw-outline-none tw-text-sm  tw-w-full tw-rounded tw-p-2 tw-px-4 tw-bg-whiterounded tw-h-12 tw-col-span-2 md:tw-col-span-1 tw-outline-none tw-text-sm  tw-w-full tw-rounded tw-p-2 tw-px-4 tw-bg-whitebg-transparent tw-h-12 tw-col-span-2 md:tw-col-span-1 tw-outline-none tw-text-sm  tw-w-full tw-rounded tw-p-2 tw-px-4 tw-bg-whitep-2 tw-h-12 tw-col-span-2 md:tw-col-span-1 tw-outline-none tw-text-sm  tw-w-full tw-rounded tw-p-2 tw-px-4 tw-bg-whiteoutline-none"
            placeholder="DD3G1"
          />
        </div>
      </div>
      <button type="submit" className="btn btn-secondary mx-2">
        {requesting ? "Please wait..." : "SUBMIT"}
      </button>
    </form>
  );
}

function ContactUs() {
  const { validate, captcha } = useCaptcha();
  const [requesting, setRequesting] = useState(false);
  const handleContactForm = async (e) => {
    try {
      if (!e.email || !e.message || !e.mobile || !e.name) {
        return toast.error("Invalid form data!");
      }

      if (e.mobile.length > 14 || e.mobile.length < 4) {
        return toast.error("Invalid mobile");
      }
      setRequesting(true);
      if (validate(e.userCaptcha)) {
        const res = await submitContactQuery({
          email: e.email,
          mobile: e.mobile,
          message: e.message,
          name: e.name,
        });
        setRequesting(false);
        if (res.data.status) {
          toast.success(res.data.message);
        } else toast.error(res.data.message);
      } else {
        setRequesting(false);
        toast.error("Invalid captcha!");
      }
    } catch (error) {
      setRequesting(false);
      toast.error("Something went wrong!");
    }
  };

  return (
    <div id="main-wrapper">
      <TopBar title="Contact us" />
      <div className="deznav">
        <div className="deznav-scroll">
          <Navbar />
        </div>
      </div>
      <ToastContainer />

      <div className="content-body">
        <div className="container-fluid">
          <div className="row">
            <div className="tw-px-4   ">
              <div className=" md:tw-w-10/12 lg:tw-w-8/12 tw-my-20 tw-mx-auto tw-flex tw-flex-col md:tw-flex-row tw-space-y-10 md:tw-space-y-0 md:tw-space-x-10 tw-justify-center tw-items-center">
                <div className="tw-flex tw-justify-center tw-items-center tw-w-max tw-flex-col ">
                  <img
                    className="tw-mb-5 tw-w-12"
                    src="/images/email.png"
                    alt="email"
                  />
                  <h4 className="tw-capitalize tw-text-primary tw-text-lg md:tw-text-xl ">
                    Official Email
                  </h4>
                  <p className="tw-text-gray-800 tw-font-semibold tw-text-base md:tw-text-lg ">
                    support@crewmatesapp.com
                  </p>
                </div>
                <div className="tw-flex tw-justify-center tw-items-center tw-flex-col tw-w-max">
                  <img
                    className="tw-mb-5 tw-w-9"
                    src="/images/location1.png"
                    alt="email"
                  />
                  <h4 className="tw-capitalize tw-text-primary  tw-text-lg md:tw-text-xl ">
                    Official Address
                  </h4>
                  <p className="tw-text-gray-800 tw-font-semibold tw-text-base md:tw-text-lg ">
                    8 The Green, Ste B, Dover, DE 19901
                  </p>
                </div>
                {/* <div className="flex justify-center items-center flex-col w-max">
            <img
              className="mb-5 text-gray-500 "
              src="/assets/resources/telephone.png"
              alt="email"
            />
            <h4 className="capitalize text-primary-blue text-xl ">
              Official Mobile
            </h4>
            <p className="text-gray-800 font-semibold text-lg ">
              +91 72678899227
            </p>
          </div> */}
              </div>
              <div className="tw-bg-primary tw-lg:w-8/12 tw-items-stretch tw-rounded tw-overflow-hidden  tw-mx-auto tw-my-20 tw-flex tw-flex-col md:tw-flex-row ">
                <div className=" md:tw-w-6/12 tw-justify-items-stretch tw-flex-grow tw-overflow-hidden    tw-relative ">
                  <img
                    src="/images/lady.jpg"
                    alt="lady on call"
                    className="tw-w-full tw-h-full tw-object-cover tw-absolute tw-top-0 tw-left-0 tw-bottom-0 tw-right-0 "
                  />
                </div>
                <div className=" md:tw-w-6/12 tw-p-4 md:tw-p-8 tw-text-white">
                  <h4 className=" tw-font-medium tw-text-xl md:tw-text-3xl tw-text-white">
                    Get In Touch
                  </h4>
                  <p className="tw-text-base md:tw-text-lg">
                    Please fill your details below
                  </p>
                  <hr className="tw-w-[50%] tw-bg-white tw-border-2 tw-rounded-full tw-my-4" />
                  <div>
                    <ContactForm
                      requesting={requesting}
                      captcha={captcha}
                      onSubmit={handleContactForm}
                      className="tw-grid tw-grid-cols-2 tw-gap-4 tw-my-5 tw-text-gray-800"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ContactUs;
