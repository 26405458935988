import axios from "axios";
import constant from "../_config/constant";
import { basicAuthHeader, authHeader } from "../_helpers";

const api = axios.create({ baseURL: constant.apiUrl });

export const submitContactQuery = async (data) => {
  const response = await api.post("/contactus/add", data, {
    headers: basicAuthHeader(),
  });
  return response;
};

export const getFaqList = async () => {
  const response = await api.get("/faq", { headers: basicAuthHeader() });

  return response;
};

export const report = async (data) => {
  let fd = new FormData();
  fd.append("message", data.message);
  fd.append("bookingId", data.bookingId);

  data.photos.length &&
    data.photos.forEach((file) => {
      fd.append("files", file.current);
    });
  const response = await api.post("/report", fd, {
    headers: authHeader(),
  });
  return response;
};

export const getFaqCategory = async () => {
  const response = await api.get("/category/list", {
    headers: basicAuthHeader(),
  });
  return response;
};


export async function siteSettings() {
  const response = await api.get('/site-settings', basicAuthHeader())
  return response
}