import React from 'react'

export default function BlockUnblockButton({ selectedBed, properyDetails, selectedRoom, confirmBlockUnblock, isBlocking }) {
    let isDisabled = true;


    if (properyDetails.rentalType === 'Crashpad') {
        if (selectedBed) {
            isDisabled = false;
        } else {
            isDisabled = true
        }
    } else {
        if (selectedRoom) {
            isDisabled = false
        } else {
            isDisabled = true
        }
    }

    return (
        <button
            disabled={isDisabled}
            className={`tw-p-4 tw-text-center ${isDisabled ? 'tw-bg-gray-500' : 'tw-bg-primary'
                } tw-text-white tw-rounded-md tw-w-full tw-text-lg tw-font-medium`}
            onClick={confirmBlockUnblock}
        >
            {isBlocking ? 'Confirm Block Night(s)' : 'Confirm Unblocked Night(s)'}
        </button>
    )
}
